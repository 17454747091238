@charset "UTF-8";
@import "~react-dates/lib/css/_datepicker.css";
@import "~react-table/react-table.css";
@import '~react-toastify/dist/ReactToastify.css';
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
.visually__h1,
h2,
.ReactTable .rt-noData--textLower,
.ReactTable .rt-noData--text,
.visually__h2,
h3,
.visually__h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

div {
  border: 0 solid;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after, blockquote:before {
  content: "";
  content: none;
}

q:after, q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
body.navigation__noscroll {
  overflow-y: hidden;
}

@font-face {
  font-family: "RapidRatings";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RapidRatings.ttf?wu89jf") format("truetype"), url("../fonts/RapidRatings.woff?wu89jf") format("woff"), url("../fonts/RapidRatings.svg?wu89jf#RapidRatings") format("svg");
}
/* source-sans-pro-200 - light */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("Source Sans Pro Light"), local("Light"), url("../fonts/SourceSansPro-Light.ttf") format("truetype");
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro-v11-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/source-sans-pro-v11-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-regular.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-regular.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro-v11-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../fonts/source-sans-pro-v11-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/source-sans-pro-v11-latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v11-latin-700.woff") format("woff"), url("../fonts/source-sans-pro-v11-latin-700.ttf") format("truetype"), url("../fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro") format("svg");
  /* Legacy iOS */
}
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #4A4A4A;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconFont, .search .search__results__v2 .search__request button:before, .search .search__results__v2 ul li button:before, .search .search__results ul li button:before, .search .search__results ul li a:before, .search .search__input:before, .findialog__lists .findialog__list--concerns h1:before, .findialog__lists .findialog__list--concerns .visually__h1:before, .findialog__lists .findialog__list--concerns .visually__h2:before, .findialog__lists .findialog__list--concerns .visually__h3:before,
.findialog__lists .findialog__list--concerns h2:before,
.findialog__lists .findialog__list--concerns .ReactTable .rt-noData--textLower:before,
.ReactTable .findialog__lists .findialog__list--concerns .rt-noData--textLower:before,
.findialog__lists .findialog__list--concerns .ReactTable .rt-noData--text:before,
.ReactTable .findialog__lists .findialog__list--concerns .rt-noData--text:before,
.findialog__lists .findialog__list--concerns h3:before,
.findialog__lists .findialog__list--concerns h4:before, .findialog__lists .findialog__list--strengths h1:before, .findialog__lists .findialog__list--strengths .visually__h1:before, .findialog__lists .findialog__list--strengths .visually__h2:before, .findialog__lists .findialog__list--strengths .visually__h3:before,
.findialog__lists .findialog__list--strengths h2:before,
.findialog__lists .findialog__list--strengths .ReactTable .rt-noData--textLower:before,
.ReactTable .findialog__lists .findialog__list--strengths .rt-noData--textLower:before,
.findialog__lists .findialog__list--strengths .ReactTable .rt-noData--text:before,
.ReactTable .findialog__lists .findialog__list--strengths .rt-noData--text:before,
.findialog__lists .findialog__list--strengths h3:before,
.findialog__lists .findialog__list--strengths h4:before, label.labelchange input:checked + .square:before, label.labelchange .square:before, .input__group--search .rbtn--reset:before, .input__group--search .react-autosuggest__container:before, .input__group--search:before, .input__group input[type=checkbox]:checked + label:before, .input__group input[type=checkbox] + label:before, .input__group input[type=checkbox]:before, .rbtn__group > *.rbtn:after, .main__supplierPay .rbtn__group > *.stripe-button-el:after, .input__group .rbtn__group > *.file__input--label:after, .rbtn__group > *.react-fine-uploader-gallery-file-input-container:after, .invitation .rbtn__group > *.react-fine-uploader-gallery-file-input-container:after,
.react-autosuggest__container > *.rbtn:after,
.main__supplierPay .react-autosuggest__container > *.stripe-button-el:after,
.input__group .react-autosuggest__container > *.file__input--label:after,
.react-autosuggest__container > *.react-fine-uploader-gallery-file-input-container:after,
.invitation .react-autosuggest__container > *.react-fine-uploader-gallery-file-input-container:after, .activities .activity .activity__details .activity__files li::before, .table--spaced td.th--sort:after,
.table--spaced th.th--sort:after, .table--summary tbody tr.tr--bad th:first-child:before, .table--summary tbody tr.tr--decrease th:first-child:before, .table--summary tbody tr.tr--good th:first-child:before, .table--summary tbody tr.tr--increase th:first-child:before, .table--summary td.numeric--decrease:before,
.table--summary th.numeric--decrease:before, .table--summary td.numeric--increase:before,
.table--summary th.numeric--increase:before, .accordion .accordion__header:after, .icon {
  font-family: "RapidRatings";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--backgroundGrey {
  width: 18px;
  height: 18px;
  background-color: #C7D2DB;
  color: #ffffff;
  font-size: 10px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  line-height: 15px;
  padding: 0;
  margin: 0;
}
.icon--selectable:hover {
  cursor: pointer;
}
.icon--graychateau {
  color: #98a4ae;
}
.icon--2x {
  font-size: 2em;
}
.icon--close:before {
  content: "";
}
.icon--increase:before {
  content: "";
}
.icon--building:before {
  content: "";
}
.icon--decrease:before {
  content: "";
}
.icon--down:before {
  content: "";
}
.icon--menu:before {
  content: "";
}
.icon--up:before {
  content: "";
}
.icon--minimize:before {
  content: "";
}
.icon--maximize:before {
  content: "";
}
.icon--calendar:before {
  content: "";
}
.icon--action-indicator:before {
  content: "";
}
.icon--reports:before {
  content: "";
}
.icon--dialog:before {
  content: "";
}
.icon--fins:before {
  content: "";
}
.icon--tag:before {
  content: "";
}
.icon--trend:before {
  content: "";
}
.icon--marker-hq:before {
  content: "";
}
.icon--marker:before {
  content: "";
}
.icon--marker-circle:before {
  content: "";
}
.icon--tick-circle:before {
  content: "";
}
.icon--warning-circle:before {
  content: "";
}
.icon--submitted-circle:before {
  content: "";
}
.icon--failed-circle:before {
  content: "";
}
.icon--ratio:before {
  content: "";
}
.icon--check:before {
  content: "";
}
.icon--checked:before {
  content: "";
}
.icon--tick:before {
  content: "";
}
.icon--right:before {
  content: "";
}
.icon--news:before {
  content: "";
}
.icon--lock:before {
  content: "";
}
.icon--search:before {
  content: "";
}
.icon--bankruptcy:before {
  content: "";
}
.icon--disasters:before {
  content: "";
}
.icon--news:before {
  content: "";
}
.icon--mergers:before {
  content: "";
}
.icon--phone-circle:before {
  content: "";
}
.icon--envelop-circle:before {
  content: "";
}
.icon--linkedin:before {
  content: "";
}
.icon--twitter:before {
  content: "";
}
.icon--facebook:before {
  content: "";
}
.icon--googleplus:before {
  content: "";
}
.icon--sortable:before {
  content: "";
}
.icon--back:before {
  content: "";
}
.icon--file-issue:before {
  content: "";
}
.icon--exclamation:before {
  content: "";
}
.icon--printer:before {
  content: "";
}
.icon--question:before {
  content: "";
}
.icon--hierarchy:before {
  content: "";
}
.icon--download:before {
  content: "";
}
.icon--leftChevron:before {
  content: "";
}
.icon--rightChevron:before {
  content: "";
}
.icon--lock:before {
  content: "";
}
.icon--unlock:before {
  content: "";
}

h1, .visually__h1, h3, .visually__h3, h2, .ReactTable .rt-noData--textLower, .ReactTable .rt-noData--text, .visually__h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #4A4A4A;
  letter-spacing: 0;
  line-height: 32px;
}

h2, .ReactTable .rt-noData--textLower, .ReactTable .rt-noData--text, .visually__h2 {
  font-size: 26px;
  font-weight: normal;
  line-height: 32px;
}

h3, .visually__h3 {
  font-size: 16px;
  line-height: 24px;
}

h1.border--bottom, .border--bottom.visually__h1, h2.border--bottom, .ReactTable .border--bottom.rt-noData--textLower, .ReactTable .border--bottom.rt-noData--text, .border--bottom.visually__h2, h3.border--bottom, .border--bottom.visually__h3 {
  border-bottom: 2px solid #A3B0BB;
}

.main-headline {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 26px;
  letter-spacing: 0;
  text-align: left;
  line-height: 26px;
  margin: 0 0 4px 0;
}
.main-headline__landing {
  margin-left: 200px;
}
.main-headline strong {
  font-weight: 600;
}
.main-headline--numbered:before {
  content: attr(title);
  color: white;
  background-color: #005E8C;
  width: 36px;
  height: 36px;
  text-align: center;
  display: block;
  margin-right: 12px;
  border-radius: 100%;
  line-height: 36px;
  position: absolute;
  margin: -5px 0 0 -44px;
}

.body-copy {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
  line-height: 18px;
  margin: 0 0 8px 0;
}
.body-copy:last-child {
  margin-bottom: 0;
}
.body-copy__landing {
  margin-left: 200px;
}

.secondary-headline {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 26px;
  letter-spacing: 0;
  text-align: left;
  line-height: 26px;
}

.tertiary-headline {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0;
  text-align: left;
  font-size: 16px;
  text-align: left;
  line-height: 16px;
}

.subheader {
  font-size: 14px;
  font-weight: 700;
  color: #98a4ae;
  text-transform: uppercase;
}

.caption, .caption1 {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1.2px;
  line-height: 16px;
  color: #A3B0BB;
  text-transform: uppercase;
}
.caption.link, .link.caption1 {
  color: #A3B0BB;
}
.caption--secondary, .caption2 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 400;
  color: #828C94;
  text-transform: initial;
}
.caption--secondary.link, .link.caption2 {
  color: #828C94;
}

p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #4A4A4A;
}
p.p--secondary {
  color: #828C94;
}
p.p--tertiary {
  color: #A3B0BB;
}
p strong {
  font-weight: 700;
}

.text--cerulean {
  color: #005E8C;
}
.text--turbo {
  color: #ffe900;
}
.text--razzmatazz {
  color: #d30547;
}
.text--grey, .ReactTable .rt-noData--textLower, .ReactTable .rt-noData--text {
  color: #828C94;
}
.text--lightGrey {
  color: #A3B0BB;
}
.text--white {
  color: #ffffff;
}
.text--white.link {
  color: #ffffff;
}
.text--lowerSize {
  font-size: 13px;
}
.text--extraLarge {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}
.text--disclaimer {
  color: #3B4043;
  font-size: 12px;
  line-height: normal;
}
.text__casing--initial {
  text-transform: initial;
}
.text__casing--capitalize, .rbtn__group .dropdown li .rbtn, .rbtn__group .dropdown li .main__supplierPay .stripe-button-el, .main__supplierPay .rbtn__group .dropdown li .stripe-button-el, .rbtn__group .dropdown li .input__group .file__input--label, .input__group .rbtn__group .dropdown li .file__input--label, .rbtn__group .dropdown li .react-fine-uploader-gallery-file-input-container,
.react-autosuggest__container .dropdown li .rbtn,
.react-autosuggest__container .dropdown li .main__supplierPay .stripe-button-el,
.main__supplierPay .react-autosuggest__container .dropdown li .stripe-button-el,
.react-autosuggest__container .dropdown li .input__group .file__input--label,
.input__group .react-autosuggest__container .dropdown li .file__input--label,
.react-autosuggest__container .dropdown li .react-fine-uploader-gallery-file-input-container {
  text-transform: capitalize;
}
.text__casing--lower {
  text-transform: lowercase;
}
.text__casing--upper {
  text-transform: uppercase;
}
.text__weight--normal {
  font-weight: normal;
}
.text__align--left, .rbtn__group .dropdown li .rbtn, .rbtn__group .dropdown li .main__supplierPay .stripe-button-el, .main__supplierPay .rbtn__group .dropdown li .stripe-button-el, .rbtn__group .dropdown li .input__group .file__input--label, .input__group .rbtn__group .dropdown li .file__input--label, .rbtn__group .dropdown li .react-fine-uploader-gallery-file-input-container,
.react-autosuggest__container .dropdown li .rbtn,
.react-autosuggest__container .dropdown li .main__supplierPay .stripe-button-el,
.main__supplierPay .react-autosuggest__container .dropdown li .stripe-button-el,
.react-autosuggest__container .dropdown li .input__group .file__input--label,
.input__group .react-autosuggest__container .dropdown li .file__input--label,
.react-autosuggest__container .dropdown li .react-fine-uploader-gallery-file-input-container {
  text-align: left;
}
.text__align--center {
  text-align: center;
}
.text__align--right {
  text-align: right;
}
.text__ellipses {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}

.italics {
  font-style: italic;
}

.rectangle {
  border-radius: 2px;
  background-color: #E3EEEA;
  padding: 4px 8px;
  margin-right: 8px;
  display: inline-block;
}
.rectangle:last-of-type {
  margin-right: 0;
}

.sprites::before {
  display: inline-block;
  position: absolute;
  content: " ";
  left: 0;
  width: 32px;
  height: 32px;
}
.sprites--trendanalysis::before {
  background: url("../imgs/sprites@2x.png") no-repeat 0 0;
  background-size: 32px 170px;
}
.sprites--programmanagement::before {
  background: url("../imgs/sprites@2x.png") no-repeat 0 -34px;
  background-size: 32px 170px;
}
.sprites--support::before {
  background: url("../imgs/sprites@2x.png") no-repeat 0 -68px;
  background-size: 32px 170px;
}
.sprites--search::before {
  background: url("../imgs/sprites@2x.png") no-repeat 0 -98px;
  background-size: 32px 170px;
}

.print__only {
  display: none;
}

@page {
  margin: 0 1cm;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @page {
    margin: 0.85cm 1cm;
  }
}
@media print {
  navigation,
navigation *,
.task-bar,
.task-bar *,
.health-mark-task-bar,
.health-mark-task-bar *,
.print__hide,
.print__hide *,
.icon--selectable {
    display: none;
  }

  .print__only {
    display: block;
  }

  .pagebreak--before {
    break-before: page;
  }
  .pagebreak--after {
    break-after: page;
  }

  .main {
    margin-top: 0 !important;
  }

  .rapidRatings__v3 .container--rr,
.rapidRatings__v3 .container-fluid--rr {
    padding: 0 2rem;
  }
  .rapidRatings__v3 *[class^=col-xs],
.rapidRatings__v3 *[class*=" col-xs"] {
    padding: 0;
  }
  .rapidRatings__v3 .row.bordered > [class*=col-],
.rapidRatings__v3 .row.bordered > [class^=" col-"] {
    border: 0;
  }

  /* HM Profile parent address cell*/
  .parent--address {
    white-space: initial;
  }

  /* detail block component s*/
  .details__block,
*.details__block[class*=" col-"],
*.details__block[class^=col-],
*.details__block[class^=col-]:first-of-type {
    padding: 0;
    margin: 0;
  }

  /* accordion components */
  .accordion.accordion--card.accordion--locked {
    border: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
  .accordion.accordion--card.accordion--locked .padding__horizontal--loose--left {
    padding-left: 0;
  }

  ul.border__left {
    padding-top: 20px;
  }

  /* font styles */
  header h1, header h2, header .ReactTable .rt-noData--textLower, .ReactTable header .rt-noData--textLower, header .ReactTable .rt-noData--text, .ReactTable header .rt-noData--text, header h3, header .visually__h1, header .visually__h2, header .visually__h3 {
    font-size: 40px;
    line-height: 40px;
    margin-top: 0;
  }
  header p {
    color: #666666;
  }
  header hr {
    display: none;
  }

  .rapidRatings__v3 h2.margin__vertical__bottom__loose, .rapidRatings__v3 .ReactTable .margin__vertical__bottom__loose.rt-noData--textLower, .ReactTable .rapidRatings__v3 .margin__vertical__bottom__loose.rt-noData--textLower, .rapidRatings__v3 .ReactTable .margin__vertical__bottom__loose.rt-noData--text, .ReactTable .rapidRatings__v3 .margin__vertical__bottom__loose.rt-noData--text, .rapidRatings__v3 .margin__vertical__bottom__loose.visually__h2 {
    margin-bottom: 10px;
  }

  .caption, .caption1 {
    font-size: 14px;
    color: #005E8C;
    border-top: 3px solid #999999;
    padding-top: 20px;
  }

  /* image styles */
  img.print__logo {
    display: block;
    width: 200px;
    height: auto;
  }
  img.gauge--image {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  img.max__width {
    max-width: 700px;
    height: auto;
  }

  .report_header {
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 0 0 0 0;
    background-color: #ffffff;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  .report_header .border {
    height: 62px;
    background-color: #231F20;
    position: relative;
  }
  .report_header .rapid_title {
    position: absolute;
    top: 25%;
    left: 0;
  }
  .report_header .rapid_title .logo {
    width: 200px;
  }
  .report_header .rapid_title .logo.logo--white {
    display: block;
  }
  .report_header .rapid_title .logo.logo--black {
    display: none;
  }
}
@media print and (-ms-high-contrast: none) {
  .report_header .rapid_title .logo.logo--white {
    display: none;
  }
  .report_header .rapid_title .logo.logo--black {
    display: block;
  }
}
@media print {
  @supports (-ms-ime-align: auto) {
    .report_header .rapid_title .logo.logo--white {
      display: none;
    }
    .report_header .rapid_title .logo.logo--black {
      display: block;
    }
  }
}
@media print {
  .report_header .topright {
    position: absolute;
    top: 15%;
    right: 20px;
    text-align: right;
    color: #ffffff;
    width: 70%;
    font-size: 18px;
  }
  .report_header .topright .report_type {
    font-size: 20px;
    font-weight: bold;
  }
  .report_header .topright .company_info {
    max-width: 450px;
    float: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media print {
  .dataTypes .col-sm-3, .dataTypes .table_sortable-filters {
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
}
@media print {
  .healthMark {
    background-color: #ffffff !important;
  }
  .healthMark--footer {
    background-color: #ffffff !important;
  }
  .healthMark--disclaimer {
    margin-top: 48px;
  }
  .healthMark--map {
    margin-left: 0;
  }
}
@media print {
  .table--HealthMark img {
    max-width: 185px;
  }
}
@media print {
  .text__align--left--print {
    text-align: left !important;
  }
  .text__align--center--print {
    text-align: center !important;
  }
  .text__align--right--print {
    text-align: right !important;
  }
}
@media print {
  .margin__print__vertical {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .margin__print__vertical--top {
    margin-top: 12px;
  }
  .margin__print__vertical--bottom {
    margin-bottom: 12px;
  }
  .margin__print__vertical--tight {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .margin__print__vertical--tight--top {
    margin-top: 8px;
    margin-bottom: inherit;
  }
  .margin__print__vertical--tight--bottom {
    margin-bottom: 8px;
    margin-top: inherit;
  }
  .margin__print__vertical--loose {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .margin__print__vertical--loose--top {
    margin-top: 24px;
  }
  .margin__print__vertical--loose--bottom {
    margin-bottom: 24px;
  }
  .margin__print__horizontal {
    margin-left: 12px;
    margin-right: 12px;
  }
  .margin__print__horizontal--left {
    margin-left: 12px;
  }
  .margin__print__horizontal--right {
    margin-right: 12px;
  }
  .margin__print__horizontal--tight {
    margin-left: 8px;
    margin-right: 8px;
  }
  .margin__print__horizontal--tight--left {
    margin-left: 8px;
  }
  .margin__print__horizontal--tight--right {
    margin-right: 8px;
  }
  .margin__print__horizontal--loose {
    margin-left: 24px;
    margin-right: 24px;
  }
  .margin__print__horizontal--loose--left {
    margin-left: 24px;
  }
  .margin__print__horizontal--loose--right {
    margin-right: 24px;
  }
  .margin__print__none {
    margin: 0;
  }
}
.temp-content-width {
  padding-top: 32px;
}

.search__container.active {
  background: rgba(0, 0, 0, 0.8);
}

.newsImage--companyReports {
  height: 60px;
  float: right;
  margin-top: -10px;
}

.nav-tabs .newsImageItem--companyReports {
  float: right;
}

.quadrant-plane {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  display: block;
}

/**
* requires $className and $size
* $className refers to the class' prefix, i.e. '.className--veryhigh'
* $size refers to the width, height, and line-height of the status indicator
*/
.rapidRatings__v3 .container-fluid,
.rapidRatings__v3 .container {
  margin-right: auto;
  margin-left: auto;
}
.rapidRatings__v3 .container-fluid--with-nav,
.rapidRatings__v3 .container--with-nav {
  margin: 40px 0 0 0;
}
.rapidRatings__v3 .container-fluid--rr,
.rapidRatings__v3 .container--rr {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}
.rapidRatings__v3 .container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}
.rapidRatings__v3 .margin__vertical, .rapidRatings__v3 .selectDropdown {
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 1px;
}
.rapidRatings__v3 .margin__vertical--top {
  margin-top: 12px;
}
.rapidRatings__v3 .margin__vertical--top--anchors {
  margin-top: 110px;
}
.rapidRatings__v3 .margin__vertical--top--anchorsTight {
  margin-top: 80px;
}
.rapidRatings__v3 .margin__vertical--bottom {
  margin-bottom: 12px;
}
.rapidRatings__v3 .margin__vertical--tight {
  margin-top: 8px;
  margin-bottom: 8px;
}
.rapidRatings__v3 .margin__vertical--tight--top {
  margin-top: 8px;
  margin-bottom: inherit;
}
.rapidRatings__v3 .margin__vertical--tight--bottom {
  margin-bottom: 8px;
  margin-top: inherit;
}
.rapidRatings__v3 .margin__vertical--large {
  margin-top: 48px;
  margin-bottom: 48px;
}
.rapidRatings__v3 .margin__vertical--large--top {
  margin-top: 48px;
  margin-bottom: inherit;
}
.rapidRatings__v3 .margin__vertical--large--bottom {
  margin-bottom: 48px;
  margin-top: inherit;
}
.rapidRatings__v3 .margin__vertical--loose {
  margin-top: 24px;
  margin-bottom: 24px;
}
.rapidRatings__v3 .margin__vertical--loose--top {
  margin-top: 24px;
}
.rapidRatings__v3 .margin__vertical--loose--bottom {
  margin-bottom: 24px;
}
.rapidRatings__v3 .margin__horizontal {
  margin-left: 12px;
  margin-right: 12px;
}
.rapidRatings__v3 .margin__horizontal--left {
  margin-left: 12px;
}
.rapidRatings__v3 .margin__horizontal--right {
  margin-right: 12px;
}
.rapidRatings__v3 .margin__horizontal--tight {
  margin-left: 8px;
  margin-right: 8px;
}
.rapidRatings__v3 .margin__horizontal--tight--left {
  margin-left: 8px;
}
.rapidRatings__v3 .margin__horizontal--tight--right {
  margin-right: 8px;
}
.rapidRatings__v3 .margin__horizontal--loose {
  margin-left: 24px;
  margin-right: 24px;
}
.rapidRatings__v3 .margin__horizontal--loose--left {
  margin-left: 24px;
}
.rapidRatings__v3 .margin__horizontal--loose--right {
  margin-right: 24px;
}
.rapidRatings__v3 .margin__none {
  margin: 0;
}
.rapidRatings__v3 .padding__vertical {
  padding-top: 12px;
  padding-bottom: 12px;
}
.rapidRatings__v3 .padding__vertical--top {
  padding-top: 12px;
}
.rapidRatings__v3 .padding__vertical--bottom {
  padding-bottom: 12px;
}
.rapidRatings__v3 .padding__vertical--tight {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rapidRatings__v3 .padding__vertical--tight--top {
  padding-top: 8px;
}
.rapidRatings__v3 .padding__vertical--tight--bottom {
  padding-bottom: 8px;
}
.rapidRatings__v3 .padding__vertical--loose {
  padding-top: 24px;
  padding-bottom: 24px;
}
.rapidRatings__v3 .padding__vertical--loose--top {
  padding-top: 24px;
}
.rapidRatings__v3 .padding__vertical--loose--bottom {
  padding-bottom: 24px;
}
.rapidRatings__v3 .padding__vertical--large {
  padding-top: 48px;
  padding-bottom: 48px;
}
.rapidRatings__v3 .padding__vertical--large--top {
  padding-top: 48px;
}
.rapidRatings__v3 .padding__vertical--large--bottom {
  padding-bottom: 48px;
}
.rapidRatings__v3 .padding__horizontal {
  padding-left: 12px;
  padding-right: 12px;
}
.rapidRatings__v3 .padding__horizontal--left {
  padding-left: 12px;
}
.rapidRatings__v3 .padding__horizontal--right {
  padding-right: 12px;
}
.rapidRatings__v3 .padding__horizontal--tight {
  padding-left: 8px;
  padding-right: 8px;
}
.rapidRatings__v3 .padding__horizontal--tight--left {
  padding-left: 8px;
}
.rapidRatings__v3 .padding__horizontal--tight--right {
  padding-right: 8px;
}
.rapidRatings__v3 .padding__horizontal--loose {
  padding-left: 24px;
  padding-right: 24px;
}
.rapidRatings__v3 .padding__horizontal--loose--left {
  padding-left: 24px;
}
.rapidRatings__v3 .padding__horizontal--loose--right {
  padding-right: 24px;
}
.rapidRatings__v3 .padding__none {
  padding: 0;
}
.rapidRatings__v3 .align__vertical--start {
  align-self: flex-start;
}
.rapidRatings__v3 .align__vertical--center {
  align-self: center;
}
.rapidRatings__v3 .align__vertical--end {
  align-self: flex-end;
}
.rapidRatings__v3 .inline__block {
  display: inline-block;
}
.rapidRatings__v3 .row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.rapidRatings__v3 .row--margin {
  padding: 0 0 48px 0;
}
.rapidRatings__v3 .row--margin:last-child {
  padding: 0 0 0 0;
}
.rapidRatings__v3 .row--hr:not(:last-of-type) > div[class^=col-]:after, .rapidRatings__v3 .row--hr:not(:last-of-type) > div[class^=" col-"]:after {
  content: "";
  margin: 40px auto 32px auto;
  border: none;
  height: 1px;
  width: 100%;
  color: #C7D2DB;
  /* old IE */
  background-color: #C7D2DB;
  /* Modern Browsers */
  display: inline-block;
}
.rapidRatings__v3 .row--whiteBackground {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #ffffff;
}
.rapidRatings__v3 .row--greyBackground {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #F4F6F9;
}
.rapidRatings__v3 .row--fullHeight {
  min-height: 100vh;
}
.rapidRatings__v3 .row--borderTop {
  border-top: 1px solid #C7D2DB;
}
.rapidRatings__v3 .row--borderTop:before {
  content: "";
  background: linear-gradient(180deg, #F4F6F9 0%, #EFF2F6 100%);
  height: 7px;
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 576px) {
  .rapidRatings__v3 .row.bordered > [class^=col-], .rapidRatings__v3 .row.bordered > [class*=" col-"] {
    box-sizing: border-box;
    border-left: 1px solid #C7D2DB;
  }
  .rapidRatings__v3 .row.bordered > [class^=col-]:first-of-type, .rapidRatings__v3 .row.bordered > [class*=" col-"]:first-of-type {
    border-left: 1px solid transparent;
  }
}
.rapidRatings__v3 .row.bordered--tight > [class^=col-], .rapidRatings__v3 .row.bordered--tight > [class*=" col-"] {
  margin-top: -24px;
  padding-top: 24px;
  margin-bottom: -24px;
  padding-bottom: 24px;
}
@media screen and (min-width: 75em) {
  .rapidRatings__v3 .row.bordered--tight > [class^=col-], .rapidRatings__v3 .row.bordered--tight > [class*=" col-"] {
    margin-top: -32px;
    padding-top: 32px;
    margin-bottom: -32px;
    padding-bottom: 32px;
  }
}
.rapidRatings__v3 .row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.rapidRatings__v3 .col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.rapidRatings__v3 .d-none {
  display: none;
}
.rapidRatings__v3 .d-xs-none {
  display: none;
}
.rapidRatings__v3 .col-xs,
.rapidRatings__v3 .col-xs-1,
.rapidRatings__v3 .col-xs-2,
.rapidRatings__v3 .col-xs-3,
.rapidRatings__v3 .col-xs-4,
.rapidRatings__v3 .col-xs-5,
.rapidRatings__v3 .col-xs-6,
.rapidRatings__v3 .col-xs-7,
.rapidRatings__v3 .col-xs-8,
.rapidRatings__v3 .col-xs-9,
.rapidRatings__v3 .col-xs-10,
.rapidRatings__v3 .col-xs-11,
.rapidRatings__v3 .col-xs-12,
.rapidRatings__v3 .col-xs-offset-0,
.rapidRatings__v3 .col-xs-offset-1,
.rapidRatings__v3 .col-xs-offset-2,
.rapidRatings__v3 .col-xs-offset-3,
.rapidRatings__v3 .col-xs-offset-4,
.rapidRatings__v3 .col-xs-offset-5,
.rapidRatings__v3 .col-xs-offset-6,
.rapidRatings__v3 .col-xs-offset-7,
.rapidRatings__v3 .col-xs-offset-8,
.rapidRatings__v3 .col-xs-offset-9,
.rapidRatings__v3 .col-xs-offset-10,
.rapidRatings__v3 .col-xs-offset-11,
.rapidRatings__v3 .col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.rapidRatings__v3 .col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.rapidRatings__v3 .col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.rapidRatings__v3 .col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.rapidRatings__v3 .col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.rapidRatings__v3 .col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.rapidRatings__v3 .col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.rapidRatings__v3 .col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.rapidRatings__v3 .col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.rapidRatings__v3 .col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.rapidRatings__v3 .col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.rapidRatings__v3 .col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.rapidRatings__v3 .col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.rapidRatings__v3 .col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.rapidRatings__v3 .col-xs-offset-0 {
  margin-left: 0;
}
.rapidRatings__v3 .col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.rapidRatings__v3 .col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.rapidRatings__v3 .col-xs-offset-3 {
  margin-left: 25%;
}
.rapidRatings__v3 .col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.rapidRatings__v3 .col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.rapidRatings__v3 .col-xs-offset-6 {
  margin-left: 50%;
}
.rapidRatings__v3 .col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.rapidRatings__v3 .col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.rapidRatings__v3 .col-xs-offset-9 {
  margin-left: 75%;
}
.rapidRatings__v3 .col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.rapidRatings__v3 .col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.rapidRatings__v3 .start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
.rapidRatings__v3 .center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.rapidRatings__v3 .end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}
.rapidRatings__v3 .top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.rapidRatings__v3 .middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.rapidRatings__v3 .bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.rapidRatings__v3 .around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.rapidRatings__v3 .between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.rapidRatings__v3 .first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.rapidRatings__v3 .last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (min-width: 48em) {
  .rapidRatings__v3 .container {
    width: 100%;
  }
  .rapidRatings__v3 .d-sm-none {
    display: none;
  }
  .rapidRatings__v3 .col-sm,
.rapidRatings__v3 .col-sm-1,
.rapidRatings__v3 .col-sm-2,
.rapidRatings__v3 .col-sm-3,
.rapidRatings__v3 .table_sortable-filters,
.rapidRatings__v3 .col-sm-4,
.rapidRatings__v3 .col-sm-5,
.rapidRatings__v3 .col-sm-6,
.rapidRatings__v3 .col-sm-7,
.rapidRatings__v3 .col-sm-8,
.rapidRatings__v3 .col-sm-9,
.rapidRatings__v3 .col-sm-10,
.rapidRatings__v3 .col-sm-11,
.rapidRatings__v3 .col-sm-12,
.rapidRatings__v3 .table_sortable,
.rapidRatings__v3 .col-sm-offset-0,
.rapidRatings__v3 .col-sm-offset-1,
.rapidRatings__v3 .col-sm-offset-2,
.rapidRatings__v3 .col-sm-offset-3,
.rapidRatings__v3 .col-sm-offset-4,
.rapidRatings__v3 .col-sm-offset-5,
.rapidRatings__v3 .col-sm-offset-6,
.rapidRatings__v3 .col-sm-offset-7,
.rapidRatings__v3 .col-sm-offset-8,
.rapidRatings__v3 .col-sm-offset-9,
.rapidRatings__v3 .col-sm-offset-10,
.rapidRatings__v3 .col-sm-offset-11,
.rapidRatings__v3 .col-sm-offset-12 {
    display: block;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .rapidRatings__v3 .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .rapidRatings__v3 .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .rapidRatings__v3 .col-sm-3, .rapidRatings__v3 .table_sortable-filters {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .rapidRatings__v3 .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .rapidRatings__v3 .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .rapidRatings__v3 .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .rapidRatings__v3 .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .rapidRatings__v3 .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .rapidRatings__v3 .col-sm-9, .rapidRatings__v3 .table_sortable-filters ~ .table_sortable {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .rapidRatings__v3 .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .rapidRatings__v3 .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .rapidRatings__v3 .col-sm-12, .rapidRatings__v3 .table_sortable {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-sm-offset-0 {
    margin-left: 0;
  }
  .rapidRatings__v3 .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .rapidRatings__v3 .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .rapidRatings__v3 .col-sm-offset-3 {
    margin-left: 25%;
  }
  .rapidRatings__v3 .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .rapidRatings__v3 .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .rapidRatings__v3 .col-sm-offset-6 {
    margin-left: 50%;
  }
  .rapidRatings__v3 .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .rapidRatings__v3 .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .rapidRatings__v3 .col-sm-offset-9 {
    margin-left: 75%;
  }
  .rapidRatings__v3 .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .rapidRatings__v3 .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .rapidRatings__v3 .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .rapidRatings__v3 .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .rapidRatings__v3 .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .rapidRatings__v3 .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .rapidRatings__v3 .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .rapidRatings__v3 .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .rapidRatings__v3 .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .rapidRatings__v3 .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rapidRatings__v3 .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .rapidRatings__v3 .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 64em) {
  .rapidRatings__v3 .container {
    width: 100%;
  }
  .rapidRatings__v3 .d-md-none {
    display: none;
  }
  .rapidRatings__v3 .col-md,
.rapidRatings__v3 .col-md-1,
.rapidRatings__v3 .col-md-2,
.rapidRatings__v3 .col-md-3,
.rapidRatings__v3 .col-md-4,
.rapidRatings__v3 .col-md-5,
.rapidRatings__v3 .col-md-6,
.rapidRatings__v3 .col-md-7,
.rapidRatings__v3 .col-md-8,
.rapidRatings__v3 .col-md-9,
.rapidRatings__v3 .col-md-10,
.rapidRatings__v3 .col-md-11,
.rapidRatings__v3 .col-md-12,
.rapidRatings__v3 .col-md-offset-0,
.rapidRatings__v3 .col-md-offset-1,
.rapidRatings__v3 .col-md-offset-2,
.rapidRatings__v3 .col-md-offset-3,
.rapidRatings__v3 .col-md-offset-4,
.rapidRatings__v3 .col-md-offset-5,
.rapidRatings__v3 .col-md-offset-6,
.rapidRatings__v3 .col-md-offset-7,
.rapidRatings__v3 .col-md-offset-8,
.rapidRatings__v3 .col-md-offset-9,
.rapidRatings__v3 .col-md-offset-10,
.rapidRatings__v3 .col-md-offset-11,
.rapidRatings__v3 .col-md-offset-12 {
    display: block;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .rapidRatings__v3 .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .rapidRatings__v3 .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .rapidRatings__v3 .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .rapidRatings__v3 .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .rapidRatings__v3 .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .rapidRatings__v3 .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .rapidRatings__v3 .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .rapidRatings__v3 .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .rapidRatings__v3 .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .rapidRatings__v3 .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .rapidRatings__v3 .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .rapidRatings__v3 .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-md-offset-0 {
    margin-left: 0;
  }
  .rapidRatings__v3 .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .rapidRatings__v3 .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .rapidRatings__v3 .col-md-offset-3 {
    margin-left: 25%;
  }
  .rapidRatings__v3 .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .rapidRatings__v3 .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .rapidRatings__v3 .col-md-offset-6 {
    margin-left: 50%;
  }
  .rapidRatings__v3 .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .rapidRatings__v3 .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .rapidRatings__v3 .col-md-offset-9 {
    margin-left: 75%;
  }
  .rapidRatings__v3 .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .rapidRatings__v3 .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .rapidRatings__v3 .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .rapidRatings__v3 .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .rapidRatings__v3 .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .rapidRatings__v3 .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .rapidRatings__v3 .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .rapidRatings__v3 .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .rapidRatings__v3 .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .rapidRatings__v3 .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rapidRatings__v3 .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .rapidRatings__v3 .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .rapidRatings__v3 .container {
    width: 100%;
    max-width: 2000px;
  }
  .rapidRatings__v3 .container--rr {
    width: 100%;
    max-width: 1696px;
  }
  .rapidRatings__v3 .d-lg-none {
    display: none;
  }
  .rapidRatings__v3 .col-lg,
.rapidRatings__v3 .col-lg-1,
.rapidRatings__v3 .col-lg-2,
.rapidRatings__v3 .col-lg-3,
.rapidRatings__v3 .col-lg-4,
.rapidRatings__v3 .col-lg-5,
.rapidRatings__v3 .col-lg-6,
.rapidRatings__v3 .col-lg-7,
.rapidRatings__v3 .col-lg-8,
.rapidRatings__v3 .col-lg-9,
.rapidRatings__v3 .col-lg-10,
.rapidRatings__v3 .col-lg-11,
.rapidRatings__v3 .col-lg-12,
.rapidRatings__v3 .col-lg-offset-0,
.rapidRatings__v3 .col-lg-offset-1,
.rapidRatings__v3 .col-lg-offset-2,
.rapidRatings__v3 .col-lg-offset-3,
.rapidRatings__v3 .col-lg-offset-4,
.rapidRatings__v3 .col-lg-offset-5,
.rapidRatings__v3 .col-lg-offset-6,
.rapidRatings__v3 .col-lg-offset-7,
.rapidRatings__v3 .col-lg-offset-8,
.rapidRatings__v3 .col-lg-offset-9,
.rapidRatings__v3 .col-lg-offset-10,
.rapidRatings__v3 .col-lg-offset-11,
.rapidRatings__v3 .col-lg-offset-12 {
    display: block;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
  .rapidRatings__v3 .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .rapidRatings__v3 .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .rapidRatings__v3 .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .rapidRatings__v3 .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .rapidRatings__v3 .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .rapidRatings__v3 .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .rapidRatings__v3 .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .rapidRatings__v3 .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .rapidRatings__v3 .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .rapidRatings__v3 .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .rapidRatings__v3 .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .rapidRatings__v3 .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .rapidRatings__v3 .col-lg-offset-0 {
    margin-left: 0;
  }
  .rapidRatings__v3 .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .rapidRatings__v3 .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .rapidRatings__v3 .col-lg-offset-3 {
    margin-left: 25%;
  }
  .rapidRatings__v3 .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .rapidRatings__v3 .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .rapidRatings__v3 .col-lg-offset-6 {
    margin-left: 50%;
  }
  .rapidRatings__v3 .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .rapidRatings__v3 .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .rapidRatings__v3 .col-lg-offset-9 {
    margin-left: 75%;
  }
  .rapidRatings__v3 .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .rapidRatings__v3 .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .rapidRatings__v3 .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .rapidRatings__v3 .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .rapidRatings__v3 .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .rapidRatings__v3 .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .rapidRatings__v3 .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .rapidRatings__v3 .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .rapidRatings__v3 .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .rapidRatings__v3 .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rapidRatings__v3 .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .rapidRatings__v3 .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
.rapidRatings__v3 .d__hide {
  display: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .rapidRatings__v3 .d__xs-show-table {
    display: table-cell;
  }
  .rapidRatings__v3 .d__xs-show-row {
    display: table-row;
  }
  .rapidRatings__v3 .d__xs-show-block {
    display: block;
  }
  .rapidRatings__v3 .d__xs-show-inline {
    display: inline;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .rapidRatings__v3 .d__sm-show-table {
    display: table-cell;
  }
  .rapidRatings__v3 .d__sm-show-row {
    display: table-row;
  }
  .rapidRatings__v3 .d__sm-show-block {
    display: block;
  }
  .rapidRatings__v3 .d__sm-show-inline {
    display: inline;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .rapidRatings__v3 .d__md-show-table {
    display: table-cell;
  }
  .rapidRatings__v3 .d__md-show-row {
    display: table-row;
  }
  .rapidRatings__v3 .d__md-show-block {
    display: block;
  }
  .rapidRatings__v3 .d__md-show-inline {
    display: inline;
  }
}
@media (min-width: 1281px) {
  .rapidRatings__v3 .d__lg-show-table {
    display: table-cell;
  }
  .rapidRatings__v3 .d__lg-show-row {
    display: table-row;
  }
  .rapidRatings__v3 .d__lg-show-block {
    display: block;
  }
  .rapidRatings__v3 .d__lg-show-inline {
    display: inline;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.animated {
  animation-duration: 200ms;
  animation-fill-mode: both;
}
.animated--fadeIn {
  animation-name: fadeIn;
}
.animated--zoomIn {
  animation-name: zoomIn;
}
.animated--fadeInTop {
  animation-name: fadeInTop;
}
.animated--fadeInBottomm {
  animation-name: fadeInBottomm;
}
.animated--fadeInLeft {
  animation-name: fadeInLeft;
}
.animated--fadeInRight {
  animation-name: fadeInRight;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-5%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

.v2-hide {
  display: none;
}

.scrollable {
  position: relative;
  overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #c1c6cd;
  border-radius: 10px;
}

.datetime strong::after {
  content: " ";
}
.datetime span::before {
  content: " ";
}

.content__block {
  border-left: 1px solid #ffe900;
  margin: 16px 0;
  padding: 0 16px 0 0;
}
.content__block .link,
.content__block h1,
.content__block .visually__h1,
.content__block .visually__h2,
.content__block .visually__h3,
.content__block h2,
.content__block .ReactTable .rt-noData--textLower,
.ReactTable .content__block .rt-noData--textLower,
.content__block .ReactTable .rt-noData--text,
.ReactTable .content__block .rt-noData--text,
.content__block h3,
.content__block h4,
.content__block h5 {
  padding: 0 0 0 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  color: #2C2E2E;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  font-weight: 700;
}
.content__block .link--sm,
.content__block h1--sm,
.content__block h2--sm,
.content__block h3--sm,
.content__block h4--sm,
.content__block h5--sm {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #005E8C;
  padding: 0;
  margin: 0 8px 0 0;
}
.content__block p {
  padding: 0 0 0 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  margin: 16px 0 0;
  line-height: 18px;
}
.content__block p strong {
  font-weight: 600;
}

.content__information {
  margin: 50px 0 24px;
}
.content__information ul {
  margin: 14px 0 0;
}
.content__information ul li {
  display: inline-block;
  position: relative;
}
.content__information ul li div {
  display: inline-block;
  float: left;
}
.content__information ul li.progression--right:after {
  content: " ";
  width: 31px;
  height: 31px;
  display: block;
  position: absolute;
  background: url("../imgs/arrow.svg") no-repeat;
  right: 0;
  top: 50%;
  margin: -14px 0 0;
}
.content__information .content__information-copy {
  max-width: 90px;
  margin: 14px 40px 0 4px;
}

@media screen and (min-width: 576px) {
  .details__block,
.details__block[class^=col-],
.details__block[class*=" col-"] {
    padding: 0 24px;
  }
}
.details__block:first-of-type,
.details__block[class^=col-]:first-of-type,
.details__block[class*=" col-"]:first-of-type {
  padding-left: 0.5rem;
}
.details__block:last-of-type,
.details__block[class^=col-]:last-of-type,
.details__block[class*=" col-"]:last-of-type {
  padding-right: 0.5rem;
}
.details__block .caption, .details__block .caption1,
.details__block[class^=col-] .caption,
.details__block[class*=" col-"] .caption {
  margin-bottom: 24px;
}

.card {
  padding: 32px;
  border: 1px solid #F4F6F9;
}
.card--grey {
  background-color: #F4F6F9;
}
.card--shadowed {
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.card--opaque {
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
}
.card--img {
  position: relative;
}
.card--img img {
  position: absolute;
  left: -50px;
  top: 25px;
  width: 250px;
}

.header {
  margin: 32px auto 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header h1, .header h2, .header .ReactTable .rt-noData--textLower, .ReactTable .header .rt-noData--textLower, .header .ReactTable .rt-noData--text, .ReactTable .header .rt-noData--text, .header h3, .header .visually__h1, .header .visually__h2, .header .visually__h3 {
  margin-bottom: 8px;
  position: relative;
  display: inline-block;
}
.header p {
  color: #A3B0BB;
}
.header hr {
  margin-top: 24px;
}
.header .header__details {
  flex: 2;
}
.header .header__main {
  width: 100%;
}
.header .header__icon {
  position: relative;
  margin-right: 8px;
}
.header .header__icon .icon--hierarchy {
  position: absolute;
  top: 8px;
  color: #A3B0BB;
}

.icon--line--container {
  height: calc(100% - 44px);
  min-width: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 24px;
  background-image: linear-gradient(#A3B0BB, #A3B0BB);
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}
.icon--line--container:after {
  content: "";
  width: 10px;
  height: 1px;
  background: linear-gradient(180deg, #A3B0BB, #A3B0BB);
  position: absolute;
  left: 8px;
  bottom: 0;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: strong;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge--primary {
  color: color-yiq(#017BC1);
  background-color: #017BC1;
}
.badge--primary[href]:hover, .badge--primary[href]:focus {
  color: color-yiq(#017BC1);
  text-decoration: none;
  background-color: #015b8e;
}

.content__list {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #3B4043;
  letter-spacing: 0;
  line-height: 19px;
  list-style-type: square;
}
.content__list--circle {
  list-style: none;
  padding: 0 0 0 1em;
  margin: 0;
}
.content__list--circle li {
  position: relative;
  line-height: 24px;
}
.content__list--circle li:before {
  content: url("../imgs/bullet-circle.svg");
  color: #A3B0BB;
  position: absolute;
  left: -1em;
}
.content__list li {
  margin: 0 0 0 19px;
}
.content__list li .link {
  line-height: inherit;
}
.content__list li.errors {
  list-style: none;
}
.content__list li.errors:before {
  font-family: "RapidRatings";
  content: "";
  color: #d30547;
  float: left;
  margin-left: -20px;
}
.content__list li.errors .error {
  font-weight: 600;
  color: #d30547;
}

.step__list {
  list-style-type: none;
}
.step__list > li {
  margin: 0 0 24px;
}
.step__list > li .row {
  padding: 0 0 24px;
}

.accordion {
  margin: 0 0 0 0;
  max-height: 2em;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  -webkit-transition: max-height 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out;
  -ms-transition: max-height 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out;
  transition: max-height 300ms ease-in-out;
}
.accordion strong {
  font-weight: 700;
}
.accordion.accordion--card {
  background-color: #ffffff;
  padding: 16px;
  min-height: 102px;
  border: 1px solid rgba(199, 210, 219, 0.7);
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(199, 210, 219, 0.4);
  -moz-box-shadow: 0 1px 3px 0 rgba(199, 210, 219, 0.4);
  box-shadow: 0 1px 3px 0 rgba(199, 210, 219, 0.4);
}
@media screen and (min-width: 64em) {
  .accordion.accordion--card {
    padding: 24px;
  }
}
@media screen and (min-width: 75em) {
  .accordion.accordion--card {
    padding: 32px;
  }
}
.accordion.accordion--card .icon {
  padding: 0 4px 0 0;
  line-height: 1em;
}
.accordion.accordion--card .accordion__header {
  background-color: #ffffff;
  line-height: inherit;
  min-height: 50px;
  max-height: initial;
  padding: 0;
  pointer-events: none;
  margin-bottom: 36px;
}
.accordion.accordion--card .accordion__header:after {
  font-family: inherit;
  content: "More";
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  display: inline-block;
  transform: none;
  margin: 0 !important;
  min-width: auto !important;
  pointer-events: all;
}
.accordion.accordion--card .accordion__header:hover {
  background-color: initial;
  cursor: initial;
}
.accordion.accordion--card .accordion__header:hover:after {
  cursor: pointer;
}
.accordion.accordion--card .accordion__header .icon {
  font-size: 18px;
  color: #6D6C6C;
}
.accordion.accordion--card.accordion--locked {
  display: inline-block;
  max-height: initial;
  width: 100%;
  overflow: visible;
}
.accordion.accordion--card.accordion--locked .accordion__header:after {
  display: none;
}
.accordion.accordion--card.accordion--locked .accordion__header:hover {
  background-color: initial;
  cursor: initial;
}
.accordion.accordion--card.accordion--active .accordion__header:after {
  content: "Less";
  transform: none;
}
.accordion.accordion--card .accordion__details {
  padding: 0;
}
.accordion.accordion--card .accordion__details .icon {
  font-size: 16px;
  color: #98a4ae;
}
.accordion .accordion__header {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
  font-size: 14px;
  color: #3B4043;
  letter-spacing: 0;
  background-color: #DDE0E4;
  padding: 0 8px;
  max-height: 2em;
  overflow: hidden;
  -webkit-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  cursor: pointer;
  vertical-align: bottom;
  line-height: 2em;
}
.accordion .accordion__header:hover {
  background-color: #c1c6cd;
}
.accordion .accordion__header:after {
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 8px;
  padding: 0 0 0 8px;
  display: inline-block;
  content: "";
  transform-origin: 67% 30%;
  -webkit-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
}
.accordion .accordion__header.full-width-inherit {
  width: 100%;
  text-align: inherit;
}
.accordion .accordion__header .icon {
  font-size: 16px;
  padding: 0 4px 0 0;
  line-height: 1em;
  color: #535a5e;
}
.accordion--active .accordion__header:after {
  transform: rotate(180deg);
  -webkit-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
}
.accordion .accordion__details {
  padding: 16px 32px 16px 16px;
  position: relative;
  min-height: 120px;
  visibility: hidden;
}
.accordion--active, .accordion--locked {
  display: inline;
  border-bottom: 1px solid #DDE0E4;
}
.accordion--active .accordion__details, .accordion--locked .accordion__details {
  visibility: visible;
}

.accordion__more {
  position: absolute;
  right: 12px;
  bottom: 12px;
  margin: -15px 0 0 0;
  color: #005E8C;
  border-radius: 2px;
  text-decoration-line: underline;
  font-weight: 700;
  font-size: 13px;
}
.accordion__more:after {
  display: inline-block;
  font-family: "RapidRatings";
  content: "";
  font-size: 10px;
  margin-left: 2px;
}
.accordion__more:focus {
  margin-bottom: -2px;
  margin-right: -2px;
  padding: 2px;
}

[revealer] {
  cursor: pointer;
}

[reveal] {
  display: none;
}

[reveal_on_action] {
  display: none;
}

/* remove tax calculation form when tax results are displayed in NSP registration */
.revealer--show + [reveal_from_store=showTaxForm] {
  display: none;
}

.revealer--show {
  display: block;
}
.revealer--nav button {
  margin-top: auto;
  margin-bottom: 24px;
}
.revealer--nav button:last-of-type {
  margin-right: 24px;
}
.revealer--active {
  background-color: #ffffff;
  position: relative;
}
.revealer--active .company__details:after, .revealer--active .accordion__details:after {
  content: "";
  font-family: RapidRatings;
  color: #98a4ae;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 6em;
}
.revealer--active .accordion__more {
  display: none;
}

summary.revealer--active:after {
  margin-top: 14px;
}

.table {
  width: 100%;
  max-width: 100%;
  text-align: left;
}
.table td,
.table th {
  padding: 4px;
  vertical-align: top;
}
.table thead th {
  vertical-align: bottom;
}
.table--verticalMiddle th, .table--verticalMiddle td {
  vertical-align: middle;
}
.table--summary {
  border: none;
  background-color: #F4F6F9;
  margin-bottom: 25px;
}
.table--summary thead tr:last-child {
  border-bottom: 1px solid #d3dbe7;
}
.table--summary thead tr th {
  font-weight: 700;
  font-size: 13px;
  color: #3B4043;
  letter-spacing: 0;
  line-height: 12px;
}
.table--summary td,
.table--summary th {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  padding: 4px;
  vertical-align: top;
  text-align: left;
}
.table--summary td.trend,
.table--summary th.trend {
  text-align: center;
  width: 18px;
}
.table--summary td.trend .icon,
.table--summary th.trend .icon {
  font-size: 8px;
}
.table--summary td.numeric,
.table--summary th.numeric {
  text-align: right;
}
.table--summary td.numeric--increase:before,
.table--summary th.numeric--increase:before {
  margin: 0 4px 0 0;
  font-size: 8px;
  content: "";
}
.table--summary td.numeric--decrease:before,
.table--summary th.numeric--decrease:before {
  margin: 0 4px 0 0;
  font-size: 8px;
  content: "";
}
.table--summary tbody tr {
  border-bottom: 1px solid #d3dbe7;
}
.table--summary tbody tr.tr--good, .table--summary tbody tr.tr--increase {
  background-color: #7bd476;
}
.table--summary tbody tr.tr--good th:first-child:before, .table--summary tbody tr.tr--increase th:first-child:before {
  margin: 0 4px 0 0;
  font-size: 10px;
  content: "";
  color: #143912;
}
.table--summary tbody tr.tr--bad, .table--summary tbody tr.tr--decrease {
  background-color: #f69692;
}
.table--summary tbody tr.tr--bad th:first-child:before, .table--summary tbody tr.tr--decrease th:first-child:before {
  margin: 0 4px 0 0;
  font-size: 10px;
  content: "";
  color: #7e0f0b;
}
.table--spaced {
  border-right: 1px solid #C7D2DB;
  border-top: 4px solid #F4F6F9;
  border-bottom: 4px solid #F4F6F9;
}
.table--spaced strong {
  font-weight: 600;
}
.table--spaced th {
  color: #3B4043;
  border-top: 4px solid #F4F6F9;
  border-bottom: 4px solid #F4F6F9;
}
.table--spaced td,
.table--spaced th {
  padding: 16px 12px;
  font-size: 14px;
  border-left: 1px solid #C7D2DB;
  vertical-align: middle !important;
  text-align: left;
}
.table--spaced td.th--selected,
.table--spaced th.th--selected {
  color: #3B4043;
  font-weight: 800;
  border-bottom: 4px solid #3B4043;
}
.table--spaced td.th--sort,
.table--spaced th.th--sort {
  position: relative;
  cursor: pointer;
}
.table--spaced td.th--sort:after,
.table--spaced th.th--sort:after {
  font-size: 8px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -2px;
}
.table--spaced td.th--asc:after,
.table--spaced th.th--asc:after {
  content: "";
}
.table--spaced td.th--desc:after,
.table--spaced th.th--desc:after {
  content: "";
}
.table--spaced td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.table--spaced td.cs {
  -webkit-transition: background-color 300ms ease-in-out;
  -moz-transition: background-color 300ms ease-in-out;
  -ms-transition: background-color 300ms ease-in-out;
  -o-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;
  /**
  * from mixins/_statusIndictators.scss
  * outputs .cs--veryhigh, .cs--high, .cs--medium etc, at 24px
  */
}
.table--spaced td.cs.cs--veryhigh {
  line-height: 24px;
}
.table--spaced td.cs.cs--veryhigh:before {
  content: "";
  color: #ffffff;
  background-color: #DC1B13;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
  border-radius: 100%;
}
.table--spaced td.cs.cs--high {
  line-height: 24px;
}
.table--spaced td.cs.cs--high:before {
  content: "";
  color: #3B4043;
  background-color: #F5B353;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
  border-radius: 100%;
}
.table--spaced td.cs.cs--medium {
  line-height: 24px;
}
.table--spaced td.cs.cs--medium:before {
  content: "";
  color: #3B4043;
  background-color: #F7E856;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
  border-radius: 100%;
}
.table--spaced td.cs.cs--low {
  line-height: 24px;
}
.table--spaced td.cs.cs--low:before {
  content: "";
  color: #ffffff;
  background-color: #2F872A;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
  border-radius: 100%;
}
.table--spaced td.cs.cs--verylow {
  line-height: 24px;
}
.table--spaced td.cs.cs--verylow:before {
  content: "";
  color: #3B4043;
  background-color: #6FBFEA;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
  border-radius: 100%;
}
@media (min-width: 320px) and (max-width: 767px) {
  .table--spaced td.cs[abbr]:after {
    content: attr(abbr);
    width: 100%;
    display: block;
  }
  .table--spaced td.cs[abbr] span {
    display: none;
  }
}
.table--spaced td.td--restricted {
  max-width: 200px;
}
.table--spaced td.td--lowercase {
  text-transform: lowercase;
}
.table--spaced tbody tr:nth-child(even) {
  background-color: #F4F6F9;
}
.table--spaced tbody tr:nth-last-child(2) {
  border-bottom: 1px solid #C7D2DB;
}
.table--spaced tbody tr:hover {
  background-color: #e4e9f0;
  -webkit-transition: background-color 300ms ease-in-out;
  -moz-transition: background-color 300ms ease-in-out;
  -ms-transition: background-color 300ms ease-in-out;
  -o-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;
}
.table--spaced tbody tr.tr--expandable {
  border-left: none;
  background-color: #ffffff;
}
.table--spaced tbody tr.tr--expandable td:first-child {
  animation-duration: 0;
  animation-name: "";
  border-left: 1px solid #017BC1;
  border-right: 1px solid #C7D2DB;
  background: -moz-linear-gradient(right, #017BC1 0px, #017BC1 3px, #ffffff 3px, #ffffff 100%);
  background: -webkit-linear-gradient(right, #017BC1 0px, #017BC1 3px, #ffffff 3px, #ffffff 100%);
  background: linear-gradient(to right, #017BC1 0px, #017BC1 3px, #ffffff 3px, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= $cerulean-700, endColorstr=$white,GradientType=1 );
}
.table--spaced tbody tr.tr--expandable:hover {
  cursor: default;
  background-color: transparent;
}
.table--spaced tbody tr.tr--expandable .revealer--show {
  display: table-cell;
}
.table--spaced tbody tr.tr--expandable div {
  margin: 0 0 14px 0;
}
.table--spaced tbody tr.tr--expandable div strong {
  font-size: 12px;
  color: #3B4043;
  display: block;
}
.table--spaced tbody tr .icon {
  text-align: center;
  display: block;
}
.table--spaced tbody tr .icon:before {
  transform-origin: 50% 30%;
  display: inline-block;
  font-size: 8px;
}
.table--spaced tbody tr.active {
  background: #E4F7FB;
}
.table--spaced tbody tr.active td:first-child {
  border-left: none;
  background: -moz-linear-gradient(right, #017BC1 0px, #017BC1 4px, #E4F7FB 4px, #E4F7FB 100%);
  background: -webkit-linear-gradient(right, #017BC1 0px, #017BC1 4px, #E4F7FB 4px, #E4F7FB 100%);
  background: linear-gradient(to right, #017BC1 0px, #017BC1 4px, #E4F7FB 4px, #E4F7FB 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= $cerulean-700, endColorstr=$white,GradientType=1 );
}
.table--spaced tbody tr.active .icon:before {
  transform: rotate(180deg);
}
.table--light {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}
.table--light thead th {
  background-color: #98a4ae;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}
.table--bordered {
  border: 1px solid #51585D;
}
.table--bordered tr {
  border-bottom: 1px solid #51585D;
}
.table--bordered td:not(:first-child), .table--bordered th:not(:first-child) {
  border-left: 1px solid #51585D;
}
.table--bordered td:not(:last-child), .table--bordered th:not(:last-child) {
  border-right: 1px solid #51585D;
}
.table--layoutFixed {
  table-layout: fixed;
}
@media only screen and (max-width: 576px) {
  .table--responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.table--expandable-rows tbody tr:nth-of-type(4n-7) {
  background: #F4F6F9;
}
.table--expandable-rows tbody tr:nth-of-type(4n-7):hover {
  background-color: #e4e9f0;
  -webkit-transition: background-color 300ms ease-in-out;
  -moz-transition: background-color 300ms ease-in-out;
  -ms-transition: background-color 300ms ease-in-out;
  -o-transition: background-color 300ms ease-in-out;
  transition: background-color 300ms ease-in-out;
}

.pill {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  line-height: 24px;
  background: #51585D;
  border-radius: 15px;
  color: #000000;
  letter-spacing: 0;
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 8px 0 8px;
}
.pill--transparent {
  background: transparent;
  border: 1px solid #51585D;
}
.pill--transparent .pill--value {
  color: #000000;
}
.pill__status--processing {
  background: #F5B353;
}
.pill__status--required {
  background: #d30547;
}
.pill__status--sent {
  background: #2F872A;
}
.pill--default {
  background: white;
  border: 1px solid #5C6367;
}
.pill--value {
  display: inline-block;
  padding: 0 12px;
  color: #ffffff;
  font-weight: bold;
  min-width: 44px;
}
.pill--delete {
  background: #3B4043;
  color: #ffffff;
  border: 0;
  border-radius: 15px;
  margin-right: 2.5px;
  cursor: pointer;
  line-height: initial;
  vertical-align: text-top;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 10px;
  padding: initial;
}

.body--accounts .login,
.rmodal {
  height: 100vh;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.body--accounts .login .logo,
.rmodal .logo {
  width: 300px;
  margin: 140px 0 16px;
}
@media screen and (min-width: 1024px) {
  .body--accounts .login .logo,
.rmodal .logo {
    margin: 24px 0 16px;
  }
}
.body--accounts .login .login__body,
.rmodal .login__body {
  width: 100%;
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (min-width: 1024px) {
  .body--accounts .login .login__body,
.rmodal .login__body {
    width: 50%;
  }
}
.body--accounts .login .login__body .login__form-container,
.rmodal .login__body .login__form-container {
  max-width: 379px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.body--accounts .login .login__body .errors,
.rmodal .login__body .errors {
  margin: 24px 0 0;
}
.body--accounts .login .login__form label,
.rmodal .login__form label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #64676E;
  line-height: 16px;
}
.body--accounts .login .login__form input,
.rmodal .login__form input {
  height: 40px;
  background-color: white;
  border: 1px solid #C7D2DB;
  border-radius: 2px;
}
.body--accounts .login .login__form .passwordContainer,
.rmodal .login__form .passwordContainer {
  position: relative;
}
.body--accounts .login .login__form .passwordContainer input::-ms-clear,
.rmodal .login__form .passwordContainer input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.body--accounts .login .login__footer,
.rmodal .login__footer {
  padding: 0;
  margin-top: auto;
  margin-bottom: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.body--accounts .login .login__footer p,
.rmodal .login__footer p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #1b1f29;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
.body--accounts .login .login__footer ul,
.rmodal .login__footer ul {
  text-align: center;
  padding: 8px 0 16px 8px;
}
.body--accounts .login .login__footer ul li,
.rmodal .login__footer ul li {
  display: inline-block;
}
.body--accounts .login .login__footer ul li a,
.rmodal .login__footer ul li a {
  font-size: 14px;
  color: #005E8C;
  letter-spacing: 0;
  margin: 0 8px 0 0;
}
.body--accounts .login .login__title,
.rmodal .login__title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 26px;
  font-weight: normal;
  color: #1b1f29;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 104px;
  margin-bottom: 24px;
  font-family: "Source Sans Pro";
}
.body--accounts .login .login__input,
.rmodal .login__input {
  background-color: #ffffff;
  border: 1px solid #C7D2DB;
}

div.toast.log-back-in {
  position: fixed;
  display: flex;
  top: 0;
  width: 100vw;
  height: 64px;
  z-index: 10;
  text-align: center;
  padding: 20px 0px;
  background-color: #e7faf3;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: 0.75s ease-out;
  font-family: "Source Sans Pro";
  transform: translateY(0px);
}

div.toast.log-back-in > p {
  margin-left: auto;
  margin-right: auto;
  background: none;
  outline: none;
  border: none;
  font-size: 16px;
  color: #006647;
  font-weight: 700;
  font-family: "Source Sans Pro";
}

div.toast.log-back-in.hide {
  transform: translateY(-100px);
}

.modal__background {
  background: rgba(0, 0, 0, 0.5);
}

.modal__outter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2;
}

.modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__container {
  background-color: #ffffff;
  max-height: 340px;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 25vh;
  left: 0;
  right: 0;
  padding: 34px 34px 24px;
  border-radius: 2px;
  z-index: 2;
}

.modal__header h2, .modal__header .ReactTable .rt-noData--textLower, .ReactTable .modal__header .rt-noData--textLower, .modal__header .ReactTable .rt-noData--text, .ReactTable .modal__header .rt-noData--text, .modal__header .visually__h2 {
  display: inline-block;
  max-width: 90%;
}
.modal__header .rbtn, .modal__header .main__supplierPay .stripe-button-el, .main__supplierPay .modal__header .stripe-button-el, .modal__header .input__group .file__input--label, .input__group .modal__header .file__input--label, .modal__header .react-fine-uploader-gallery-file-input-container {
  font-size: 1.2em;
  color: #51585d;
  min-width: 0;
  float: right;
  border: none;
  line-height: 36px;
  padding: 0;
  margin: 0;
}
.modal__header .rbtn:hover, .modal__header .main__supplierPay .stripe-button-el:hover, .main__supplierPay .modal__header .stripe-button-el:hover, .modal__header .input__group .file__input--label:hover, .input__group .modal__header .file__input--label:hover, .modal__header .react-fine-uploader-gallery-file-input-container:hover {
  background-color: transparent;
}

.modal__content {
  margin: 32px auto;
  line-height: initial;
  max-height: 160px;
}
.modal__content p {
  display: inline-block;
}
.modal__content .link {
  text-transform: initial;
  font-size: 14px;
}

.modal__footer {
  margin: 16px auto 0 auto;
  text-align: right;
}

modal .link {
  text-decoration: underline;
}
modal .link:hover {
  cursor: pointer;
}

.rrModal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.25s ease;
}
.rrModal.rrModal--loading .modal__bg {
  cursor: initial;
}
.rrModal.rrModal--loading img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.rrModal .modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.rrModal .modal__inner {
  padding: 34px 34px 24px;
  width: 50vw;
  max-height: 95vh;
  overflow-y: auto;
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  margin: 1.75rem auto;
  transition: all 0.25s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rrModal .modal__header {
  position: relative;
}
.rrModal .modal__header h1, .rrModal .modal__header h3, .rrModal .modal__header .visually__h1, .rrModal .modal__header .visually__h2, .rrModal .modal__header .visually__h3, .rrModal .modal__header h2, .rrModal .modal__header .ReactTable .rt-noData--textLower, .ReactTable .rrModal .modal__header .rt-noData--textLower, .rrModal .modal__header .ReactTable .rt-noData--text, .ReactTable .rrModal .modal__header .rt-noData--text {
  max-width: 95%;
}
.rrModal .modal__header .modal__close {
  position: absolute;
  right: 0;
  top: 0;
}
.rrModal .modal__content {
  margin: initial;
  line-height: normal;
  max-height: 100%;
  width: 100%;
}
.rrModal .modal__footer {
  margin: 0;
  background: #ffffff;
}
.rrModal .modal__footer .rbtn:last-child, .rrModal .modal__footer .main__supplierPay .stripe-button-el:last-child, .main__supplierPay .rrModal .modal__footer .stripe-button-el:last-child, .rrModal .modal__footer .input__group .file__input--label:last-child, .input__group .rrModal .modal__footer .file__input--label:last-child, .rrModal .modal__footer .react-fine-uploader-gallery-file-input-container:last-child {
  margin-right: 0;
}
.rrModal .modal__close {
  font-size: 1.2em;
  color: #51585D;
  line-height: 36px;
}
.rrModal .modal__close:hover {
  cursor: pointer;
}
.rrModal .modal__close:after {
  font-family: RapidRatings;
  content: "";
}
@media screen and (max-width: 768px) {
  .rrModal .modal__inner {
    width: 90vw;
    box-sizing: border-box;
  }
}

.modal-state {
  display: none;
}
.modal-state:checked + .rrModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 6;
}

@media screen and (min-width: 1024px) {
  .modal_screens {
    display: flex;
  }
}

.divisor {
  display: flex;
  margin-bottom: 24px;
}
.divisor p {
  margin: 0px 10px;
}

.line {
  height: 1px;
  background-color: #979797;
  width: 50%;
  align-self: center;
}

.loader {
  display: inline-block;
  margin: 0;
  width: 20px;
  height: 17px;
}
.loader .loader__animation {
  border-radius: 100%;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 2px 0 0 -4px;
}
.loader .loader__animation:after, .loader .loader__animation:before {
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  content: "";
  position: absolute;
  background-color: #fff;
  top: 2px;
  left: 45%;
  height: 8px;
  width: 3px;
  -webkit-transform-origin: 50% 94%;
  transform-origin: 50% 94%;
}
.loader .loader__animation:after {
  -webkit-animation: degree360 10000ms linear infinite;
  animation: degree360 10000ms linear infinite;
}
.loader .loader__animation:before {
  -webkit-animation: degree360 2000ms linear infinite;
  animation: degree360 2000ms linear infinite;
}

@-webkit-keyframes degree360 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes degree360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.return--background {
  position: absolute;
  min-width: 250px;
  top: 134px;
  right: 0;
  padding: 0 12px;
  color: #ffffff;
  background: linear-gradient(to right top, #c90544 50%, #d30547 50%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 6px 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 6px 100%);
  z-index: 3;
}
@media screen and (min-width: 1280px) {
  .return--background {
    top: 84px;
  }
}
.return--title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.return .link {
  text-transform: initial;
  color: #ffffff;
  float: right;
}
.return .link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.disclaimer__text {
  height: calc(80vh - 200px);
  min-height: 140px;
  overflow-y: auto;
  margin-bottom: 32px;
}

.fhrNetwork--main {
  min-height: 100vh;
  padding-top: 24px;
  background-color: #F4F6F9;
}
.fhrNetwork .loading__container {
  position: relative;
  min-height: 250px;
}
.fhrNetwork .loading__image {
  margin: 0 auto;
  display: block;
  position: absolute;
  right: -25px;
}
.fhrNetwork .counterparty {
  font-size: 14px;
  line-height: 18px;
}
.fhrNetwork .counterparty--logo {
  max-width: 100px;
  width: 100%;
}
.fhrNetwork .counterparty--requested {
  font-size: 12px;
  color: #3B4043;
}
.fhrNetwork--footer {
  padding: 24px 0;
  background-color: #F4F6F9;
  font-family: Arial;
  text-align: center;
}
.fhrNetwork--footer p {
  font-family: Arial;
  color: #828C94;
}
.fhrNetwork--footer .link {
  color: #828C94;
}
.fhrNetwork--footer ul {
  margin: 12px auto;
}
.fhrNetwork--footer ul.social {
  font-size: 20px;
}
.fhrNetwork--footer ul.social a {
  color: #828C94;
}
.fhrNetwork--footer ul li {
  display: inline;
  margin: auto 8px;
}

/* HealthMark CSS */
.healthMark--profile {
  -webkit-print-color-adjust: exact;
}

.healthMark {
  background-color: #F4F6F9;
  min-height: calc(100vh - 84px);
  padding-bottom: 8px;
}
.healthMark--footer {
  background-color: #F4F6F9;
  padding: 8px 0;
}
.healthMark--map {
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.healthMark .anchors {
  width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #F4F6F9 0%, #EBEEF2 100%);
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid #C7D2DB;
}

.healthMark__cards {
  display: inline-block;
  max-height: initial !important;
  width: 100%;
}

.health-mark-profile-search {
  margin: 14px 0px 14px 0px;
}

.health-mark-task-bar,
.task-bar {
  background-color: #ffffff;
  border-bottom: none;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  top: 84px;
  z-index: 4;
}
.health-mark-task-bar__left,
.task-bar__left {
  border-bottom: 1px solid #C7D2DB;
}
@media screen and (min-width: 1024px) {
  .health-mark-task-bar__left,
.task-bar__left {
    border-bottom: none;
  }
}
.health-mark-task-bar__left button,
.task-bar__left button {
  float: left;
  border-right: 1px solid #C7D2DB;
}
.health-mark-task-bar__left button:first-of-type,
.task-bar__left button:first-of-type {
  margin-left: -0.5rem;
}
.health-mark-task-bar__right,
.task-bar__right {
  border-bottom: 1px solid #C7D2DB;
}
@media screen and (min-width: 1024px) {
  .health-mark-task-bar__right,
.task-bar__right {
    border-bottom: none;
  }
}
.health-mark-task-bar__right button,
.task-bar__right button {
  float: right;
  border-left: 1px solid #C7D2DB;
}
.health-mark-task-bar__right button:first-of-type,
.task-bar__right button:first-of-type {
  margin-right: -0.5rem;
}
.health-mark-task-bar button,
.task-bar button {
  min-width: initial;
  margin: 0;
}
.health-mark-task-bar button .icon,
.task-bar button .icon {
  font-size: 20px;
  vertical-align: middle;
  color: #3B4043;
}
@media screen and (min-width: 1024px) {
  .health-mark-task-bar,
.task-bar {
    border-bottom: 1px solid #C7D2DB;
  }
}

.gauge--image {
  width: 100%;
  min-width: 210px;
  max-width: 210px;
}

.list--icon {
  max-width: 46px;
}

.iconImage--medium {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
}
.iconImage--large {
  max-width: 64px;
  max-height: 64px;
  width: 100%;
}

.dataType {
  max-width: 76px;
  max-height: 76px;
  position: relative;
}
@media screen and (min-width: 75em) {
  .dataType {
    max-width: 108px;
    max-height: 108px;
  }
}
.dataType--image {
  width: 100%;
}

.list_image {
  height: 80px;
  width: 80px;
  background-position: center;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
}
.list_image.edit_list_image {
  background-image: url("../imgs/edit_list.svg");
}
.list_image.org_list_image {
  background-image: url("../imgs/org_list.svg");
}
.list_image.personal_list_image {
  background-image: url("../imgs/personal_list.svg");
}

.companyIcon {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}
.companyIcon--lg {
  min-width: 64px;
  min-height: 64px;
}
.companyIcon--parent {
  background-image: url("../imgs/ParentCompanyIcon.svg");
}
.companyIcon--parent--lg {
  background-image: url("../imgs/ParentCompanyIconLrg.svg");
}
.companyIcon--child {
  background-image: url("../imgs/ChildCompanyIcon.svg");
}
.companyIcon--child--lg {
  background-image: url("../imgs/ChildCompanyIconLrg.svg");
}
.companyIcon--firmographic {
  background-image: url("../imgs/firmographic-icon.svg");
}

.paging-item button {
  padding: 0 8px;
}

.status:after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 2px;
  border-radius: 50%;
  vertical-align: super;
}
.status--required:after {
  background-color: #d30547;
}
.status--analysis:after {
  background-color: #F5B353;
}
.status--rated:after, .status--submitted:after {
  background-color: #2F872A;
}

.statuses {
  display: block;
  margin: 0;
  padding: 0;
  color: #3B4043;
  font-size: 14px;
  line-height: 30px;
}
.statuses .stat {
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.statuses .stat .stat__count,
.statuses .stat .stat__text {
  padding: 0 6px;
}
.statuses .stat .stat__count {
  float: right;
}
.statuses .stat--parent > div.stat__text:first-of-type {
  font-weight: 600;
}
.statuses .stat--parent > ul.statuses:first-of-type .stat {
  padding: 0 0 0 8px;
}
.statuses .stat--active {
  background: -moz-linear-gradient(top, #F4F6F9 0, #F4F6F9 30px, rgba(0, 0, 0, 0) 31px);
  background: -webkit-linear-gradient(top, #F4F6F9 0, #F4F6F9 30px, rgba(0, 0, 0, 0) 31px);
  background: linear-gradient(to bottom, #F4F6F9 0, #F4F6F9 30px, rgba(0, 0, 0, 0) 31px);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= "#F4F6F9", endColorstr="#F4F6F9",GradientType=0 );
  background-repeat: no-repeat;
}
.statuses .stat--active .stat {
  background-color: white;
}

.filter--container {
  margin: 16px auto;
  display: inline-block;
  position: relative;
}
.filter--container:not(:last-of-type) {
  margin-right: 8px;
}
.filter--container.borderRight {
  margin-right: 32px;
}
.filter--container.borderRight:after {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  right: -16px;
  background-color: #c7d2db;
}

.select--container {
  display: inline-block;
  min-width: 200px;
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
  border: none;
  height: 1px;
  width: 100%;
  color: #C7D2DB;
  /* old IE */
  background-color: #C7D2DB;
  /* Modern Browsers */
  display: inline-block;
}

.footer__about {
  max-width: 244px;
  margin: 48px auto;
}
.footer__about p {
  text-align: center;
  font-family: Arial;
  color: #828C94;
  font-size: 12px;
  margin: 0 0 12px;
}
.footer__about ul {
  text-align: center;
  margin: 0 0 12px;
}
.footer__about ul li {
  display: inline-block;
  margin: 0 4px 0 0;
}
.footer__about ul li a {
  color: #828C94;
  font-weight: bold;
  font-size: 12px;
}
.footer__about ul li a.social__icons {
  text-decoration: none;
  font-size: 20px;
  margin: 0 8px 0 0;
}

.activities {
  margin: 24px 0;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.activities .activity {
  display: table;
  margin: 0 0 24px;
}
.activities .activity:last-child {
  margin: 0;
}
.activities .activity .activity__thumb {
  display: table-cell;
}
.activities .activity .activity__thumb .profile {
  height: 48px;
  width: 48px;
  margin: 0 6px 0 0;
  color: white;
  background-color: #4A4A4A;
  border-radius: 100%;
  line-height: 48px;
  text-align: center;
  font-size: 25px;
}
.activities .activity .activity__details {
  display: table-cell;
  vertical-align: top;
}
.activities .activity .activity__details .pill {
  margin: 10px 0 0;
}
.activities .activity .activity__details .activity__files,
.activities .activity .activity__details .activity__message,
.activities .activity .activity__details .activity__time {
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 8px 0 0 8px;
}
.activities .activity .activity__details .activity__files,
.activities .activity .activity__details .activity__message {
  font-size: 14px;
  color: #000000;
  line-height: 18px;
}
.activities .activity .activity__details .activity__files li::before {
  content: "";
  font-size: 16px;
}
.activities .activity .activity__details .activity__time {
  color: #3B4043;
  font-size: 12px;
  line-height: 12px;
}

.rbtn__group .DayPicker_weekHeader_ul {
  padding: 0;
  box-shadow: none;
  border: none;
  width: initial;
  position: relative;
  background-color: transparent;
  display: inline-block;
}
.rbtn__group .DayPicker_weekHeader_ul li {
  border-bottom: none;
}

.rrModal .SingleDatePicker_picker {
  display: inline-block;
  position: relative;
  top: 0 !important;
}
.rrModal .DateInput_fang {
  display: none;
}
.rrModal input.DateInput_input {
  display: inline-block;
  width: initial;
}

.please_wait_animation {
  background: url(../imgs/please_wait_animation.gif) no-repeat;
  background-position: 50%;
  height: 170px;
  position: relative;
}

.spinner_animation {
  background: url(../imgs/spinner_loading.svg) no-repeat;
  background-position: 50%;
  height: 24px;
  position: relative;
}

.rr_logo_animation {
  background: url(../imgs/RR_logo_loader_transparent_433x472.gif) no-repeat;
  background-position: 50%;
  background-size: contain;
  height: 170px;
  position: relative;
}

.tooltip {
  padding: 16px;
}
.tooltip.type-dark p {
  color: #ffffff;
}
.tooltip a {
  color: #ffe900;
}
.tooltip a:hover {
  color: #F7E856;
}
.tooltip.__react_component_tooltip {
  background-color: #3B4043;
  opacity: 1;
  width: 100%;
  max-width: 225px;
}
.tooltip.__react_component_tooltip.place-top:after {
  border-top-color: #3B4043;
}
.tooltip.__react_component_tooltip.place-left:after {
  border-left-color: #3B4043;
}
.tooltip.__react_component_tooltip.place-right:after {
  border-right-color: #3B4043;
}
.tooltip.__react_component_tooltip.place-bottom:after {
  border-bottom-color: #3B4043;
}

[role=tab]:focus:before, [role=tab]:hover:before, [role=tab][aria-selected=true]:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: -1px;
  left: -1px;
  border-bottom: 4px solid #00afd7;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
[role=tab][aria-selected=true]:not(:focus):before, [role=tab][aria-selected=true]:not(:hover):before {
  border-bottom: 4px solid #00afd7;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  border-color: #00afd7;
  transition: border-color 0.25s ease-in-out;
}

fieldset.fieldset__radio label, *.rbtn, .main__supplierPay .stripe-button-el, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container, .accordion.accordion--card .accordion__header:after {
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  min-width: 95px;
  cursor: pointer;
  padding: 0 8px;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  outline: none;
  margin: 0 4px 0 0;
  color: #3B4043;
  background-color: #F4F6F9;
  border: 1px solid #3B4043;
}
fieldset.fieldset__radio label:active, .rbtn:active, .main__supplierPay .stripe-button-el:active, .input__group .file__input--label:active, .react-fine-uploader-gallery-file-input-container:active, .accordion.accordion--card .accordion__header:active:after, fieldset.fieldset__radio label:focus, .rbtn:focus, .main__supplierPay .stripe-button-el:focus, .input__group .file__input--label:focus, .react-fine-uploader-gallery-file-input-container:focus, .accordion.accordion--card .accordion__header:focus:after, fieldset.fieldset__radio label:hover, .rbtn:hover, .main__supplierPay .stripe-button-el:hover, .input__group .file__input--label:hover, .react-fine-uploader-gallery-file-input-container:hover, .accordion.accordion--card .accordion__header:hover:after {
  background-color: #d3dbe7;
}

*.rbtn--primary, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container, fieldset.fieldset__radio input[type=radio]:checked + label {
  color: #ffffff;
  background-color: #017BC1;
  border: 1px solid #017BC1;
}
*.rbtn--primary.rbtn--ghost, .input__group *.rbtn--ghost.file__input--label, *.rbtn--ghost.react-fine-uploader-gallery-file-input-container, fieldset.fieldset__radio input[type=radio]:checked + label.rbtn--ghost {
  color: #017BC1;
}
*.rbtn--primary:active, .input__group *.file__input--label:active, *.react-fine-uploader-gallery-file-input-container:active, fieldset.fieldset__radio input[type=radio]:checked + label:active, *.rbtn--primary:focus, .input__group *.file__input--label:focus, *.react-fine-uploader-gallery-file-input-container:focus, fieldset.fieldset__radio input[type=radio]:checked + label:focus, *.rbtn--primary:hover, .input__group *.file__input--label:hover, *.react-fine-uploader-gallery-file-input-container:hover, fieldset.fieldset__radio input[type=radio]:checked + label:hover {
  color: #ffffff;
  background-color: #005E8C;
  text-decoration: none;
}
*.rbtn--secondary, .main__supplierPay .stripe-button-el {
  color: #000000;
  background-color: #ffe900;
  border: 1px solid #ffe900;
}
*.rbtn--secondary.rbtn--ghost, .main__supplierPay *.rbtn--ghost.stripe-button-el {
  color: #ffe900;
}
*.rbtn--secondary:active, .main__supplierPay *.stripe-button-el:active, *.rbtn--secondary:focus, .main__supplierPay *.stripe-button-el:focus, *.rbtn--secondary:hover, .main__supplierPay *.stripe-button-el:hover {
  color: #ffffff;
  background-color: #ccba00;
  border: 1px solid #ccba00;
}
*.rbtn--danger {
  color: #000000;
  background-color: #d30547;
  border: 1px solid #d30547;
}
*.rbtn--danger.rbtn--ghost {
  color: #d30547;
}
*.rbtn--danger:focus, *.rbtn--danger:hover {
  color: #ffffff;
  background-color: #a10436;
  border: 1px solid #a10436;
}
*.rbtn--dark {
  background-color: #51585D;
  border-color: #51585D;
  color: #ffffff;
}
*.rbtn--dark:active, *.rbtn--dark:focus, *.rbtn--dark:hover {
  color: #ffffff;
  background-color: #3B4043;
}
*.rbtn--white {
  background-color: #ffffff;
  border-color: #98a4ae;
  color: #3B4043;
  font-weight: 600;
  border-radius: 2px;
}
*.rbtn--white:active, *.rbtn--white:focus, *.rbtn--white:hover {
  color: #3B4043;
  background-color: transparent;
}
*.rbtn--anchor {
  background-color: transparent;
  border: transparent;
  color: #A3B0BB;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.28px;
  padding: 16px 24px;
  margin: 0;
  position: relative;
}
@media screen and (min-width: 75em) {
  *.rbtn--anchor {
    padding: 16px 32px;
  }
}
*.rbtn--anchor:after {
  display: block;
  content: "";
  border-bottom: solid 4px #017BC1;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
*.rbtn--anchor--active {
  color: #231F20;
}
*.rbtn--anchor--active:after {
  transform: scaleX(1);
}
*.rbtn--anchor:hover:after {
  border-color: #51585D;
  transform: scaleX(1);
}
*.rbtn--anchor:hover:active:after {
  transform: scaleX(1);
  border-color: #017BC1;
  transition: border-color 250ms ease-in-out;
}
*.rbtn--anchor:active, *.rbtn--anchor:focus, *.rbtn--anchor:hover {
  background-color: transparent;
}
*.rbtn--anchor:focus {
  outline: auto;
}
*.rbtn--ghost {
  background-color: transparent;
}
*.rbtn--transparent, fieldset.fieldset__radio label {
  background-color: transparent;
  border-color: transparent;
}
*.rbtn--disabled {
  background-color: #D8D9DC;
  color: #828C94;
}
*.rbtn--block {
  display: block;
  width: 100%;
}
*.rbtn--right {
  float: right;
}
*.rbtn--borderless {
  border: none;
}
*.rbtn--md {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
*.rbtn--lg {
  height: 52px;
  line-height: 52px;
  padding: 0 16px;
}
*.rbtn--textStandard {
  font-size: 14px;
}
*.rbtn--onFocus {
  outline-offset: 2px;
}
@media only screen and (min-width: 75em) {
  *.rbtn--responsive {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
  }
}
*.rbtn--geyser {
  color: #828C94;
  border: 1px solid #C7D2DB;
  background-color: #F4F6F9;
  font-size: 16px;
  font-weight: normal;
  min-width: initial;
  position: relative;
}
*.rbtn--geyser--active {
  color: #017BC1;
  background-color: #ffffff;
}
*.rbtn--geyser--active:focus {
  background-color: #ffffff;
}
*.rbtn--group .rbtn:not(:last-of-type), *.rbtn--group .main__supplierPay .stripe-button-el:not(:last-of-type), .main__supplierPay *.rbtn--group .stripe-button-el:not(:last-of-type), *.rbtn--group .input__group .file__input--label:not(:last-of-type), .input__group *.rbtn--group .file__input--label:not(:last-of-type), *.rbtn--group .react-fine-uploader-gallery-file-input-container:not(:last-of-type) {
  margin-right: 0;
  border-right: none;
}
*.rbtn--group--tab--noStyles [role=tab]:focus:before, *.rbtn--group--tab--noStyles [role=tab]:hover:before, *.rbtn--group--tab--noStyles [role=tab][aria-selected=true]:before {
  border-bottom: none;
}
*.rbtn[disabled], .main__supplierPay *[disabled].stripe-button-el, .input__group *[disabled].file__input--label, *[disabled].react-fine-uploader-gallery-file-input-container {
  color: #828C94;
  background-color: #D8D9DC;
  border-color: #828C94;
}
*.rbtn[disabled].rbtn--ghost, .main__supplierPay *[disabled].rbtn--ghost.stripe-button-el, .input__group *[disabled].rbtn--ghost.file__input--label, *[disabled].rbtn--ghost.react-fine-uploader-gallery-file-input-container {
  background-color: transparent;
}
*.rbtn[disabled]:hover, .main__supplierPay *[disabled].stripe-button-el:hover, .input__group *[disabled].file__input--label:hover, *[disabled].react-fine-uploader-gallery-file-input-container:hover {
  cursor: not-allowed;
}
*.rbtn:last-of-type, .main__supplierPay *.stripe-button-el:last-of-type, .input__group *.file__input--label:last-of-type, *.react-fine-uploader-gallery-file-input-container:last-of-type {
  margin-right: 0;
}
*.rbtn sup, .main__supplierPay .stripe-button-el sup, .input__group .file__input--label sup, .react-fine-uploader-gallery-file-input-container sup, .invitation .react-fine-uploader-gallery-file-input-container sup {
  top: -0.5em;
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  left: 4px;
}
@media only screen and (max-width: 48em) {
  *.rbtn, .main__supplierPay .stripe-button-el, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container {
    line-height: 34px;
  }
  *.rbtn--mobile {
    display: block;
    width: 100%;
  }
}

.rbtn__group,
.react-autosuggest__container {
  display: inline-block;
  position: relative;
}
.rbtn__group ~ .icon--close,
.react-autosuggest__container ~ .icon--close {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  transform: translateY(100%);
}
.rbtn__group > *.rbtn:after, .main__supplierPay .rbtn__group > *.stripe-button-el:after, .input__group .rbtn__group > *.file__input--label:after, .rbtn__group > *.react-fine-uploader-gallery-file-input-container:after, .invitation .rbtn__group > *.react-fine-uploader-gallery-file-input-container:after,
.react-autosuggest__container > *.rbtn:after,
.main__supplierPay .react-autosuggest__container > *.stripe-button-el:after,
.input__group .react-autosuggest__container > *.file__input--label:after,
.react-autosuggest__container > *.react-fine-uploader-gallery-file-input-container:after,
.invitation .react-autosuggest__container > *.react-fine-uploader-gallery-file-input-container:after {
  font-size: 8px;
  padding: 0 0 0 8px;
  display: inline-block;
  transform-origin: 67% 30%;
  -webkit-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: transform 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  content: "";
}
.rbtn__group:hover *.rbtn:after, .rbtn__group:hover .main__supplierPay *.stripe-button-el:after, .main__supplierPay .rbtn__group:hover *.stripe-button-el:after, .rbtn__group:hover .input__group *.file__input--label:after, .input__group .rbtn__group:hover *.file__input--label:after, .rbtn__group:hover *.react-fine-uploader-gallery-file-input-container:after,
.react-autosuggest__container:hover *.rbtn:after,
.react-autosuggest__container:hover .main__supplierPay *.stripe-button-el:after,
.main__supplierPay .react-autosuggest__container:hover *.stripe-button-el:after,
.react-autosuggest__container:hover .input__group *.file__input--label:after,
.input__group .react-autosuggest__container:hover *.file__input--label:after,
.react-autosuggest__container:hover *.react-fine-uploader-gallery-file-input-container:after {
  transform: rotate(180deg);
}
.rbtn__group:hover *.rbtn--disabled:after,
.react-autosuggest__container:hover *.rbtn--disabled:after {
  transform: rotate(0deg);
}
.rbtn__group:hover ul,
.react-autosuggest__container:hover ul {
  display: block;
}
.rbtn__group--onclick:hover ul,
.react-autosuggest__container--onclick:hover ul {
  display: none;
}
.rbtn__group--onclick:hover *.rbtn:after, .rbtn__group--onclick:hover .main__supplierPay *.stripe-button-el:after, .main__supplierPay .rbtn__group--onclick:hover *.stripe-button-el:after, .rbtn__group--onclick:hover .input__group *.file__input--label:after, .input__group .rbtn__group--onclick:hover *.file__input--label:after, .rbtn__group--onclick:hover *.react-fine-uploader-gallery-file-input-container:after,
.react-autosuggest__container--onclick:hover *.rbtn:after,
.react-autosuggest__container--onclick:hover .main__supplierPay *.stripe-button-el:after,
.main__supplierPay .react-autosuggest__container--onclick:hover *.stripe-button-el:after,
.react-autosuggest__container--onclick:hover .input__group *.file__input--label:after,
.input__group .react-autosuggest__container--onclick:hover *.file__input--label:after,
.react-autosuggest__container--onclick:hover *.react-fine-uploader-gallery-file-input-container:after {
  transform: rotate(0);
}
.rbtn__group--onclicked ul,
.react-autosuggest__container--onclicked ul {
  display: block !important;
  z-index: 900000000;
  position: relative;
}
.rbtn__group--onclicked *.rbtn:after, .rbtn__group--onclicked .main__supplierPay *.stripe-button-el:after, .main__supplierPay .rbtn__group--onclicked *.stripe-button-el:after, .rbtn__group--onclicked .input__group *.file__input--label:after, .input__group .rbtn__group--onclicked *.file__input--label:after, .rbtn__group--onclicked *.react-fine-uploader-gallery-file-input-container:after,
.react-autosuggest__container--onclicked *.rbtn:after,
.react-autosuggest__container--onclicked .main__supplierPay *.stripe-button-el:after,
.main__supplierPay .react-autosuggest__container--onclicked *.stripe-button-el:after,
.react-autosuggest__container--onclicked .input__group *.file__input--label:after,
.input__group .react-autosuggest__container--onclicked *.file__input--label:after,
.react-autosuggest__container--onclicked *.react-fine-uploader-gallery-file-input-container:after {
  transform: rotate(180deg) !important;
}
.rbtn__group ul,
.react-autosuggest__container ul {
  display: none;
  background: #ffffff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 4px;
  width: 215px;
  border: 1px solid #DDE0E4;
  position: absolute;
}
.rbtn__group ul li,
.react-autosuggest__container ul li {
  border-bottom: 1px solid #DDE0E4;
}
.rbtn__group ul li button,
.rbtn__group ul li > div,
.react-autosuggest__container ul li button,
.react-autosuggest__container ul li > div {
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 32px;
  padding: 0 8px;
  color: #000000;
  display: block;
  -webkit-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: background-color 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
}
.rbtn__group ul li button:hover,
.rbtn__group ul li > div:hover,
.react-autosuggest__container ul li button:hover,
.react-autosuggest__container ul li > div:hover {
  background-color: #017BC1;
  color: #ffffff;
}
.rbtn__group ul li.react-autosuggest__suggestion--highlighted > div,
.react-autosuggest__container ul li.react-autosuggest__suggestion--highlighted > div {
  background-color: #017BC1;
  color: #ffffff;
}
.rbtn__group ul li:last-child,
.react-autosuggest__container ul li:last-child {
  border-bottom: none;
}
.rbtn__group--dark *.rbtn, .rbtn__group--dark .main__supplierPay .stripe-button-el, .main__supplierPay .rbtn__group--dark .stripe-button-el, .rbtn__group--dark .input__group .file__input--label, .input__group .rbtn__group--dark .file__input--label, .rbtn__group--dark .react-fine-uploader-gallery-file-input-container,
.react-autosuggest__container--dark *.rbtn,
.react-autosuggest__container--dark .main__supplierPay .stripe-button-el,
.main__supplierPay .react-autosuggest__container--dark .stripe-button-el,
.react-autosuggest__container--dark .input__group .file__input--label,
.input__group .react-autosuggest__container--dark .file__input--label,
.react-autosuggest__container--dark .react-fine-uploader-gallery-file-input-container {
  background-color: #51585D;
  border: none;
  color: #ffffff;
}
.rbtn__group--dark *.rbtn:hover, .rbtn__group--dark .main__supplierPay *.stripe-button-el:hover, .main__supplierPay .rbtn__group--dark *.stripe-button-el:hover, .rbtn__group--dark .input__group *.file__input--label:hover, .input__group .rbtn__group--dark *.file__input--label:hover, .rbtn__group--dark *.react-fine-uploader-gallery-file-input-container:hover,
.react-autosuggest__container--dark *.rbtn:hover,
.react-autosuggest__container--dark .main__supplierPay *.stripe-button-el:hover,
.main__supplierPay .react-autosuggest__container--dark *.stripe-button-el:hover,
.react-autosuggest__container--dark .input__group *.file__input--label:hover,
.input__group .react-autosuggest__container--dark *.file__input--label:hover,
.react-autosuggest__container--dark *.react-fine-uploader-gallery-file-input-container:hover {
  background-color: #3B4043;
}
.rbtn__group--dark ul,
.react-autosuggest__container--dark ul {
  background-color: #3B4043;
  border-color: #3B4043;
}
.rbtn__group--dark ul li,
.react-autosuggest__container--dark ul li {
  border: none;
}
.rbtn__group--dark ul li a,
.react-autosuggest__container--dark ul li a {
  color: #ffffff;
}
.rbtn__group--dark ul li a:hover,
.react-autosuggest__container--dark ul li a:hover {
  background-color: #ffe900;
  color: #000000;
}
.rbtn__group--dark:hover .rbtn, .rbtn__group--dark:hover .main__supplierPay .stripe-button-el, .main__supplierPay .rbtn__group--dark:hover .stripe-button-el, .rbtn__group--dark:hover .input__group .file__input--label, .input__group .rbtn__group--dark:hover .file__input--label, .rbtn__group--dark:hover .react-fine-uploader-gallery-file-input-container,
.rbtn__group--dark:hover a.rbtn,
.rbtn__group--dark:hover button.rbtn,
.react-autosuggest__container--dark:hover .rbtn,
.react-autosuggest__container--dark:hover .main__supplierPay .stripe-button-el,
.main__supplierPay .react-autosuggest__container--dark:hover .stripe-button-el,
.react-autosuggest__container--dark:hover .input__group .file__input--label,
.input__group .react-autosuggest__container--dark:hover .file__input--label,
.react-autosuggest__container--dark:hover .react-fine-uploader-gallery-file-input-container,
.react-autosuggest__container--dark:hover a.rbtn,
.react-autosuggest__container--dark:hover button.rbtn {
  background-color: #3B4043;
}
.rbtn__group--right ul,
.react-autosuggest__container--right ul {
  right: 0;
  border: none;
}
.rbtn__group .menugroup ul,
.react-autosuggest__container .menugroup ul {
  display: inline-block;
  float: left;
  border: 0;
  box-shadow: none;
  padding: 8px 0;
  border-radius: 0;
}
.rbtn__group .menugroup .menuitem > ul,
.react-autosuggest__container .menugroup .menuitem > ul {
  float: left;
  padding: 8px 0;
}
.rbtn__group .menugroup .menuitem a,
.react-autosuggest__container .menugroup .menuitem a {
  margin-left: 12px;
}
.rbtn__group .menugroup .menuitems--header,
.react-autosuggest__container .menugroup .menuitems--header {
  width: 215px;
  padding: 0 0 0 8px;
  float: left;
  color: #fff;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  background-color: #51585D;
  line-height: 34px;
}
.rbtn__group .dropdown li .rbtn, .rbtn__group .dropdown li .main__supplierPay .stripe-button-el, .main__supplierPay .rbtn__group .dropdown li .stripe-button-el, .rbtn__group .dropdown li .input__group .file__input--label, .input__group .rbtn__group .dropdown li .file__input--label, .rbtn__group .dropdown li .react-fine-uploader-gallery-file-input-container,
.react-autosuggest__container .dropdown li .rbtn,
.react-autosuggest__container .dropdown li .main__supplierPay .stripe-button-el,
.main__supplierPay .react-autosuggest__container .dropdown li .stripe-button-el,
.react-autosuggest__container .dropdown li .input__group .file__input--label,
.input__group .react-autosuggest__container .dropdown li .file__input--label,
.react-autosuggest__container .dropdown li .react-fine-uploader-gallery-file-input-container {
  font-family: inherit;
}
.rbtn__group .dropdown--left,
.react-autosuggest__container .dropdown--left {
  position: absolute;
  left: 0;
}
.rbtn__group .dropdown--right,
.react-autosuggest__container .dropdown--right {
  position: absolute;
  right: 0;
}
.rbtn__group .menuSubGroup,
.react-autosuggest__container .menuSubGroup {
  position: absolute;
  right: 4px;
  width: 215px;
}

autosuggest, .autosuggest {
  position: relative;
}

.react-autosuggest__container ul {
  display: block;
}

.selectDropdown.selectDropdown--inline {
  display: inline-block;
  margin: initial;
}
.selectDropdown__prefix {
  display: inline-block;
  padding-right: 8px;
  line-height: 38px;
}
.selectDropdown__error {
  position: relative;
  display: none;
}
.selectDropdown.selectDropdown--showErrors .selectDropdown__error {
  display: block;
  position: absolute;
}

.dropdown--container {
  position: relative;
  z-index: 2;
}
.dropdown--container .dropdown-content .rbtn, .dropdown--container .dropdown-content .main__supplierPay .stripe-button-el, .main__supplierPay .dropdown--container .dropdown-content .stripe-button-el, .dropdown--container .dropdown-content .input__group .file__input--label, .input__group .dropdown--container .dropdown-content .file__input--label, .dropdown--container .dropdown-content .react-fine-uploader-gallery-file-input-container {
  text-align: left;
}

.ReactTable input.checkbox {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

.ReactTable .rt-body .rt-body {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.rt-td .link:nth-child(1) {
  width: 100%;
}

.rt-td .link:not(:first-child) {
  flex: 1;
  margin-top: 8px;
}

.link {
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: underline;
  font-size: 16px;
  color: #005E8C;
  line-height: 24px;
  margin: 0 8px 0 0;
  outline: none;
  /** Custom Links **/
}
.link path {
  fill: #005E8C;
}
.link--block {
  display: block;
  width: 100%;
}
.link--secondary {
  color: #3B4043;
}
.link--secondary path {
  fill: #3B4043;
}
.link--right {
  text-align: right;
}
.link:hover, .link:focus {
  color: #004567;
  cursor: pointer;
  text-decoration-style: dotted;
}
.link:hover path, .link:focus path {
  fill: #006D86;
}
.link--primary {
  color: #ffe900;
}
.link--primary path {
  fill: #ffe900;
}
.link--primary:hover, .link--primary:focus {
  color: #ffed33;
}
.link--primary:hover path, .link--primary:focus path {
  fill: #ffed33;
}
.link--secondary {
  color: black;
}
.link--secondary path {
  fill: black;
}
.link--inline {
  font-size: inherit;
}
.link--paragraph {
  font-size: 16px;
  text-transform: initial;
}
.link--sentence {
  text-transform: initial;
  margin-right: 0;
}
.link--lowerSize {
  font-size: 14px;
}
.link--onFocus:focus {
  border: 2px solid #004567;
}
.link--extraSmall {
  font-size: 12px;
}
.link--noDecoration {
  text-decoration: none;
}
.link--disabled {
  cursor: not-allowed;
  color: #3B4043;
}
.link--disabled path {
  fill: #3B4043;
}
.link--disabled:hover {
  cursor: not-allowed;
  color: #3B4043;
}
.link--showPassword {
  width: 28px;
  height: 28px;
  background: none;
  border: none;
  line-height: 24px;
  position: absolute !important;
  top: 5px;
  right: 10px;
  margin-right: 0;
}
.link--showPassword:hover, .link--showPassword:focus {
  cursor: pointer;
  text-decoration-style: dotted;
}

.input__group {
  margin: 0 0 16px;
}
.input__group label {
  font-weight: 600;
  font-size: 12px;
  color: #3f454a;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.input__group label[for=password].required:after {
  content: "*";
  padding-left: 2px;
  color: #64676e;
}
.input__group.required label:after {
  content: "*";
  padding-left: 2px;
  color: #64676e;
}
.input__group textarea {
  padding: 5px 10px;
  background: #F4F6F9;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000000;
  outline: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  width: 100%;
}
.input__group input,
.input__group select {
  background: #F4F6F9;
  border: none;
  height: 34px;
  display: block;
  width: 100%;
  outline: none;
  padding: 0 8px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 34px;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.input__group input.DateInput_input,
.input__group select.DateInput_input {
  background: #F4F6F9;
}
.input__group input.input--bordered,
.input__group select.input--bordered {
  background: #ffffff;
  border: 1px solid #F4F6F9;
}
.input__group input.input--bordered:focus,
.input__group select.input--bordered:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.input__group input.input--dark,
.input__group select.input--dark {
  color: #ffffff;
}
.input__group input.input--dark::-webkit-input-placeholder, .input__group select.input--dark::-webkit-input-placeholder {
  color: #ffffff;
}

.input__group input.input--dark:-moz-placeholder, .input__group select.input--dark:-moz-placeholder {
  color: #ffffff;
}

.input__group input.input--dark::-moz-placeholder, .input__group select.input--dark::-moz-placeholder {
  color: #ffffff;
}

.input__group input.input--dark:-ms-input-placeholder, .input__group select.input--dark:-ms-input-placeholder {
  color: #ffffff;
}

.input__group input.input--transparent,
.input__group select.input--transparent {
  background: transparent;
}
.input__group input.input--hidden,
.input__group select.input--hidden {
  display: none;
}
.input__group input[type=checkbox] {
  display: none;
}
.input__group input[type=checkbox]:before {
  content: "";
}
.input__group input[type=checkbox] + label {
  cursor: pointer;
}
.input__group input[type=checkbox] + label:before {
  content: "";
  padding: 0 4px 0 0;
}
.input__group input[type=checkbox]:checked + label:before {
  content: "";
}
.input__group--inline input,
.input__group--inline label,
.input__group--inline select {
  display: inline-block;
  width: auto;
  margin: 0 4px;
}
.input__group--search {
  background: #ffffff;
  border: 1px solid #C7D2DB;
  height: 54px;
}
.input__group--search:before {
  content: "";
  font-size: 22px;
  color: #51585D;
  padding-left: 12px;
  line-height: 49px;
  z-index: 1;
}
.input__group--search input {
  font-size: 18px;
  background: none;
  border: none;
  height: 52px;
  line-height: 52px;
}
.input__group--search input::-webkit-input-placeholder {
  color: #A3B0BB;
}

.input__group--search input:-moz-placeholder {
  color: #A3B0BB;
}

.input__group--search input::-moz-placeholder {
  color: #A3B0BB;
}

.input__group--search input:-ms-input-placeholder {
  color: #A3B0BB;
}

.input__group--search input::-ms-clear {
  display: none;
}
.input__group--search .rbtn, .input__group--search .main__supplierPay .stripe-button-el, .main__supplierPay .input__group--search .stripe-button-el, .input__group--search .input__group .file__input--label, .input__group .input__group--search .file__input--label, .input__group--search .react-fine-uploader-gallery-file-input-container {
  width: 141px;
  margin: 0;
}
@media (max-width: 1023px) {
  .input__group--search {
    margin: 0 0 150px 0;
  }
  .input__group--search .rbtn--primary, .input__group--search .input__group .file__input--label, .input__group .input__group--search .file__input--label, .input__group--search .react-fine-uploader-gallery-file-input-container, .input__group--search fieldset.fieldset__radio input[type=radio]:checked + label, fieldset.fieldset__radio .input__group--search input[type=radio]:checked + label {
    width: 100%;
  }
  .input__group--search autosuggest,
.input__group--search .autosuggest {
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 1px solid #C7D2DB;
    height: 54px;
    display: block;
    margin: 16px 0 14px 0;
    z-index: 10;
  }
}
.input__group--search .react-autosuggest__container input {
  height: 36px;
  line-height: 36px;
  margin: 8px 0;
  padding-left: 40px;
}
@media (min-width: 1024px) {
  .input__group--search .react-autosuggest__container input {
    border-left: 1px solid #C7D2DB;
  }
}
.input__group--search .react-autosuggest__container ul {
  margin: 8px 0 0 41px;
  width: auto;
}
.input__group--search .react-autosuggest__container + .icon--selectable:focus, .input__group--search .react-autosuggest__container + .icon--selectable:hover {
  background-color: #98a4ae;
  cursor: pointer;
}
.input__group--search .react-autosuggest__container:before {
  content: "";
  display: block;
  font-size: 22px;
  color: #51585D;
  position: absolute;
  padding-left: 12px;
  line-height: 49px;
  z-index: 1;
}
.input__group--search .react-autosuggest__container:hover:before {
  color: #017BC1;
}
.input__group--search:hover:before {
  color: #017BC1;
}
.input__group--search input:invalid + .rbtn--reset {
  display: none;
}
.input__group--search .rbtn--reset {
  background: none;
  border: none;
  text-align: right;
  padding-right: 12px;
  margin: 0;
}
.input__group--search .rbtn--reset:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #C7D2DB;
  color: #ffffff;
  font-size: 10px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  line-height: 15px;
  padding: 0;
  margin: 0;
}
.input__group--search .rbtn--reset:focus:before, .input__group--search .rbtn--reset:hover:before {
  background-color: #98a4ae;
  cursor: pointer;
}
.input__group--append {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input__group--append > input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input__group--append .rbtn, .input__group--append .main__supplierPay .stripe-button-el, .main__supplierPay .input__group--append .stripe-button-el, .input__group--append .input__group .file__input--label, .input__group .input__group--append .file__input--label, .input__group--append .react-fine-uploader-gallery-file-input-container {
  position: relative;
  z-index: 2;
  height: 52px;
}

.checkbox__group {
  margin: 0 0 16px;
}

label.labelchange {
  position: relative;
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  color: #3f454a;
  letter-spacing: 0;
  line-height: 12px;
}
label.labelchange input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0 4px;
}
label.labelchange .label-text {
  margin-left: 20px;
}

label.labelchange .square {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 5px;
}
label.labelchange .square:before {
  content: "";
}

label.labelchange input:checked + .square:before {
  content: "";
}

label.labelchange input:focus + .square:before {
  border: 1px solid #017BC1;
}

.errors .error {
  font-weight: 600;
  font-size: 12px;
  color: #d30547;
  letter-spacing: 0;
  line-height: 12px;
}

.form--centered {
  text-align: center;
}
.form.request_public_addition i {
  display: inline-block;
  margin: 20px auto 30px;
  font-size: 3em;
}
.form--submitted i {
  color: #2F872A;
}
.form--failed i {
  color: #d30547;
}

.success_image {
  margin: 0 auto;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../imgs/success_sun.gif");
}

.error_image {
  margin: 0 auto;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../imgs/error_cloud.gif");
}

.input__group {
  margin: 0 0 16px;
}
.input__group label {
  font-weight: 600;
  font-size: 12px;
  color: #3f454a;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.input__group label[for=password].required:after {
  content: "*";
  padding-left: 2px;
  color: #64676e;
}
.input__group.required label:after {
  content: "*";
  padding-left: 2px;
  color: #64676e;
}
.input__group textarea {
  padding: 5px 10px;
  background: #F4F6F9;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000000;
  outline: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  width: 100%;
}
.input__group input,
.input__group select {
  background: #F4F6F9;
  border: none;
  height: 34px;
  display: block;
  width: 100%;
  outline: none;
  padding: 0 8px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 34px;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.input__group input.DateInput_input,
.input__group select.DateInput_input {
  background: #F4F6F9;
}
.input__group input.input--bordered,
.input__group select.input--bordered {
  background: #ffffff;
  border: 1px solid #F4F6F9;
}
.input__group input.input--bordered:focus,
.input__group select.input--bordered:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.input__group input.input--dark,
.input__group select.input--dark {
  color: #ffffff;
}
.input__group input.input--dark::-webkit-input-placeholder, .input__group select.input--dark::-webkit-input-placeholder {
  color: #ffffff;
}

.input__group input.input--dark:-moz-placeholder, .input__group select.input--dark:-moz-placeholder {
  color: #ffffff;
}

.input__group input.input--dark::-moz-placeholder, .input__group select.input--dark::-moz-placeholder {
  color: #ffffff;
}

.input__group input.input--dark:-ms-input-placeholder, .input__group select.input--dark:-ms-input-placeholder {
  color: #ffffff;
}

.input__group input.input--transparent,
.input__group select.input--transparent {
  background: transparent;
}
.input__group input.input--hidden,
.input__group select.input--hidden {
  display: none;
}
.input__group input[type=checkbox] {
  display: none;
}
.input__group input[type=checkbox]:before {
  content: "";
}
.input__group input[type=checkbox] + label {
  cursor: pointer;
}
.input__group input[type=checkbox] + label:before {
  content: "";
  padding: 0 4px 0 0;
}
.input__group input[type=checkbox]:checked + label:before {
  content: "";
}
.input__group--inline input,
.input__group--inline label,
.input__group--inline select {
  display: inline-block;
  width: auto;
  margin: 0 4px;
}
.input__group--search {
  background: #ffffff;
  border: 1px solid #C7D2DB;
  height: 54px;
}
.input__group--search:before {
  content: "";
  font-size: 22px;
  color: #51585D;
  padding-left: 12px;
  line-height: 49px;
  z-index: 1;
}
.input__group--search input {
  font-size: 18px;
  background: none;
  border: none;
  height: 52px;
  line-height: 52px;
}
.input__group--search input::-webkit-input-placeholder {
  color: #A3B0BB;
}

.input__group--search input:-moz-placeholder {
  color: #A3B0BB;
}

.input__group--search input::-moz-placeholder {
  color: #A3B0BB;
}

.input__group--search input:-ms-input-placeholder {
  color: #A3B0BB;
}

.input__group--search input::-ms-clear {
  display: none;
}
.input__group--search .rbtn, .input__group--search .main__supplierPay .stripe-button-el, .main__supplierPay .input__group--search .stripe-button-el, .input__group--search .input__group .file__input--label, .input__group .input__group--search .file__input--label, .input__group--search .react-fine-uploader-gallery-file-input-container {
  width: 141px;
  margin: 0;
}
@media (max-width: 1023px) {
  .input__group--search {
    margin: 0 0 150px 0;
  }
  .input__group--search .rbtn--primary, .input__group--search .input__group .file__input--label, .input__group .input__group--search .file__input--label, .input__group--search .react-fine-uploader-gallery-file-input-container, .input__group--search fieldset.fieldset__radio input[type=radio]:checked + label, fieldset.fieldset__radio .input__group--search input[type=radio]:checked + label {
    width: 100%;
  }
  .input__group--search autosuggest,
.input__group--search .autosuggest {
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 1px solid #C7D2DB;
    height: 54px;
    display: block;
    margin: 16px 0 14px 0;
    z-index: 10;
  }
}
.input__group--search .react-autosuggest__container input {
  height: 36px;
  line-height: 36px;
  margin: 8px 0;
  padding-left: 40px;
}
@media (min-width: 1024px) {
  .input__group--search .react-autosuggest__container input {
    border-left: 1px solid #C7D2DB;
  }
}
.input__group--search .react-autosuggest__container ul {
  margin: 8px 0 0 41px;
  width: auto;
}
.input__group--search .react-autosuggest__container + .icon--selectable:focus, .input__group--search .react-autosuggest__container + .icon--selectable:hover {
  background-color: #98a4ae;
  cursor: pointer;
}
.input__group--search .react-autosuggest__container:before {
  content: "";
  display: block;
  font-size: 22px;
  color: #51585D;
  position: absolute;
  padding-left: 12px;
  line-height: 49px;
  z-index: 1;
}
.input__group--search .react-autosuggest__container:hover:before {
  color: #017BC1;
}
.input__group--search:hover:before {
  color: #017BC1;
}
.input__group--search input:invalid + .rbtn--reset {
  display: none;
}
.input__group--search .rbtn--reset {
  background: none;
  border: none;
  text-align: right;
  padding-right: 12px;
  margin: 0;
}
.input__group--search .rbtn--reset:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #C7D2DB;
  color: #ffffff;
  font-size: 10px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  line-height: 15px;
  padding: 0;
  margin: 0;
}
.input__group--search .rbtn--reset:focus:before, .input__group--search .rbtn--reset:hover:before {
  background-color: #98a4ae;
  cursor: pointer;
}
.input__group--append {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input__group--append > input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input__group--append .rbtn, .input__group--append .main__supplierPay .stripe-button-el, .main__supplierPay .input__group--append .stripe-button-el, .input__group--append .input__group .file__input--label, .input__group .input__group--append .file__input--label, .input__group--append .react-fine-uploader-gallery-file-input-container {
  position: relative;
  z-index: 2;
  height: 52px;
}

.checkbox__group {
  margin: 0 0 16px;
}

label.labelchange {
  position: relative;
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  color: #3f454a;
  letter-spacing: 0;
  line-height: 12px;
}
label.labelchange input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0 4px;
}
label.labelchange .label-text {
  margin-left: 20px;
}

label.labelchange .square {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 5px;
}
label.labelchange .square:before {
  content: "";
}

label.labelchange input:checked + .square:before {
  content: "";
}

label.labelchange input:focus + .square:before {
  border: 1px solid #017BC1;
}

fieldset.fieldset__radio label, .accordion.accordion--card .accordion__header:after, *.rbtn, .main__supplierPay .stripe-button-el, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container {
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  min-width: 95px;
  cursor: pointer;
  padding: 0 8px;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  outline: none;
  margin: 0 4px 0 0;
  color: #3B4043;
  background-color: #F4F6F9;
  border: 1px solid #3B4043;
}
fieldset.fieldset__radio label:active, .accordion.accordion--card .accordion__header:active:after, .rbtn:active, .main__supplierPay .stripe-button-el:active, .input__group .file__input--label:active, .react-fine-uploader-gallery-file-input-container:active, fieldset.fieldset__radio label:focus, .accordion.accordion--card .accordion__header:focus:after, .rbtn:focus, .main__supplierPay .stripe-button-el:focus, .input__group .file__input--label:focus, .react-fine-uploader-gallery-file-input-container:focus, fieldset.fieldset__radio label:hover, .accordion.accordion--card .accordion__header:hover:after, .rbtn:hover, .main__supplierPay .stripe-button-el:hover, .input__group .file__input--label:hover, .react-fine-uploader-gallery-file-input-container:hover {
  background-color: #d3dbe7;
}

*.rbtn--primary, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container, fieldset.fieldset__radio input[type=radio]:checked + label {
  color: #ffffff;
  background-color: #017BC1;
  border: 1px solid #017BC1;
}
*.rbtn--primary.rbtn--ghost, .input__group *.rbtn--ghost.file__input--label, *.rbtn--ghost.react-fine-uploader-gallery-file-input-container, fieldset.fieldset__radio input[type=radio]:checked + label.rbtn--ghost {
  color: #017BC1;
}
*.rbtn--primary:active, .input__group *.file__input--label:active, *.react-fine-uploader-gallery-file-input-container:active, fieldset.fieldset__radio input[type=radio]:checked + label:active, *.rbtn--primary:focus, .input__group *.file__input--label:focus, *.react-fine-uploader-gallery-file-input-container:focus, fieldset.fieldset__radio input[type=radio]:checked + label:focus, *.rbtn--primary:hover, .input__group *.file__input--label:hover, *.react-fine-uploader-gallery-file-input-container:hover, fieldset.fieldset__radio input[type=radio]:checked + label:hover {
  color: #ffffff;
  background-color: #005E8C;
  text-decoration: none;
}
*.rbtn--secondary, .main__supplierPay .stripe-button-el {
  color: #000000;
  background-color: #ffe900;
  border: 1px solid #ffe900;
}
*.rbtn--secondary.rbtn--ghost, .main__supplierPay *.rbtn--ghost.stripe-button-el {
  color: #ffe900;
}
*.rbtn--secondary:active, .main__supplierPay *.stripe-button-el:active, *.rbtn--secondary:focus, .main__supplierPay *.stripe-button-el:focus, *.rbtn--secondary:hover, .main__supplierPay *.stripe-button-el:hover {
  color: #ffffff;
  background-color: #ccba00;
  border: 1px solid #ccba00;
}
*.rbtn--danger {
  color: #000000;
  background-color: #d30547;
  border: 1px solid #d30547;
}
*.rbtn--danger.rbtn--ghost {
  color: #d30547;
}
*.rbtn--danger:focus, *.rbtn--danger:hover {
  color: #ffffff;
  background-color: #a10436;
  border: 1px solid #a10436;
}
*.rbtn--dark {
  background-color: #51585D;
  border-color: #51585D;
  color: #ffffff;
}
*.rbtn--dark:active, *.rbtn--dark:focus, *.rbtn--dark:hover {
  color: #ffffff;
  background-color: #3B4043;
}
*.rbtn--white {
  background-color: #ffffff;
  border-color: #98a4ae;
  color: #3B4043;
  font-weight: 600;
  border-radius: 2px;
}
*.rbtn--white:active, *.rbtn--white:focus, *.rbtn--white:hover {
  color: #3B4043;
  background-color: transparent;
}
*.rbtn--anchor {
  background-color: transparent;
  border: transparent;
  color: #A3B0BB;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.28px;
  padding: 16px 24px;
  margin: 0;
  position: relative;
}
@media screen and (min-width: 75em) {
  *.rbtn--anchor {
    padding: 16px 32px;
  }
}
*.rbtn--anchor:after {
  display: block;
  content: "";
  border-bottom: solid 4px #017BC1;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
*.rbtn--anchor--active {
  color: #231F20;
}
*.rbtn--anchor--active:after {
  transform: scaleX(1);
}
*.rbtn--anchor:hover:after {
  border-color: #51585D;
  transform: scaleX(1);
}
*.rbtn--anchor:hover:active:after {
  transform: scaleX(1);
  border-color: #017BC1;
  transition: border-color 250ms ease-in-out;
}
*.rbtn--anchor:active, *.rbtn--anchor:focus, *.rbtn--anchor:hover {
  background-color: transparent;
}
*.rbtn--anchor:focus {
  outline: auto;
}
*.rbtn--ghost {
  background-color: transparent;
}
*.rbtn--transparent, fieldset.fieldset__radio label {
  background-color: transparent;
  border-color: transparent;
}
*.rbtn--disabled {
  background-color: #D8D9DC;
  color: #828C94;
}
*.rbtn--block {
  display: block;
  width: 100%;
}
*.rbtn--right {
  float: right;
}
*.rbtn--borderless {
  border: none;
}
*.rbtn--md {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
*.rbtn--lg {
  height: 52px;
  line-height: 52px;
  padding: 0 16px;
}
*.rbtn--textStandard {
  font-size: 14px;
}
*.rbtn--onFocus {
  outline-offset: 2px;
}
@media only screen and (min-width: 75em) {
  *.rbtn--responsive {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
  }
}
*.rbtn--geyser {
  color: #828C94;
  border: 1px solid #C7D2DB;
  background-color: #F4F6F9;
  font-size: 16px;
  font-weight: normal;
  min-width: initial;
  position: relative;
}
*.rbtn--geyser--active {
  color: #017BC1;
  background-color: #ffffff;
}
*.rbtn--geyser--active:focus {
  background-color: #ffffff;
}
*.rbtn--group .rbtn:not(:last-of-type), *.rbtn--group .main__supplierPay .stripe-button-el:not(:last-of-type), .main__supplierPay *.rbtn--group .stripe-button-el:not(:last-of-type), *.rbtn--group .input__group .file__input--label:not(:last-of-type), .input__group *.rbtn--group .file__input--label:not(:last-of-type), *.rbtn--group .react-fine-uploader-gallery-file-input-container:not(:last-of-type) {
  margin-right: 0;
  border-right: none;
}
*.rbtn--group--tab--noStyles [role=tab]:focus:before, *.rbtn--group--tab--noStyles [role=tab]:hover:before, *.rbtn--group--tab--noStyles [role=tab][aria-selected=true]:before {
  border-bottom: none;
}
*.rbtn[disabled], .main__supplierPay *[disabled].stripe-button-el, .input__group *[disabled].file__input--label, *[disabled].react-fine-uploader-gallery-file-input-container {
  color: #828C94;
  background-color: #D8D9DC;
  border-color: #828C94;
}
*.rbtn[disabled].rbtn--ghost, .main__supplierPay *[disabled].rbtn--ghost.stripe-button-el, .input__group *[disabled].rbtn--ghost.file__input--label, *[disabled].rbtn--ghost.react-fine-uploader-gallery-file-input-container {
  background-color: transparent;
}
*.rbtn[disabled]:hover, .main__supplierPay *[disabled].stripe-button-el:hover, .input__group *[disabled].file__input--label:hover, *[disabled].react-fine-uploader-gallery-file-input-container:hover {
  cursor: not-allowed;
}
*.rbtn:last-of-type, .main__supplierPay *.stripe-button-el:last-of-type, .input__group *.file__input--label:last-of-type, *.react-fine-uploader-gallery-file-input-container:last-of-type {
  margin-right: 0;
}
*.rbtn sup, .main__supplierPay .stripe-button-el sup, .input__group .file__input--label sup, .react-fine-uploader-gallery-file-input-container sup, .invitation .react-fine-uploader-gallery-file-input-container sup {
  top: -0.5em;
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  left: 4px;
}
@media only screen and (max-width: 48em) {
  *.rbtn, .main__supplierPay .stripe-button-el, .input__group .file__input--label, .react-fine-uploader-gallery-file-input-container, .invitation .react-fine-uploader-gallery-file-input-container {
    line-height: 34px;
  }
  *.rbtn--mobile {
    display: block;
    width: 100%;
  }
}

.radio-group.radio-group__horizontal label {
  display: inline-block;
}
.radio-group label {
  display: block;
  padding: 8px;
  cursor: pointer;
}
.radio-group label > span {
  position: relative;
  line-height: 24px;
}
.radio-group label > span:before {
  content: "";
  display: inline-block;
  vertical-align: -4px;
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
  border: 1px solid #404042;
}
.radio-group label > input[type=radio] + span:before {
  border-radius: 50%;
}
.radio-group label > span:after {
  content: "";
  display: none;
  vertical-align: -6px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  margin-right: 0.5em;
  opacity: 0;
  -webkit-transition: opacity 0.3;
  -moz-transition: opacity 0.3;
  -ms-transition: opacity 0.3;
  -o-transition: opacity 0.3;
  transition: opacity 0.3;
}
.radio-group label > input:checked + span:after {
  display: inline-block;
  background-image: radial-gradient(circle closest-side, #005E8C 0%, #005E8C 40%, transparent 50%, transparent 100%);
  opacity: 1;
  transition: opacity 0.03s ease;
  left: 0;
  top: 0;
  position: absolute;
  vertical-align: middle;
}
.radio-group label > input[type=radio]:checked + span:after {
  background-image: radial-gradient(circle closest-side, #005E8C 0%, #005E8C 40%, transparent 50%, transparent 100%);
}
.radio-group label > input[type=radio]:disabled + span {
  opacity: 0.5;
}
.radio-group label > input[type=radio]:disabled:checked + span:after {
  background-image: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50%, transparent 50%, transparent 100%);
}
.radio-group label > input[type=radio] {
  display: none;
}

fieldset.fieldset__radio {
  min-width: 0;
  padding-top: 16px;
  margin-top: 48px;
  margin-bottom: 48px;
  position: relative;
}
fieldset.fieldset__radio .fieldset__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: wrap;
  overflow-x: auto;
}
fieldset.fieldset__radio input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
fieldset.fieldset__radio label {
  border: 1px solid #98a4ae;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  text-transform: none;
  flex: 1 0 auto;
}
fieldset.fieldset__radio--standardSizing label {
  flex: 0 0 auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 4px;
  width: 215px;
  border: 1px solid #dde0e4;
}
.dropdown-content.show, .dropdown-content.active {
  display: block;
}
.dropdown-content li {
  list-style: none;
  border-bottom: 1px solid #dde0e4;
}
.dropdown-content li:last-child {
  border-bottom: none;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-content--right {
  right: 0;
}

.rcheckbox.required label:after {
  content: "*";
  color: #d30547;
}
.rcheckbox label {
  display: block;
  padding: 0.5em;
  cursor: pointer;
}
.rcheckbox label > span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.rcheckbox label > span:before {
  content: "";
  display: inline-block;
  vertical-align: -5px;
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
  border: 1px solid #404042;
}
.rcheckbox label > input[type=checkbox] + span:before {
  border-radius: 2px;
}
.rcheckbox label > span:after {
  content: "";
  display: inline-block;
  vertical-align: -6px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  margin-right: 0.5em;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transition: opacity 0.3;
  -moz-transition: opacity 0.3;
  -ms-transition: opacity 0.3;
  -o-transition: opacity 0.3;
  transition: opacity 0.3;
}
.rcheckbox label > input[type=checkbox]:checked + span:after {
  background-image: none;
  font-family: RapidRatings;
  content: "";
  color: #017BC1;
  text-align: center;
  opacity: 1;
  left: 0;
  position: absolute;
  -webkit-transition: opacity 0.3;
  -moz-transition: opacity 0.3;
  -ms-transition: opacity 0.3;
  -o-transition: opacity 0.3;
  transition: opacity 0.3;
}
.rcheckbox label > input[type=checkbox] {
  display: none;
}
.rcheckbox .link {
  margin-right: 0;
  text-transform: capitalize;
  font-size: 14px;
}

.invitation {
  max-width: 1034px;
  margin: 24px auto;
}
.invitation fhrregistration > .row {
  padding-top: 3px;
}
.invitation .trafficLights {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}
.invitation textarea {
  resize: none;
  border: 1px solid #A3B0BB;
  outline: 1px solid #979797;
  outline-offset: 0px;
}
.invitation__header {
  height: 66px;
}
.invitation__header--client {
  border: 1px solid #e7e7e7;
  border-bottom: 4px solid;
  border-bottom-color: #ffe900;
}
.invitation__header img {
  width: 100%;
  padding: 5px 10px;
}
.invitation__header h1, .invitation__header h2, .invitation__header .ReactTable .rt-noData--textLower, .ReactTable .invitation__header .rt-noData--textLower, .invitation__header .ReactTable .rt-noData--text, .ReactTable .invitation__header .rt-noData--text, .invitation__header h3, .invitation__header .visually__h1, .invitation__header .visually__h2, .invitation__header .visually__h3 {
  margin: 0;
  font-size: 26px;
  line-height: 66px;
  display: inline-block;
}
.invitation__wizard {
  border: 1px solid #E7E7E7;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.invitation__wizard .wizard__content .row {
  margin: 0;
  padding: 0 0 0 8px;
}
.invitation__wizard .wizard__content .row [class^=col] {
  padding: 0 8px 0 0;
}
.invitation__wizard .wizard__content .rcheckbox {
  padding: 0.5em 0;
}
.invitation__wizard .wizard__navigation {
  text-align: right;
}
.invitation__wizard .revealer--show {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.invitation__wizard .revealer--content {
  flex-grow: 1;
  min-height: 420px;
}
.invitation__wizard .revealer--nav {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
}
.invitation .fhr_faqs {
  margin-left: 32px;
}
.invitation .link {
  text-transform: initial;
  text-decoration: underline;
}
.invitation ul .link {
  font-size: 14px;
}
.invitation__links {
  float: right;
  line-height: 45px;
}
.invitation__links li {
  display: inline-block;
}
.invitation__links a {
  color: #4B4B4B;
  text-decoration: none;
}
.invitation .react-fine-uploader-gallery-dropzone,
.invitation .react-fine-uploader-gallery-nodrop-container {
  max-height: initial;
  min-height: 68px;
}
.invitation .react-fine-uploader-gallery-dropzone {
  border: 2px dashed #3B4043;
  padding: 8px;
}
.invitation .react-fine-uploader-gallery-dropzone-upload-icon {
  display: none;
}
.invitation .react-fine-uploader-gallery-dropzone-content {
  font-size: 14px;
  opacity: 1;
}
.invitation .react-fine-uploader-gallery-file-input-container {
  min-width: 120px;
}
.invitation .react-fine-uploader-gallery-file-input-container:hover {
  cursor: pointer;
}

.multi-step {
  display: flex;
  padding: 4px;
  height: 500px;
}

.step {
  padding: 26px 24px;
  flex: 1;
  min-height: 400px;
}
.step h2, .step .ReactTable .rt-noData--textLower, .ReactTable .step .rt-noData--textLower, .step .ReactTable .rt-noData--text, .ReactTable .step .rt-noData--text, .step .visually__h2 {
  width: 100%;
}
.step form {
  width: 100%;
  margin: 16px auto;
}
.step .radio-group label {
  padding: 0;
  vertical-align: middle;
  line-height: 24px;
}
.step .link:hover {
  cursor: pointer;
}
.step .financial {
  margin-left: 27px;
}
.step2 .rcheckbox label {
  line-height: 38px;
}
.step4 .rcheckbox label {
  padding: 0.5em 0;
}

.step4__additionalInfo textarea {
  min-height: 106px;
}
.step4__additionalInfo .row.step4__additionalInfo--faqs {
  display: block;
}
.step4__additionalInfo .row.step4__additionalInfo--meta {
  display: none;
}

.step4__additionalInfo--showMeta .step4__additionalInfo--faqs {
  display: none;
}
.step4__additionalInfo--showMeta .row.step4__additionalInfo--meta {
  display: flex;
}

.sidebarLinks {
  position: relative;
  background-color: #F4F6F9;
  max-width: 278px;
  border: 3px solid #fff;
  border-top: 0;
  width: 100%;
  min-height: 424px;
  height: 100%;
}
.sidebarLinks h3, .sidebarLinks .visually__h3 {
  padding-left: 40px;
  line-height: 32px;
  color: #3F454A;
}
.sidebarLinks .sidebarLinks--general {
  padding-left: 40px;
}
.sidebarLinks .sidebarLinks--general .link {
  text-transform: initial;
  font-size: 14px;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  counter-reset: li;
}
ol.progtrckr div {
  display: inline-block;
}
ol.progtrckr li {
  font-size: 12px;
  text-align: left;
  line-height: 55px;
  pointer-events: none;
  position: relative;
}
ol.progtrckr li[revealer=stepForward] {
  display: none;
}
ol.progtrckr li[revealer=stepForward].revealer--active {
  display: block;
}
ol.progtrckr li[revealer=stepForward].revealer--active ~ li {
  display: none;
}
ol.progtrckr li[revealer=stepForward].revealer--active:after {
  height: 2rem;
}
ol.progtrckr li:before {
  position: relative;
  bottom: -18px;
  float: left;
  left: 10px;
  text-align: center;
  z-index: 2;
  content: counter(li);
  /* Use the counter as content */
  counter-increment: li;
  /* Increment the counter by 1 */
  color: #98a4ae;
  border: 2px solid #98a4ae;
  background: #F4F6F9;
  width: 18px;
  height: 18px;
  font-family: Arial;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  border-radius: 9px;
}
ol.progtrckr li.active:before {
  color: white;
  background-color: #005E8C;
  border: 2px solid #005E8C;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li span {
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 1.5rem;
  pointer-events: none;
}
ol.progtrckr li.revealer--active {
  background-color: #F4F6F9;
}
ol.progtrckr-done:before {
  font-family: "RapidRatings";
  content: "";
  color: #98a4ae;
  background-color: #f4f6f9;
  width: 18px;
  line-height: 1.2em;
  border-radius: 1.2em;
  z-index: 2;
}

ol.progtrckr > li:after {
  content: "";
  width: 0;
  position: absolute;
  border: 1px solid #9B9B9B;
  top: 0;
  left: 18px;
  right: 0;
  height: 4.6rem;
  z-index: 1;
}
ol.progtrckr > li:first-child:after {
  height: 1.5rem;
  bottom: 0;
  top: initial;
}
ol.progtrckr > li:last-child:after {
  height: 2rem;
  top: 0;
  bottom: initial;
}

.input__group .DateInput {
  width: 100%;
}

.SingleDatePickerInput {
  width: 100%;
}
.SingleDatePickerInput__withBorder {
  border-radius: 0;
  border: 1px solid #A3B0BB;
}

.SingleDatePickerInput_calendarIcon {
  margin: 0;
  position: absolute;
  z-index: 1;
  padding: 5px 10px;
}
.SingleDatePickerInput_calendarIcon + .DateInput .DateInput_input {
  padding-left: 32px;
}

.DateRangePicker, .SingleDatePicker {
  width: 100%;
}
.DateRangePicker td, .SingleDatePicker td {
  vertical-align: middle;
}

.CalendarDay__highlighted_calendar {
  background: #005E8C;
  color: #ffffff;
}

.CalendarDay__highlighted_calendar:hover {
  background: #009ABD;
  color: #ffffff;
}

.CalendarDay__highlighted_calendar:active {
  background: #006D86;
  color: #ffffff;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: #005E8C;
  border: none;
  color: #fff;
}
.CalendarDay__selected_span,
.CalendarDay__selected:hover_span,
.CalendarDay__selected:active_span {
  background: #017BC1;
  border: none;
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover,
.CalendarDay__selected:hover_span:active,
.CalendarDay__selected:hover_span:hover,
.CalendarDay__selected:active_span:active,
.CalendarDay__selected:active_span:hover {
  background: #017BC1;
  border: none;
  color: #fff;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:hover,
.CalendarDay__selected_span:active {
  background: #62c9e0;
  border: 1px solid #59b8ce;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active {
  background: #57b8ce;
  border: 1px solid #57b8ce;
  border: none;
  color: #fff;
}

.DateInput_input__focused {
  border-bottom: 2px solid #005E8C;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid #005E8C;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #009ABD;
}

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
  max-height: initial;
  min-height: 68px;
}

.react-fine-uploader-gallery-dropzone {
  border: 2px dashed #3B4043;
  padding: 8px;
}

.react-fine-uploader-gallery-dropzone-upload-icon {
  display: none;
}

.react-fine-uploader-gallery-dropzone-content {
  font-size: 14px;
  opacity: 1;
}

.react-fine-uploader-gallery-file-input-container {
  min-width: 120px;
}
.react-fine-uploader-gallery-file-input-container:hover {
  cursor: pointer;
}

.react-fine-uploader-gallery-dropzone .react-fine-uploader-gallery-file-input-container {
  padding: 0;
}

.rapidRatings__v3 .invitation__wizard select {
  font-size: 14px;
  border-radius: 0;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #F0F0F1;
  background-position-x: 95%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDEyIDciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+U2hhcGUgQ29weSAyPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iU3RlcC00LS0tZHJhZ2dlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTcxNC4wMDAwMDAsIC00MDcuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+ICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlNoYXBlLUNvcHktMiIgcG9pbnRzPSI3MTUuNDEgNDA3IDcyMCA0MTEuMzI2NTg2IDcyNC41OSA0MDcgNzI2IDQwOC4zMzE5ODQgNzIwIDQxNCA3MTQgNDA4LjMzMTk4NCI+PC9wb2x5Z29uPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
}
.rapidRatings__v3 .invitation__wizard select:hover {
  cursor: pointer;
}
.rapidRatings__v3 .invitation__wizard select::-webkit-input-placeholder {
  color: #000000;
}

.rapidRatings__v3 .invitation__wizard select:-moz-placeholder {
  color: #000000;
}

.rapidRatings__v3 .invitation__wizard select::-moz-placeholder {
  color: #000000;
}

.rapidRatings__v3 .invitation__wizard select:-ms-input-placeholder {
  color: #000000;
}

.rapidRatings__v3 .invitation__wizard select::-ms-expand {
  /* remove default arrow on IE 10 and IE 11 */
  display: none;
}

.navigation__1 .accountmenu, .navigation__1 .accountmenu--internal {
  position: relative;
  display: block;
  float: right;
  background-color: #51585D;
  text-align: right;
  height: 24px;
  z-index: 4;
  transform: translateX(0px);
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -ms-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}
.navigation__1 .accountmenu .rbtn, .navigation__1 .accountmenu .main__supplierPay .stripe-button-el, .main__supplierPay .navigation__1 .accountmenu .stripe-button-el, .navigation__1 .accountmenu .input__group .file__input--label, .input__group .navigation__1 .accountmenu .file__input--label, .navigation__1 .accountmenu--internal .rbtn, .navigation__1 .accountmenu--internal .main__supplierPay .stripe-button-el, .main__supplierPay .navigation__1 .accountmenu--internal .stripe-button-el, .navigation__1 .accountmenu--internal .input__group .file__input--label, .input__group .navigation__1 .accountmenu--internal .file__input--label, .navigation__1 .accountmenu .react-fine-uploader-gallery-file-input-container, .navigation__1 .accountmenu--internal .react-fine-uploader-gallery-file-input-container {
  line-height: 22px;
  font-weight: 600;
}
.navigation__1 .accountmenu li, .navigation__1 .accountmenu--internal li {
  padding: 4px;
}
.navigation__1 .accountmenu a, .navigation__1 .accountmenu--internal a {
  text-decoration-line: none;
}
.navigation__1 .accountmenu--internal .rbtn__group--right ul {
  padding: 0;
}
.navigation__1 .accountmenu--internal li {
  padding: 0;
}
@media only screen and (min-width: 1280px) {
  .navigation__1 .accountmenu, .navigation__1 .accountmenu--internal {
    transform: translateX(-220px);
  }
  .navigation__1 .accountmenu--error {
    transform: translateX(0px);
  }
}
@media only screen and (max-width: 48em) {
  .navigation__1 .accountmenu .rbtn, .navigation__1 .accountmenu .main__supplierPay .stripe-button-el, .main__supplierPay .navigation__1 .accountmenu .stripe-button-el, .navigation__1 .accountmenu .input__group .file__input--label, .input__group .navigation__1 .accountmenu .file__input--label, .navigation__1 .accountmenu--internal .rbtn, .navigation__1 .accountmenu--internal .main__supplierPay .stripe-button-el, .main__supplierPay .navigation__1 .accountmenu--internal .stripe-button-el, .navigation__1 .accountmenu--internal .input__group .file__input--label, .input__group .navigation__1 .accountmenu--internal .file__input--label, .navigation__1 .accountmenu .react-fine-uploader-gallery-file-input-container, .navigation__1 .accountmenu--internal .react-fine-uploader-gallery-file-input-container,
.navigation__1 .accountmenu a.rbtn,
.navigation__1 .accountmenu button.rbtn {
    line-height: 24px;
  }
}

.navigation__1 .mainmenu {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 12px;
}
.navigation__1 .mainmenu .mainmenu__bar {
  background-color: #231F20;
  text-align: right;
  padding: 0 8px;
  line-height: 58px;
  height: 60px;
  transform: translateX(0px);
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -ms-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
  z-index: 4;
  position: fixed;
  left: 0;
  right: 0;
  top: 24px;
}
.navigation__1 .mainmenu .mainmenu__bar a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 10px;
  padding: 0 8px;
  cursor: pointer;
}
.navigation__1 .mainmenu .mainmenu__bar a span {
  display: inline-block;
  padding: 0 0 0 4px;
  font-size: 12px;
  font-weight: 600;
}
.navigation__1 .mainmenu .mainmenu__bar .logo {
  position: relative;
  float: left;
  left: -16px;
  z-index: 1;
}
.navigation__1 .mainmenu .mainmenu__bar .logo img {
  height: 36px;
  margin: auto;
  vertical-align: middle;
}
.navigation__1 .mainmenu .mainmenu__bar--error ul {
  display: none;
}
@media only screen and (min-width: 1280px) {
  .navigation__1 .mainmenu .mobile__menu a {
    display: none;
  }
}
.navigation__1 .mainmenu .menuitems {
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -ms-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
  background-color: #3B4043;
  color: #ffffff;
  width: 220px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  overflow-y: auto;
  z-index: 4;
}
@media only screen and (min-width: 1280px) {
  .navigation__1 .mainmenu .menuitems {
    transform: translateX(0%);
  }
  .navigation__1 .mainmenu .menuitems--error {
    transform: translateX(100%);
  }
}
.navigation__1 .mainmenu .menuitems li, .navigation__1 .mainmenu .menuitems ul {
  line-height: 32px;
  display: block;
}
.navigation__1 .mainmenu .menuitems__close {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: right;
  padding: 0 8px 0 0;
  line-height: 43px;
  display: block;
}
@media only screen and (min-width: 1280px) {
  .navigation__1 .mainmenu .menuitems__close {
    display: none;
  }
}
.navigation__1 .mainmenu .menuitems__close i {
  margin: 0 0 0 4px;
}
.navigation__1 .mainmenu .menuitems--header {
  font-size: 14px;
  font-weight: 700;
  background-color: #51585D;
  display: block;
  padding: 0 0 0 8px;
  line-height: 34px;
}
.navigation__1 .mainmenu .menuitems--header.sprites {
  padding: 0 0 0 32px;
}
.navigation__1 .mainmenu .menuitems ul a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  padding: 0 0 0 8px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  background-image: linear-gradient(to right, #3B4043 0, #ffe900 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -220px 0;
  -webkit-transition: background-position 400ms;
  -moz-transition: background-position 400ms;
  -ms-transition: background-position 400ms;
  -o-transition: background-position 400ms;
  transition: background-position 400ms;
  line-height: inherit;
  display: inherit;
}
.navigation__1 .mainmenu .menuitems ul a.a--active, .navigation__1 .mainmenu .menuitems ul a:hover {
  color: #000000;
  background-position: 0 0;
}
@media only screen and (max-width: 420px) {
  .navigation__1 .mainmenu .menuitems--header {
    cursor: pointer;
  }
  .navigation__1 .mainmenu .menuitems--header.sprites::before {
    background: none;
    content: "";
    font: normal normal normal 12px/1 "RapidRatings";
    font-size: 10px;
    margin: 12px 0 0 8px;
    transform-origin: 8px 5px;
    -webkit-transition: transform 400ms;
    -moz-transition: transform 400ms;
    -ms-transition: transform 400ms;
    -o-transition: transform 400ms;
    transition: transform 400ms;
  }
  .navigation__1 .mainmenu .menuitems .menugroup {
    max-height: 34px;
    overflow: hidden;
    margin: 0 0 1px;
    -webkit-transition: max-height 400ms;
    -moz-transition: max-height 400ms;
    -ms-transition: max-height 400ms;
    -o-transition: max-height 400ms;
    transition: max-height 400ms;
  }
  .navigation__1 .mainmenu .menuitems .menugroup--active {
    max-height: 400px;
  }
  .navigation__1 .mainmenu .menuitems .menugroup--active .menuitems--header.sprites::before {
    transform: rotate(180deg);
  }
}
.navigation__1 .mainmenu .menuitems .menuitem {
  margin: 0 0 0 24px;
  padding: 4px 0;
  border-bottom: 1px solid #51585D;
}
.navigation__1 .mainmenu .menuitems .menuitem:last-child {
  border-bottom: none;
}
.navigation__1 .mainmenu .menubg {
  background-color: #000000;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  -ms-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
}
.navigation__1 .mainmenu--active .menuitems {
  transform: translateX(0%);
}
@media only screen and (max-width: 1280px) {
  .navigation__1 .mainmenu--active .menubg {
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    filter: alpha(opacity=60);
    position: absolute;
    z-index: 4;
    content: " ";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

navigation {
  background-color: #51585D;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  z-index: 1;
}

.navigation--account {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #50585d;
  z-index: 6;
}

.main {
  margin-top: 84px;
}
.main.healthMark--profile, .main.healthMark--hasTaskbar {
  margin-top: 360px;
}
@media screen and (min-width: 1024px) {
  .main.healthMark--profile, .main.healthMark--hasTaskbar {
    margin-top: 155px;
  }
}
.main.healthMark--profile h2, .main.healthMark--profile .ReactTable .rt-noData--textLower, .ReactTable .main.healthMark--profile .rt-noData--textLower, .main.healthMark--profile .ReactTable .rt-noData--text, .ReactTable .main.healthMark--profile .rt-noData--text, .main.healthMark--profile .visually__h2 {
  font-size: 26px !important;
  font-weight: 400 !important;
}
.main.healthMark--profile h3, .main.healthMark--profile .visually__h3 {
  font-size: 16px !important;
}
.main.healthMark--profile hr {
  height: 1px !important;
}
.main.healthMark--profile .primaryRiskHeading {
  font-weight: 600 !important;
  font-size: 18px !important;
}
.main.healthMark--profile .print__only {
  display: none !important;
}
@media print {
  .main.healthMark--profile .print__only {
    display: block !important;
  }
}

body {
  margin: 0;
  padding: 0;
}
body.body--accounts {
  background: #ffffff;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
body.body--accounts .login__page-container {
  display: flex;
}
body.body--accounts .login__image-container {
  width: 0;
  position: relative;
  background: linear-gradient(#0A080B, #31383D);
}
@media screen and (min-width: 1024px) {
  body.body--accounts .login__image-container {
    width: 50%;
  }
}
body.body--accounts .login__image-container .login__image-container-text {
  position: absolute;
  bottom: 32px;
  text-align: center;
  width: 100%;
}
body.body--accounts .login__image-container .login__image-container-text p {
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  color: white;
}
body.body--accounts .login__image-container .login__image-container-text p span {
  margin-right: 16px;
}
body.body--accounts .login__image-container .login__image {
  height: 100%;
  background-size: 100%;
  display: none;
}
@media screen and (min-width: 1024px) {
  body.body--accounts .login__image-container .login__image {
    display: block;
    background: url(../imgs/login_fhrx.png);
    background-size: 497px auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.skip-footer {
  left: 0;
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

.skip-footer:focus {
  background-color: #c7d2db;
  bottom: 0;
  right: auto;
  height: 84px;
  line-height: 84px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  z-index: 999;
  width: 100vw;
  display: block;
  position: fixed;
  left: 0;
  color: #026d86;
  text-decoration: underline;
}

snapshot {
  display: block;
  position: relative;
  width: 100%;
  background-color: #F4F6F9;
  padding-bottom: 5px;
}

.fhr {
  display: flex;
  flex-direction: row;
  flex: initial;
}
.fhr .fhr__score {
  height: 135px;
  width: 108px;
}
.fhr .fhr__score .fhr__details {
  height: 100%;
}
.fhr .fhr__score .fhr__details .fhr__score {
  display: table;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
}
.fhr .fhr__score .fhr__details .fhr__score div {
  display: table-row;
}
.fhr .fhr__score .fhr__details .fhr__score div .badge__footer {
  width: 108px;
  max-width: 108px;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__score__details {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 47px;
  padding: 22px 0 0;
  padding-left: 32px;
  display: block;
  width: 90px;
  display: table-cell;
  width: 90px;
  vertical-align: top;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__score__details sup {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  margin-left: 10px;
  line-height: inherit;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__score__details sup .icon {
  font-size: 8px;
  display: block;
  margin: -8px 0 0 0;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__score__details:after {
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 19.32px;
  letter-spacing: 0;
  line-height: 19.32px;
  content: "FHR®";
  margin-bottom: -10px;
  margin-top: 5px;
  margin-left: -1px;
}
.fhr .fhr__score .fhr__details .fhr__score div .svg__trend {
  display: table-cell;
  vertical-align: bottom;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__dates {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: inherit;
  text-align: center;
  padding: 8px 0 8px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 40px;
  display: table-cell;
  width: 90px;
  vertical-align: bottom;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__dates span {
  display: block;
  line-height: inherit;
}
.fhr .fhr__score .fhr__details .fhr__score div .fhr__dates .fiscal_date {
  display: block;
  line-height: inherit;
  font-weight: normal;
}
.fhr .fhr__score--medium {
  background-color: #F7E856;
}
.fhr .fhr__score--medium h2, .fhr .fhr__score--medium .ReactTable .rt-noData--textLower, .ReactTable .fhr .fhr__score--medium .rt-noData--textLower, .fhr .fhr__score--medium .ReactTable .rt-noData--text, .ReactTable .fhr .fhr__score--medium .rt-noData--text, .fhr .fhr__score--medium .visually__h2 {
  color: #1B1F29;
}
.fhr .fhr__score--high {
  background-color: #F5B353;
}
.fhr .fhr__score--high h2, .fhr .fhr__score--high .ReactTable .rt-noData--textLower, .ReactTable .fhr .fhr__score--high .rt-noData--textLower, .fhr .fhr__score--high .ReactTable .rt-noData--text, .ReactTable .fhr .fhr__score--high .rt-noData--text, .fhr .fhr__score--high .visually__h2 {
  color: #1B1F29;
}
.fhr .fhr__score--veryhigh {
  background-color: #DC1B13;
}
.fhr .fhr__score--veryhigh h2, .fhr .fhr__score--veryhigh .ReactTable .rt-noData--textLower, .ReactTable .fhr .fhr__score--veryhigh .rt-noData--textLower, .fhr .fhr__score--veryhigh .ReactTable .rt-noData--text, .ReactTable .fhr .fhr__score--veryhigh .rt-noData--text, .fhr .fhr__score--veryhigh .visually__h2 {
  color: #ffffff;
}
.fhr .fhr__score--low {
  background-color: #2F872A;
}
.fhr .fhr__score--low h2, .fhr .fhr__score--low .ReactTable .rt-noData--textLower, .ReactTable .fhr .fhr__score--low .rt-noData--textLower, .fhr .fhr__score--low .ReactTable .rt-noData--text, .ReactTable .fhr .fhr__score--low .rt-noData--text, .fhr .fhr__score--low .visually__h2 {
  color: #ffffff;
}
.fhr .fhr__score--verylow {
  background-color: #6FBFEA;
}
.fhr .fhr__score--verylow h2, .fhr .fhr__score--verylow .ReactTable .rt-noData--textLower, .ReactTable .fhr .fhr__score--verylow .rt-noData--textLower, .fhr .fhr__score--verylow .ReactTable .rt-noData--text, .ReactTable .fhr .fhr__score--verylow .rt-noData--text, .fhr .fhr__score--verylow .visually__h2 {
  color: #1B1F29;
}
.fhr .fhr__levels {
  background-color: transparent;
  max-width: 5px;
  height: 135px;
}
.fhr .fhr__levels ul {
  display: table;
  border-collapse: separate;
  height: 100%;
}
.fhr .fhr__levels ul .fhr__level {
  width: 4px;
  display: table-row;
  font-size: 4px;
  font-family: Arial;
}
.fhr .fhr__levels ul .fhr__level:after {
  content: "®";
}
.fhr .fhr__levels ul .fhr__level--verylow {
  background-color: #6FBFEA;
  color: #6FBFEA;
}
.fhr .fhr__levels ul .fhr__level--low {
  background-color: #2F872A;
  color: #2F872A;
}
.fhr .fhr__levels ul .fhr__level--medium {
  background-color: #F7E856;
  color: #F7E856;
}
.fhr .fhr__levels ul .fhr__level--high {
  background-color: #F5B353;
  color: #F5B353;
}
.fhr .fhr__levels ul .fhr__level--veryhigh {
  background-color: #DC1B13;
  color: #DC1B13;
}
.fhr .company__summary {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin: 0 0 8px;
}
.fhr .company__summary strong {
  margin: 0 2px 0 0;
  font-weight: 800;
}
.fhr .company__summary--uppercase {
  text-transform: uppercase;
}
.fhr .company__summary--uppercase strong {
  text-transform: none;
}
.fhr .company__summary--uppercase span:last-child {
  margin: 0 0 0 4px;
}
.fhr .company__summary--rrid {
  font-weight: 700;
}

.company__details {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;
  width: calc(100% - 105px);
  padding: 16px 16px 16px 24px;
  position: relative;
  flex: fit-content;
}
.company__details h1, .company__details h2, .company__details .ReactTable .rt-noData--textLower, .ReactTable .company__details .rt-noData--textLower, .company__details .ReactTable .rt-noData--text, .ReactTable .company__details .rt-noData--text, .company__details h3, .company__details .visually__h1, .company__details .visually__h2, .company__details .visually__h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 26px;
  letter-spacing: 0;
  margin: 8px 0 0;
}

.risk__copy {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  width: fit-content;
  display: inline-block;
}

.componentGrid--item {
  border: 1px solid #DEE0E5;
  border-radius: 2px;
  padding: 12px;
}
.componentGrid--row {
  margin: 0.5em auto;
}
.componentGrid h2, .componentGrid .ReactTable .rt-noData--textLower, .ReactTable .componentGrid .rt-noData--textLower, .componentGrid .ReactTable .rt-noData--text, .ReactTable .componentGrid .rt-noData--text, .componentGrid .visually__h2 {
  font-size: 18px;
  line-height: initial;
  margin-bottom: 12px;
}
.componentGrid .link {
  line-height: initial;
  font-weight: 700;
  text-transform: unset;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .componentGrid--item {
    position: relative;
  }
  .componentGrid .link {
    position: absolute;
    bottom: 6px;
    left: 12px;
  }
}

.primaryRiskHeading {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
}

.risk-analysis-tab p {
  margin-top: 0;
  margin-bottom: 6px;
}
.risk-analysis-tab__heading h2, .risk-analysis-tab__heading .ReactTable .rt-noData--textLower, .ReactTable .risk-analysis-tab__heading .rt-noData--textLower, .risk-analysis-tab__heading .ReactTable .rt-noData--text, .ReactTable .risk-analysis-tab__heading .rt-noData--text, .risk-analysis-tab__heading .visually__h2 {
  margin-bottom: 0;
}
.risk-analysis-tab__about {
  margin: 16px auto;
  background-color: #f6f6f6;
  padding: 12px;
  border-radius: 2px;
}
.risk-analysis-tab__about h3, .risk-analysis-tab__about .visually__h3 {
  font-size: 18px;
  line-height: 36px;
  color: #3B4043;
  font-weight: 700;
}
.risk-analysis-tab .dates {
  position: relative;
}
.risk-analysis-tab .dates:before {
  font-family: RapidRatings;
  font-size: 41px;
  content: "";
  display: inline-block;
  position: absolute;
  top: -4px;
  left: 0;
  color: #98a4ae;
}
.risk-analysis-tab .dates .icon--offset {
  margin-left: 40px;
}

.risk-analysis--epd {
  line-height: 18px;
}

.risk-analysis--private-company {
  color: #64676E;
}

.risk-analysis--trend__heading {
  margin-bottom: 0 !important;
}
.risk-analysis--trend__subheading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  padding: 0;
  margin-bottom: 12px;
}

.exec-summary-tab h1, .exec-summary-tab h2, .exec-summary-tab .ReactTable .rt-noData--textLower, .ReactTable .exec-summary-tab .rt-noData--textLower, .exec-summary-tab .ReactTable .rt-noData--text, .ReactTable .exec-summary-tab .rt-noData--text, .exec-summary-tab h3, .exec-summary-tab .visually__h1, .exec-summary-tab .visually__h2, .exec-summary-tab .visually__h3 {
  margin-bottom: 0;
}
.exec-summary-tab .risk__copy {
  margin-bottom: 46px;
}

.news__header {
  -webkit-transition: box-shadow 400ms;
  -moz-transition: box-shadow 400ms;
  -ms-transition: box-shadow 400ms;
  -o-transition: box-shadow 400ms;
  transition: box-shadow 400ms;
}
.news__header.nav__border {
  -webkit-box-shadow: 0px 2px 2px -2pxrgba 0, 0, 0, 0.5;
  -moz-box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.5);
  -webkit-transition: box-shadow 400ms;
  -moz-transition: box-shadow 400ms;
  -ms-transition: box-shadow 400ms;
  -o-transition: box-shadow 400ms;
  transition: box-shadow 400ms;
}

.news__logo--expanded {
  height: 50px;
  margin-top: -10px;
  margin-left: -15px;
}

.news__category__list {
  display: flex;
  flex-direction: row;
  margin: 25px 0 0;
  padding-bottom: 16px;
}
.news__category__list li {
  width: 105px;
}

.news__category__title {
  color: #51585d;
  margin: 0 auto;
  text-align: center;
}
.news__category__title--active {
  font-weight: 600;
}

.news__category {
  background-repeat: no-repeat;
  height: 60px;
  width: 100px;
  position: relative;
  font-family: RapidRatings;
  cursor: pointer;
  color: #00AFD5;
  text-align: center;
}
.news__category--active {
  color: #006C87;
}
.news__category--empty {
  color: #98a4ae;
  cursor: not-allowed;
}
.news__category--news:before {
  font-size: 48px;
  content: "";
}
.news__category--disasters:before {
  font-size: 48px;
  content: "";
}
.news__category--mergers:before {
  font-size: 48px;
  content: "";
}
.news__category--bankruptcy:before {
  font-size: 48px;
  content: "";
}
.news__category:after {
  content: attr(data-count);
  position: absolute;
  font-size: 0.7em;
  right: 10px;
  background: #5c6268;
  color: white;
  width: 28px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 10rem;
  font-family: Arial;
  font-weight: 600;
}

.quadrant-plane {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  display: block;
}

.currencyPicker {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  max-width: 500px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.currencyPicker input,
.currencyPicker select {
  width: 100%;
}
.currencyPicker__base {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  max-width: 20%;
}
.currencyPicker__unit {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  max-width: 30%;
}
.currencyPicker__code {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.landingHeader {
  margin-top: 24px;
  display: inline-block;
}
.landingHeader ~ span {
  display: inline-block;
}
@media (min-width: 1200px) {
  .landingHeader ~ span {
    padding-left: 8px;
  }
}
.landingFooter:after {
  content: "";
  display: block;
  height: 350px;
  background-color: #e4e6eb;
  width: 100%;
  margin-top: 15px;
  z-index: 1;
}
.landingSearchSpacing {
  margin-bottom: 100px;
}
.landingCopyright {
  text-align: center;
  background-color: #e4e6eb;
  display: inline-block;
  width: 100%;
}
.landingStatus {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.landingPublic {
  margin: 1.5em 0 4em 0;
}

.iconLanding {
  padding-top: 4px;
  line-height: inherit;
}

.positionRelative {
  position: relative;
}

.bookImage {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  display: block;
  margin: 0 auto;
  display: none;
}
@media screen and (min-width: 64em) {
  .bookImage {
    display: block;
  }
}

.helpDocLinks .content__block h2, .helpDocLinks .content__block .ReactTable .rt-noData--textLower, .ReactTable .helpDocLinks .content__block .rt-noData--textLower, .helpDocLinks .content__block .ReactTable .rt-noData--text, .ReactTable .helpDocLinks .content__block .rt-noData--text, .helpDocLinks .content__block .visually__h2, .helpDocLinks .content__block p {
  padding: 0 0 0 12px;
  margin: 12px 0 0 0;
}
.helpDocLinks .content__block .link {
  color: #005E8C;
  text-transform: capitalize;
  padding-left: 0;
}

.link--helpdocs:after {
  display: inline-block;
  font-family: "RapidRatings";
  content: "";
  font-size: 10px;
  margin-left: 5px;
  vertical-align: bottom;
}

.quad {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}
.quad strong {
  font-weight: bold;
}
.quad .quad__indicator {
  width: 60px;
  padding: 0 16px 0 0;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;
  -ms-flex-preferred-size: 76px;
  flex-basis: 76px;
  max-width: 76px;
}
.quad .quad__indicator .quad__letter {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 40px;
  color: #3B4043;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  font-weight: 100;
  height: 40px;
}
.quad .quad__indicator .quad__grids {
  width: 75px;
  height: 40px;
  padding: 0;
}
.quad .quad__indicator .quad__grids .quad__grid {
  fill: #98a4ae;
}
.quad .quad__indicator .quad__cta {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  color: #3B4043;
  letter-spacing: 0;
}
.quad .quad__details {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;
  -ms-flex-preferred-size: calc(100% - 76px);
  flex-basis: calc(100% - 76px);
  max-width: calc(100% - 76px);
  font-family: "Source Sans Pro", sans-serif;
  color: #3B4043;
  letter-spacing: 0;
  padding: 0 0 0 16px;
}
.quad .quad__details .quad__header {
  font-size: 16px;
  margin: 0 0 8px;
  line-height: 24px;
  text-transform: capitalize;
}
.quad--a .quad__indicator .quad__grids .quad__grid--a {
  fill: #2F872A;
}
.quad--a .quad__summary strong {
  color: #2F872A;
}
.quad--a .quad__header strong:last-child {
  color: #006647;
}
.quad--b .quad__indicator .quad__grids .quad__grid--b {
  fill: #F7E856;
}
.quad--b .quad__summary strong {
  color: #7b7006;
}
.quad--b .quad__header strong:last-child {
  color: #7b7006;
}
.quad--d .quad__indicator .quad__grids .quad__grid--c {
  fill: #F5B353;
}
.quad--d .quad__summary strong {
  color: #754807;
}
.quad--d .quad__header strong:last-child {
  color: #754807;
}
.quad--c .quad__indicator .quad__grids .quad__grid--d {
  fill: #DC1B13;
}
.quad--c .quad__summary strong {
  color: #200403;
}
.quad--c .quad__header strong:last-child {
  color: #200403;
}

.findialog__lists {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  margin: 0;
}
.findialog__lists .findialog__list {
  padding: 0 24px 0 0;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.findialog__lists .findialog__list h1, .findialog__lists .findialog__list .visually__h1, .findialog__lists .findialog__list .visually__h2, .findialog__lists .findialog__list .visually__h3,
.findialog__lists .findialog__list h2,
.findialog__lists .findialog__list .ReactTable .rt-noData--textLower,
.ReactTable .findialog__lists .findialog__list .rt-noData--textLower,
.findialog__lists .findialog__list .ReactTable .rt-noData--text,
.ReactTable .findialog__lists .findialog__list .rt-noData--text,
.findialog__lists .findialog__list h3,
.findialog__lists .findialog__list h4 {
  font-weight: 600;
  font-size: 12px;
  color: #3B4043;
  letter-spacing: 0;
  line-height: 12px;
  margin: 0 0 8px;
}
.findialog__lists .findialog__list--strengths h1:before, .findialog__lists .findialog__list--strengths .visually__h1:before, .findialog__lists .findialog__list--strengths .visually__h2:before, .findialog__lists .findialog__list--strengths .visually__h3:before,
.findialog__lists .findialog__list--strengths h2:before,
.findialog__lists .findialog__list--strengths .ReactTable .rt-noData--textLower:before,
.ReactTable .findialog__lists .findialog__list--strengths .rt-noData--textLower:before,
.findialog__lists .findialog__list--strengths .ReactTable .rt-noData--text:before,
.ReactTable .findialog__lists .findialog__list--strengths .rt-noData--text:before,
.findialog__lists .findialog__list--strengths h3:before,
.findialog__lists .findialog__list--strengths h4:before {
  content: "";
  color: #2F872A;
  margin: 0 4px 0 0;
}
.findialog__lists .findialog__list--concerns h1:before, .findialog__lists .findialog__list--concerns .visually__h1:before, .findialog__lists .findialog__list--concerns .visually__h2:before, .findialog__lists .findialog__list--concerns .visually__h3:before,
.findialog__lists .findialog__list--concerns h2:before,
.findialog__lists .findialog__list--concerns .ReactTable .rt-noData--textLower:before,
.ReactTable .findialog__lists .findialog__list--concerns .rt-noData--textLower:before,
.findialog__lists .findialog__list--concerns .ReactTable .rt-noData--text:before,
.ReactTable .findialog__lists .findialog__list--concerns .rt-noData--text:before,
.findialog__lists .findialog__list--concerns h3:before,
.findialog__lists .findialog__list--concerns h4:before {
  content: "";
  color: #d30547;
  margin: 0 4px 0 0;
}

.sticky {
  position: fixed;
  top: 85px;
  width: 100%;
}

.summaries {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  background-color: #F4F6F9;
}

.fullinfo {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.neutral-70 {
  color: #64676E;
  font-size: 14px;
  display: inline;
  line-height: 16px;
}

.trendChart {
  width: 100%;
  display: block;
  padding: 0 0 8px 0;
}

.trends .trend__figures .table--trend {
  background-color: transparent;
}
.trends .trend__figures .table--trend tr {
  border: none;
}
.trends .trend__figures .table--trend:hover {
  cursor: pointer;
}
.trends .trend__figures .table--trend .td--score-name {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #3B4043;
  letter-spacing: 0;
  line-height: 22px;
}
.trends .trend__figures .table--trend .td--score-name span {
  font-size: 12px;
  display: block;
  font-weight: normal;
  line-height: 12px;
}
.trends .trend__figures .table--trend .th--score-legand {
  width: 50px;
  cursor: pointer;
}
.trends .trend__figures .table--trend .th--score-legand i {
  display: inline-block;
  margin: 0 10px 0 0;
}
.trends .trend__figures .table--trend .th--score-legand hr {
  display: inline-block;
  width: 20px;
  line-height: 240px;
}
.trends .trend__figures .table--trend .td--score {
  font-size: 30px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0;
  line-height: 20px;
  width: 60px;
}
.trends .trend__figures .table--trend .td--score sup {
  font-size: 15px;
  text-align: center;
  width: 15px;
  line-height: 20px;
  position: static;
  display: inline-block;
}
.trends .trend__figures .table--trend .td--score sup .icon {
  font-size: 5px;
  display: block;
}
.trends .trend__figures #fhr-trend-checkbox,
.trends .trend__figures #sim-fhr-checkbox,
.trends .trend__figures #chs-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  transition-duration: 0.05s;
  width: 13px;
  height: 14px;
  position: absolute;
  cursor: pointer;
}
.trends .trend {
  stroke: none;
}
.trends .trend--veryhigh, .trends .trend--veryhighbar {
  fill: #DC1B13;
}
.trends .trend--high, .trends .trend--highbar {
  fill: #F5B353;
}
.trends .trend--medium, .trends .trend--mediumbar {
  fill: #F7E856;
}
.trends .trend--low, .trends .trend--lowbar {
  fill: #2F872A;
}
.trends .trend--verylow, .trends .trend--verylowbar {
  fill: #6FBFEA;
}
.trends .trend__bar {
  stroke-width: 2px;
}
.trends .trend__bar--chs {
  stroke-dasharray: 12px;
}
.trends .trend__bar--simulated {
  stroke-dasharray: 2px;
}
.trends .trend__bar--sector {
  stroke-dasharray: 5px;
  stroke-width: 5px;
}
.trends .period {
  stroke: none;
  fill: #000000;
  opacity: 0.2;
  cursor: pointer;
  -webkit-transition: opacity 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -moz-transition: opacity 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -ms-transition: opacity 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  -o-transition: opacity 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
  transition: opacity 300ms cubic-bezier(0.54, 0.09, 0.3, 0.82);
}
.trends .period--active {
  opacity: 0;
}
.trends hr.hr--fhr {
  border: none;
  border-top: 2px solid #3B4043;
}
.trends hr.hr--chs {
  border: none;
  border-top: 2px dashed #3B4043;
}
.trends hr.hr--sim {
  border: none;
  border-top: 2px dotted #3B4043;
}
.trends .period__divider {
  fill: rgba(0, 0, 0, 0.4);
  stroke: none;
}
.trends .period__text {
  text-anchor: end !important;
  font-size: 12px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}
.trends .period__date {
  text-anchor: end !important;
  font-size: 12px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  letter-spacing: 0;
  line-height: 12px;
}
@media screen and (min-width: 1680px) {
  .trends .period__date {
    font-size: 16px !important;
  }
}

.content__list--news {
  margin: 0;
  padding: 0;
  list-style: none;
}
.content__list--news li {
  margin: 0;
}
.content__list--news li .news__topic {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0;
  display: inline-block;
  margin: 16px 0 0;
}
.content__list--news li .news__date {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #51585D;
  line-height: 18px;
  margin: 0;
}
.content__list--news li .news__headline--clickable a {
  font-size: 13px;
  text-transform: initial;
}
.content__list--news li .news__snippet {
  margin: 0 0 16px;
  max-width: 600px;
}
.content__list--news li .news__headline:not(:first-child) {
  margin: 0;
}
.content__list--news li:first-child > .news__topic {
  margin: 0;
}
.content__list--news-expanded {
  margin: 29px auto;
  padding: 0;
  list-style: none;
  max-height: calc(100vh - 464px);
  overflow-y: scroll;
}
.content__list--news-expanded li {
  margin: 0;
  max-width: 600px;
}
.content__list--news-expanded li .news__topic {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0;
  display: inline-block;
  margin: 16px 0 0;
}
.content__list--news-expanded li .news__date {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #51585D;
  line-height: 18px;
  margin: 0;
}
.content__list--news-expanded li .news__headline--clickable a {
  font-size: 16px;
  text-transform: initial;
  cursor: pointer;
}
.content__list--news-expanded li .news__snippet {
  margin: 0 0 16px;
  max-width: 600px;
  font-size: 13px;
}
.content__list--news-expanded li .news__readmore {
  margin: -16px 0 16px;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
.content__list--news-expanded li .news__readmore--clickable a {
  font-size: 13px;
  text-transform: initial;
  cursor: pointer;
}
.content__list--news-expanded li .news__headline:not(:first-child) {
  margin: 0;
}
.content__list--news-expanded li:first-child > .news__topic {
  margin: 0;
}

.news__othersempty {
  background: #3B4043;
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 14px;
  padding: 4px;
  margin: 0 0 24px;
}

.news__logo {
  float: right;
  height: 50px;
  margin-top: -10px;
}

.news__empty {
  padding-top: 3%;
}

.news__subheader {
  margin: 0;
}

.comparison {
  display: inline-block;
  position: relative;
  max-width: 550px;
  width: 100%;
  padding-bottom: 30%;
  vertical-align: top;
  overflow: hidden;
}
.comparison--2 {
  display: block;
  position: relative;
  max-width: initial;
  width: 100%;
  padding-bottom: 0;
  vertical-align: initial;
  overflow: initial;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .comparison {
    padding-bottom: 45%;
    margin-bottom: -15%;
  }
  .comparison--2 {
    padding-bottom: 15%;
  }
}
.comparison--chart {
  display: inline-block;
  position: absolute;
  left: 0;
}
.comparison--chart--2 {
  display: block;
  position: relative;
  left: initial;
  height: 80px;
}
.comparison--chart .legend {
  font-size: 13px;
}
.comparison--chart .domain {
  display: none;
}
.comparison--chart g.tick {
  font-weight: 600;
}
.comparison--chart g.tick line {
  stroke: #CCD0D4;
}
.comparison--chart g.tick:nth-of-type(odd) line {
  display: none;
}
.comparison--chart g.tick:first-of-type line, .comparison--chart g.tick:last-of-type line {
  display: none;
}
.comparison--chart text {
  fill: #3B4043;
  margin-right: 10px;
  text-transform: capitalize;
}
.comparison--chart text.name, .comparison--chart text.value {
  font-size: 13px;
}
.comparison--chart text.value {
  text-anchor: end;
}
.comparison--chart.comparison--chart--2 {
  width: 100%;
}
.comparison--chart.comparison--chart--2 text.name, .comparison--chart.comparison--chart--2 text.value {
  font-size: 22px;
}
.comparison--chart.comparison--chart--2 .foreignObject {
  width: 85%;
  height: 50px;
}
.comparison--chart.comparison--chart--2 g.tick text {
  font-size: 14px;
}
.comparison--chart.comparison--chart--2 g.tick:nth-of-type(odd) line {
  display: block;
}
.comparison--chart.comparison--chart--2 g.tick:first-of-type line, .comparison--chart.comparison--chart--2 g.tick:last-of-type line {
  display: none;
}
.comparison .score {
  font-size: 20px;
  padding-left: 60px;
  text-indent: 0;
  position: relative;
}
.comparison .legend svg {
  margin-left: 36px;
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 4px;
  bottom: 0;
}
.comparison .legend--item:last-of-type rect {
  fill: #d8d8d8 !important;
}
.comparison .s0 {
  fill: #DC1B13;
}
.comparison .s1 {
  fill: #F5B353;
}
.comparison .s2 {
  fill: #F7E856;
}
.comparison .s3 {
  fill: #2F872A;
}
.comparison .s4 {
  fill: #6FBFEA;
}

.financial__statement {
  margin: 0 0 24px;
}
.financial__statement h2, .financial__statement .ReactTable .rt-noData--textLower, .ReactTable .financial__statement .rt-noData--textLower, .financial__statement .ReactTable .rt-noData--text, .ReactTable .financial__statement .rt-noData--text, .financial__statement .visually__h2 {
  margin: 0;
  line-height: 32px !important;
}
.financial__statement .table--financials tbody tr.tr--header td,
.financial__statement .table--financials tbody tr.tr--header th {
  background-color: #51585D;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}
.financial__statement .table--financials tbody tr.tr--total td,
.financial__statement .table--financials tbody tr.tr--total th {
  background-color: #009ABD;
  color: #ffffff;
  font-weight: 700;
}
.financial__statement .table--financials tbody tr td:not(:last-child),
.financial__statement .table--financials tbody tr th:not(:last-child) {
  border-right: 1px solid #d3dbe7;
}

.quadrant {
  padding: 16px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.quadrant .quadrant__plane {
  width: 300px;
  height: 300px;
  padding: 0;
  margin: 0 auto;
  transform: scale(0.7);
}
@media screen and (min-width: 1680px) {
  .quadrant .quadrant__plane {
    transform: scale(1);
  }
}
.quadrant .quadrant__plane .plane {
  width: 300px;
  height: 300px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -moz-transform: rotateX(60deg) rotateZ(-45deg);
  -o-transform: rotateX(60deg) rotateZ(-45deg);
  -ms-transform: rotateX(60deg) rotateZ(-45deg);
  -webkit-transform: rotateX(60deg) rotateZ(-45deg);
  transform: rotateX(60deg) rotateZ(-45deg);
}
.quadrant .quadrant__plane .plane .dot {
  width: 3px;
  height: 3px;
  position: absolute;
  background: black;
  z-index: 9;
}
.quadrant .quadrant__plane .plane .dot .dot__marker {
  -moz-transform: rotateZ(45deg) translateY(-180px) translateX(-101px);
  -o-transform: rotateZ(45deg) translateY(-180px) translateX(-101px);
  -ms-transform: rotateZ(45deg) translateY(-180px) translateX(-101px);
  -webkit-transform: rotateZ(45deg) translateY(-180px) translateX(-101px);
  transform: rotateZ(45deg) translateY(-180px) translateX(-101px);
}
.quadrant .quadrant__plane .plane .dot .dot__marker text {
  -moz-transform: scaleY(1.8) translateY(-76px);
  -o-transform: scaleY(1.8) translateY(-76px);
  -ms-transform: scaleY(1.8) translateY(-76px);
  -webkit-transform: scaleY(1.8) translateY(-76px);
  transform: scaleY(1.8) translateY(-76px);
}
.quadrant .quadrant__plane .plane .section {
  position: absolute;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  color: #70767A;
  text-align: right;
  background-color: #D8D8D8;
  border: 1px solid white;
}
.quadrant .quadrant__plane .plane .section span {
  display: block;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.quadrant .quadrant__plane .plane .section--a {
  margin: 120px 0 0;
  width: 180px;
  height: 180px;
  z-index: 4;
}
.quadrant .quadrant__plane .plane .section--b {
  margin: 120px 0 0 180px;
  width: 120px;
  height: 180px;
  z-index: 3;
}
.quadrant .quadrant__plane .plane .section--c {
  margin: 0 0 0 180px;
  width: 120px;
  height: 120px;
  z-index: 1;
}
.quadrant .quadrant__plane .plane .section--d {
  margin: 0;
  width: 180px;
  height: 120px;
  z-index: 2;
}
.quadrant .quadrant__plane .plane .range {
  position: absolute;
  z-index: 20;
}
.quadrant .quadrant__plane .plane .range--left {
  -moz-transform: rotateY(90deg) translateX(12px) translateY(-1px) translateZ(-8px);
  -o-transform: rotateY(90deg) translateX(12px) translateY(-1px) translateZ(-8px);
  -ms-transform: rotateY(90deg) translateX(12px) translateY(-1px) translateZ(-8px);
  -webkit-transform: rotateY(90deg) translateX(12px) translateY(-1px) translateZ(-8px);
  transform: rotateY(90deg) translateX(12px) translateY(-1px) translateZ(-8px);
}
.quadrant .quadrant__plane .plane .range--right {
  -moz-transform: rotateY(-90deg) rotateX(90deg) translateX(-7px) translateY(145px) translateZ(-154px);
  -o-transform: rotateY(-90deg) rotateX(90deg) translateX(-7px) translateY(145px) translateZ(-154px);
  -ms-transform: rotateY(-90deg) rotateX(90deg) translateX(-7px) translateY(145px) translateZ(-154px);
  -webkit-transform: rotateY(-90deg) rotateX(90deg) translateX(-7px) translateY(145px) translateZ(-154px);
  transform: rotateY(-90deg) rotateX(90deg) translateX(-7px) translateY(145px) translateZ(-154px);
  right: 0;
}
.quadrant .quadrant__plane .plane .range .range__section {
  width: 20px;
  height: 60px;
  border: 1px solid white;
}
.quadrant .quadrant__plane .plane .range .range__section--medium {
  background-color: #F7E856;
}
.quadrant .quadrant__plane .plane .range .range__section--high {
  background-color: #F5B353;
}
.quadrant .quadrant__plane .plane .range .range__section--veryhigh {
  background-color: #DC1B13;
}
.quadrant .quadrant__plane .plane .range .range__section--low {
  background-color: #2F872A;
}
.quadrant .quadrant__plane .plane .range .range__section--verylow {
  background-color: #6FBFEA;
}
.quadrant .quadrant__plane .plane--a .section--a {
  color: transparent;
  background-color: rgba(47, 135, 42, 0.5);
}
.quadrant .quadrant__plane .plane--b .section--b {
  color: transparent;
  background-color: #F7E856;
}
.quadrant .quadrant__plane .plane--c .section--c {
  color: transparent;
  background-color: #DC1B13;
}
.quadrant .quadrant__plane .plane--d .section--d {
  color: transparent;
  background-color: #F5B353;
}
.quadrant .quadrant__numbers {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  right: 400px;
  top: 260px;
}
.quadrant .quadrant__numbers .fhr__0 {
  position: absolute;
  top: -80px;
  left: 35px;
}
.quadrant .quadrant__numbers .fhr__level {
  position: absolute;
  top: -20px;
  left: 80px;
  font-size: 24px;
  font-weight: normal;
}
.quadrant .quadrant__numbers .fhr__level:after {
  content: "FHR";
  font-size: 12px;
  line-height: 10px;
  display: block;
  font-weight: bold;
}
.quadrant .quadrant__numbers .both__100 {
  position: absolute;
  top: 20px;
  left: 240px;
}
.quadrant .quadrant__numbers .chs__level {
  position: absolute;
  top: -20px;
  left: 380px;
  font-size: 24px;
  font-weight: normal;
}
.quadrant .quadrant__numbers .chs__level:after {
  content: "CHS";
  font-size: 12px;
  line-height: 10px;
  display: block;
  font-weight: bold;
}
.quadrant .quadrant__numbers .chs__0 {
  position: absolute;
  top: -80px;
  left: 458px;
}

.componentGrid--item .quadrant__title {
  margin: 0;
}

.quadrant__date {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1565px) {
  .search--highDefinition .search {
    position: absolute;
    top: 30px;
    z-index: 5;
    left: -20%;
    right: 0;
    width: 100%;
    max-width: 570px;
    background-color: transparent;
    display: inline;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1565px) {
  .search--highDefinition .search .search__input {
    max-width: 637px;
  }
}
@media screen and (min-width: 1565px) {
  .search--highDefinition .search .search__results {
    max-width: 637px;
  }
}

.search {
  background-color: #2F3336;
  height: 50px;
  margin: 0;
  padding: 8px;
}
.search .search__input {
  max-width: 837px;
  margin: 0 auto;
  position: relative;
  z-index: 4;
}
.search .search__input .input__group {
  display: block;
  position: relative;
  margin-bottom: 0;
}
.search .search__input .input__group input {
  font-size: 18px;
  border-radius: 2px;
  padding-left: 36px;
  background-color: #ffffff;
}
.search .search__input:before {
  content: "";
  display: block;
  font-size: 18px;
  color: #51585D;
  position: absolute;
  margin: 8px 0 0 12px;
  z-index: 1;
}
.search .search__input .rbtn__group {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.search .search__input .rbtn__group .rbtn, .search .search__input .rbtn__group .main__supplierPay .stripe-button-el, .main__supplierPay .search .search__input .rbtn__group .stripe-button-el, .search .search__input .rbtn__group .input__group .file__input--label, .input__group .search .search__input .rbtn__group .file__input--label, .search .search__input .rbtn__group .react-fine-uploader-gallery-file-input-container {
  color: #3B4043;
  height: 34px;
  margin: 0;
  border: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 12px;
}
.search .search__input .rbtn__group .rbtn--dark {
  background-color: transparent;
  border-color: transparent;
  color: #000000;
}
.search .search__input .rbtn__group .rbtn--dark:active, .search .search__input .rbtn__group .rbtn--dark:focus, .search .search__input .rbtn__group .rbtn--dark:hover {
  color: #000000;
  background-color: transparent;
}
.search .search__input .rbtn__group ul {
  position: absolute;
  right: 0;
}
.search .search__results {
  position: relative;
  z-index: 300000;
  background-color: #ffffff;
  margin: 2px auto auto;
  max-width: 837px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 3;
}
.search .search__results ul {
  max-height: 540px;
  overflow: auto;
}
.search .search__results ul li {
  background-color: #F4F6F9;
  width: 100%;
  padding: 8px;
  cursor: pointer;
}
.search .search__results ul li:not(:last-of-type) {
  margin: 0 0 2px;
}
.search .search__results ul li:last-of-type {
  background-color: #E5E8ED;
}
.search .search__results ul li:last-of-type:hover {
  background-color: #d6dbe3;
}
.search .search__results ul li:last-of-type span {
  padding-left: 24px;
  display: block;
}
.search .search__results ul li:last-of-type .search__details {
  color: #005E8C;
  font-size: 14px;
}
.search .search__results ul li a {
  text-decoration: none;
}
.search .search__results ul li button, .search .search__results ul li a {
  background-color: transparent;
  border: none;
  text-align: left;
  line-height: inherit;
  width: 100%;
  padding: 8px 16px;
}
.search .search__results ul li button:hover, .search .search__results ul li a:hover {
  cursor: pointer;
}
.search .search__results ul li button:before, .search .search__results ul li a:before {
  content: "";
  display: block;
  font-size: 18px;
  color: #51585D;
  position: absolute;
  margin: 12px 0 0 0;
  z-index: 1;
}
.search .search__results ul li .search__company {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #231F20;
  letter-spacing: 0;
}
.search .search__results ul li .search__company strong {
  font-weight: bold;
}
.search .search__results ul li .search__details {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
}
.search .search__results ul li .search__details strong {
  font-weight: bold;
}
.search .search__results ul li .search__highlighted {
  font-weight: bold;
  background-color: #D8D8D8;
}
.search .search__results ul li.li--active, .search .search__results ul li:hover {
  background-color: #fae400;
}
.search .search__results ul li.li--active .search__highlighted, .search .search__results ul li:hover .search__highlighted {
  color: #ffffff;
  background-color: #000000;
}
.search .search__results .search__status {
  background-color: #E5E8ED;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  color: #231F20;
  letter-spacing: 0;
  padding: 8px;
}
.search .search__results .search__status strong {
  font-weight: bold;
}
.search .search__results .search__status .search__total {
  display: inline-block;
  width: 50%;
}
.search .search__results .search__status .search__request {
  display: inline-block;
  text-align: right;
  width: 50%;
}
.search .search__results__v2 {
  position: relative;
  z-index: 300000;
  background-color: #ffffff;
  margin: 2px auto auto;
  max-width: 837px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 3;
}
.search .search__results__v2 ul {
  max-height: 276px;
  overflow: auto;
}
.search .search__results__v2 ul li {
  background-color: #F4F6F9;
  width: 100%;
  padding: 8px;
  cursor: pointer;
}
.search .search__results__v2 ul li:not(:last-of-type) {
  margin: 0 0 2px;
}
.search .search__results__v2 ul li a {
  text-decoration: none;
}
.search .search__results__v2 ul li button {
  background-color: transparent;
  border: none;
  text-align: left;
  line-height: inherit;
  width: 100%;
  padding: 8px 16px;
}
.search .search__results__v2 ul li button:hover {
  cursor: pointer;
}
.search .search__results__v2 ul li button:before {
  content: "";
  display: block;
  font-size: 18px;
  color: #51585D;
  position: absolute;
  margin: 12px 0 0 0;
  z-index: 1;
}
.search .search__results__v2 ul li .search__company {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #231F20;
  letter-spacing: 0;
}
.search .search__results__v2 ul li .search__company strong {
  font-weight: bold;
}
.search .search__results__v2 ul li .search__details {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
}
.search .search__results__v2 ul li .search__details strong {
  font-weight: bold;
}
.search .search__results__v2 ul li .search__highlighted {
  font-weight: bold;
  background-color: #D8D8D8;
}
.search .search__results__v2 ul li.li--active, .search .search__results__v2 ul li:hover {
  background-color: #fae400;
}
.search .search__results__v2 ul li.li--active .search__highlighted, .search .search__results__v2 ul li:hover .search__highlighted {
  color: #ffffff;
  background-color: #000000;
}
.search .search__results__v2 .search__request {
  padding: 8px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  background-color: #E5E8ED;
}
.search .search__results__v2 .search__request:hover {
  background-color: #d6dbe3;
}
.search .search__results__v2 .search__request span {
  padding-left: 24px;
  display: block;
}
.search .search__results__v2 .search__request .search__details {
  color: #005E8C;
  font-size: 14px;
}
.search .search__results__v2 .search__request button {
  background-color: transparent;
  border: none;
  text-align: left;
  line-height: inherit;
  width: 100%;
  padding: 8px 16px;
}
.search .search__results__v2 .search__request button:hover {
  cursor: pointer;
}
.search .search__results__v2 .search__request button:before {
  content: "";
  display: block;
  font-size: 18px;
  color: #51585D;
  position: absolute;
  margin: 8px 0 0 0;
  z-index: 1;
}
.search .search__results__v2 .search__status {
  background-color: #E5E8ED;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  color: #231F20;
  letter-spacing: 0;
  padding: 8px;
}
.search .search__results__v2 .search__status strong {
  font-weight: bold;
}
.search .search__results__v2 .search__status .search__total {
  display: inline-block;
  width: 50%;
}
.search .search__results__v2 .search__status .search__request {
  display: inline-block;
  text-align: right;
  width: 50%;
}

.rmodal--search {
  width: 600px;
}
.rmodal--search .logo {
  width: 200px;
}
.rmodal--search p {
  text-align: center;
}
.rmodal--search .search {
  height: 65px;
  background-color: #F4F6F9;
  padding: 16px;
}
.rmodal--search .search .search__input:before {
  color: #000000;
}
.rmodal--search .search .search__input input {
  color: #000000;
}
.rmodal--search .search .search__input input::-webkit-input-placeholder {
  color: #98a4ae;
}

.rmodal--search .search .search__input input:-moz-placeholder {
  color: #98a4ae;
}

.rmodal--search .search .search__input input::-moz-placeholder {
  color: #98a4ae;
}

.rmodal--search .search .search__input input:-ms-input-placeholder {
  color: #98a4ae;
}

.rmodal--search .search .search__input .rbtn__group .rbtn, .rmodal--search .search .search__input .rbtn__group .main__supplierPay .stripe-button-el, .main__supplierPay .rmodal--search .search .search__input .rbtn__group .stripe-button-el, .rmodal--search .search .search__input .rbtn__group .input__group .file__input--label, .input__group .rmodal--search .search .search__input .rbtn__group .file__input--label, .rmodal--search .search .search__input .rbtn__group .react-fine-uploader-gallery-file-input-container {
  color: #000000;
  background-color: #ffffff;
}
.rmodal--search .search .search__input .rbtn__group .rbtn--dark {
  background-color: transparent;
  border-color: transparent;
  color: #000000;
}
.rmodal--search .search .search__input .rbtn__group .rbtn--dark:active, .rmodal--search .search .search__input .rbtn__group .rbtn--dark:focus, .rmodal--search .search .search__input .rbtn__group .rbtn--dark:hover {
  color: #000000;
  background-color: transparent;
}

@media only screen and (max-width: 48em) {
  .search .rbtn, .search .main__supplierPay .stripe-button-el, .main__supplierPay .search .stripe-button-el, .search .input__group .file__input--label, .input__group .search .file__input--label, .search .react-fine-uploader-gallery-file-input-container {
    line-height: 24px;
  }
}
.home-search {
  margin-top: 2em;
}
.home-search--healthMark {
  margin-top: 16px;
}
.home-search .search {
  background-color: #ffffff;
  padding: 0;
}
.home-search .search .search__input {
  background: #ffffff;
  border: 1px solid #C7D2DB;
  height: 54px;
  max-width: 100%;
}
.home-search .search .search__input .rbtn__group,
.home-search .search .search__input .input__group {
  height: 54px;
}
.home-search .search .search__input .rbtn__group .rbtn, .home-search .search .search__input .rbtn__group .main__supplierPay .stripe-button-el, .main__supplierPay .home-search .search .search__input .rbtn__group .stripe-button-el, .input__group .home-search .search .search__input .rbtn__group .file__input--label, .home-search .search .search__input .rbtn__group .react-fine-uploader-gallery-file-input-container,
.home-search .search .search__input .rbtn__group input,
.home-search .search .search__input .input__group .rbtn,
.home-search .search .search__input .input__group .main__supplierPay .stripe-button-el,
.main__supplierPay .home-search .search .search__input .input__group .stripe-button-el,
.home-search .search .search__input .input__group .file__input--label,
.home-search .search .search__input .input__group .react-fine-uploader-gallery-file-input-container,
.home-search .search .search__input .input__group input {
  height: 52px;
}
.home-search .search .search__input:before {
  margin: 22px 0 0 12px;
}
.home-search .search .search__results__v2 {
  margin: 2px 0 0 0;
}
.home-search .search .search__results__v2 .search__request__v3:hover {
  background-color: #E5E8ED;
  cursor: initial;
}

.ReactModal__Overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease;
  z-index: 6;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Header {
  margin-bottom: 8px;
}
.ReactModal__Header h2, .ReactModal__Header .ReactTable .rt-noData--textLower, .ReactTable .ReactModal__Header .rt-noData--textLower, .ReactModal__Header .ReactTable .rt-noData--text, .ReactTable .ReactModal__Header .rt-noData--text, .ReactModal__Header .visually__h2 {
  display: inline-block;
  width: 90%;
  font-size: 26px !important;
}
.ReactModal__Header button {
  font-size: 1.2em;
  color: #51585D;
  min-width: initial;
  float: right;
  border: none;
  line-height: 32px;
  padding: 0;
  margin: 0;
}
.ReactModal__Header button:hover, .ReactModal__Header button:focus {
  background-color: transparent;
}
.ReactModal__Content {
  background-color: #ffffff;
  max-width: 380px;
  max-height: 90vh;
  width: 100%;
  padding: 34px 34px 24px;
  border-radius: 2px;
  overflow-y: auto;
}
.ReactModal__Content h3, .ReactModal__Content .visually__h3 {
  text-align: left;
}
.ReactModal__Content form .input__group {
  margin-bottom: 24px;
}
.ReactModal__Content form .form--actions {
  text-align: right;
  width: 100%;
}
.ReactModal__Content form .form--actions:before {
  content: " ";
  border: 1px solid #f4f6f9;
  display: block;
  margin: 0 -34px 24px;
}

.fullinfo, .summaryinfo {
  height: calc(100vh - 134px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 1280px) {
  .fullinfo, .summaryinfo {
    height: calc(100vh - 84px);
  }
}

.summaryinfo {
  padding: 8px;
  overflow: hidden;
  position: relative;
  background-color: #F4F6F9;
}
.summaryinfo:before {
  content: "";
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  left: 8px;
}
.summaryinfo__accordions {
  background-color: #F4F6F9;
}
.summaryinfo .return-link {
  position: absolute;
  bottom: 0;
  left: 0;
}

.fullinfo {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 32px 8px;
}

.activities {
  display: block;
  padding: 24px 0;
  width: 100%;
}
.activities.overflown {
  -webkit-box-shadow: inset 0 -8px 6px -6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: inset 0 -8px 6px -6px rgba(0, 0, 0, 0.16);
  box-shadow: inset 0 -8px 6px -6px rgba(0, 0, 0, 0.16);
}
.activities__column {
  background-color: #F4F6F9;
  margin-top: -25px;
  margin-bottom: -25px;
  padding: 0 24px;
}
.activities__column > * {
  padding-left: 32px;
  padding-right: 32px;
}
.activities__column .input__group textarea {
  background-color: #ffffff;
}

/*
  Contains base SCSS overrides
  particular for the supplier pay flow.
*/
.supplerpay__navigation {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../imgs/accounts_bg.jpg") no-repeat;
  background-position: 50%;
  background-size: cover;
  height: 170px;
  position: relative;
}
.supplerpay__navigation .main-headline {
  font-size: 26px;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.supplerpay__navigation .logo {
  max-width: 540px;
  width: 100%;
  position: absolute;
  left: -7px;
  top: 24px;
}
.supplerpay__navigation .link {
  color: #ffe900;
  text-transform: none;
  text-align: right;
  position: absolute;
  right: 8px;
  top: 24px;
}

.supplerpay__terms {
  position: absolute;
  width: 100%;
  top: 0;
}
.supplerpay__terms.animated {
  animation: none !important;
}

.supplerpay__registration {
  max-width: 720px;
  margin: 0 auto;
  padding: 140px 0 0;
}
.supplerpay__registration .card {
  position: relative;
}
.supplerpay__registration .link {
  color: #ffe900;
  font-size: 22px;
  text-transform: none;
}
.supplerpay__registration .main-headline {
  font-size: 50px;
  text-align: center;
  color: #F4F6F9;
  margin: 26px 0 26px 0;
}
.supplerpay__registration .body-copy {
  font-size: 22px;
  color: #ffffff;
  margin: 0 0 26px 0;
  text-align: center;
  line-height: 28px;
}
.supplerpay__registration .body-copy--terms {
  font-size: 14px;
  color: #ffffff;
}
.supplerpay__registration .body-copy--terms .link {
  font-size: 14px;
}
.supplerpay__registration .ul-copy {
  text-align: left;
  color: #ffffff;
  font-size: 22px;
  list-style: disc;
  margin: 0 0 26px 45px;
  line-height: 28px;
}
.supplerpay__registration .ul-copy li {
  margin: 0 0 8px 0;
}
.supplerpay__registration .ul-copy li:last-child {
  margin-bottom: 26px;
}
.supplerpay__registration .footer__about a,
.supplerpay__registration .footer__about p {
  color: #ffffff;
}
.supplerpay__registration:before {
  content: "";
  background: url("../imgs/accounts_bg.jpg") no-repeat;
  background-position: 50%;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.supplierPay--complete .mountain {
  margin-left: -24px;
  margin-bottom: -28px;
}

.image--logo {
  width: 110px;
  height: 110px;
}

.input__group .file__input {
  display: none;
}
.trafficLights {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
}
.trafficLights__holder {
  position: relative;
  height: 100%;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.accordion {
  position: relative;
}

.main__supplierPay .stripe-button-el {
  position: absolute;
  bottom: 150px;
  background-image: initial;
  color: initial;
  border-radius: initial;
  padding: initial;
  margin-left: 36px;
}
.main__supplierPay .stripe-button-el:not(:disabled):active, .main__supplierPay .stripe-button-el.active {
  background: #ccba00;
  border: 1px solid #ccba00;
}
.main__supplierPay .stripe-button-el:not(:disabled):active span, .main__supplierPay .stripe-button-el.active span {
  background-color: initial;
  background: initial;
}
.main__supplierPay .stripe-button-el:hover span {
  color: #ffffff;
}
.main__supplierPay .stripe-button-el span {
  min-height: initial !important;
  background-image: initial;
  background-color: initial;
  color: initial;
  height: initial;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 8px;
  font-weight: initial;
}
@media screen and (min-width: 720px) {
  .main__supplierPay .stripe-button-el {
    position: absolute;
    bottom: 125px;
  }
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  position: fixed;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}
.ReactModal__Content.bulkAssign {
  max-width: 725px;
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.supplierPay--modal__overlay h1, .supplierPay--modal__overlay h2, .supplierPay--modal__overlay .ReactTable .rt-noData--textLower, .ReactTable .supplierPay--modal__overlay .rt-noData--textLower, .supplierPay--modal__overlay .ReactTable .rt-noData--text, .ReactTable .supplierPay--modal__overlay .rt-noData--text, .supplierPay--modal__overlay h3, .supplierPay--modal__overlay .visually__h1, .supplierPay--modal__overlay .visually__h2, .supplierPay--modal__overlay .visually__h3 {
  font-size: 58px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 60px;
}
.supplierPay--modal__overlay h2, .supplierPay--modal__overlay .ReactTable .rt-noData--textLower, .ReactTable .supplierPay--modal__overlay .rt-noData--textLower, .supplierPay--modal__overlay .ReactTable .rt-noData--text, .ReactTable .supplierPay--modal__overlay .rt-noData--text, .supplierPay--modal__overlay .visually__h2 {
  font-size: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.pendingRequest {
  height: 80px;
  width: 80px;
  margin: 0 auto;
  background: url("../imgs/add_request_confirmation.gif") no-repeat;
  background-size: contain;
  background-position: center center;
}

.solicitation_request h2, .solicitation_request .ReactTable .rt-noData--textLower, .ReactTable .solicitation_request .rt-noData--textLower, .solicitation_request .ReactTable .rt-noData--text, .ReactTable .solicitation_request .rt-noData--text, .solicitation_request .visually__h2 {
  line-height: initial;
}
.solicitation_request h3, .solicitation_request .visually__h3 {
  text-align: center;
  color: #3B4043;
}
.solicitation_request h3.error, .solicitation_request .error.visually__h3 {
  text-align: left;
}
.solicitation_request i {
  display: inline-block;
  margin: 20px auto 30px;
  font-size: 3em;
}

.solicitation--manageFiles {
  margin: -2px 0 0 12px;
}

body.splash-body, .webreports-body, .webreports-header {
  background-color: #f5f7fa !important;
}

div.splash-title {
  background-color: #f5f7fa;
}
div.splash-title h1, div.splash-title h2, div.splash-title .ReactTable .rt-noData--textLower, .ReactTable div.splash-title .rt-noData--textLower, div.splash-title .ReactTable .rt-noData--text, .ReactTable div.splash-title .rt-noData--text, div.splash-title h3, div.splash-title .visually__h1, div.splash-title .visually__h2, div.splash-title .visually__h3 {
  font-family: "Source Sans Pro";
  font-weight: normal;
  color: #3B4043;
  font-size: 24px;
  margin-bottom: 0;
}
div.splash-title img {
  margin: 0 auto;
  max-width: 180px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .quadrant .quadrant__plane {
    float: left\9 ;
    margin-top: 0\9 ;
    margin-bottom: 0\9 ;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .quadrant .quadrant__numbers {
    left: 0\9 ;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .quadrant .quadrant__plane .plane {
    -moz-transform: rotateX(0) rotateZ(0);
    -o-transform: rotateX(0) rotateZ(0);
    -ms-transform: rotateX(0) rotateZ(0);
    -webkit-transform: rotateX(0) rotateZ(0);
    transform: rotateX(0) rotateZ(0);
  }
  .quadrant .quadrant__plane .plane .dot .dot__marker {
    -moz-transform: rotateZ(0deg) translateY(-145px) translateX(-29px) scaleY(0.4);
    -o-transform: rotateZ(0deg) translateY(-145px) translateX(-29px) scaleY(0.4);
    -ms-transform: rotateZ(0deg) translateY(-145px) translateX(-29px) scaleY(0.4);
    -webkit-transform: rotateZ(0deg) translateY(-145px) translateX(-29px) scaleY(0.4);
    transform: rotateZ(0deg) translateY(-145px) translateX(-29px) scaleY(0.4);
  }
  .quadrant .quadrant__plane .plane .dot .dot__marker text {
    -moz-transform: scaleY(1.8) translateY(0px);
    -o-transform: scaleY(1.8) translateY(0px);
    -ms-transform: scaleY(1.8) translateY(0px);
    -webkit-transform: scaleY(1.8) translateY(0px);
    transform: scaleY(1.8) translateY(0px);
  }
  .quadrant .quadrant__plane .plane .range--left {
    height: 300px;
    -moz-transform: rotateY(0) translateX(0px) translateY(0px) translateZ(0px);
    -o-transform: rotateY(0) translateX(0px) translateY(0px) translateZ(0px);
    -ms-transform: rotateY(0) translateX(0px) translateY(0px) translateZ(0px);
    -webkit-transform: rotateY(0) translateX(0px) translateY(0px) translateZ(0px);
    transform: rotateY(0) translateX(0px) translateY(0px) translateZ(0px);
  }
  .quadrant .quadrant__plane .plane .range--right {
    right: 145px;
    top: 150px;
    -moz-transform: rotateY(0) rotateX(0) rotateZ(90deg) translateX(0) translateY(0) translateZ(0);
    -o-transform: rotateY(0) rotateX(0) rotateZ(90deg) translateX(0) translateY(0) translateZ(0);
    -ms-transform: rotateY(0) rotateX(0) rotateZ(90deg) translateX(0) translateY(0) translateZ(0);
    -webkit-transform: rotateY(0) rotateX(0) rotateZ(90deg) translateX(0) translateY(0) translateZ(0);
    transform: rotateY(0) rotateX(0) rotateZ(90deg) translateX(0) translateY(0) translateZ(0);
  }
  .quadrant .quadrant__plane .plane .range .range__section {
    width: 10px;
  }
  .quadrant .quadrant__numbers .fhr__0 {
    top: -260px;
    left: 65px;
  }
}
@media screen and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: none) and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .quadrant .quadrant__numbers .fhr__0 {
    top: -240px\9 ;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .quadrant .quadrant__numbers .both__100 {
    top: 35px;
    left: 70px;
  }
}
@media screen and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: none) and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .quadrant .quadrant__numbers .both__100 {
    top: 60px\9 ;
    left: 50px\9 ;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .quadrant .quadrant__numbers .fhr__level {
    top: -130px;
    left: 40px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .quadrant .quadrant__numbers .chs__level {
    top: 65px;
    left: 245px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .quadrant .quadrant__numbers .chs__0 {
    top: 35px;
    left: 405px;
  }
}
@media screen and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: none) and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .quadrant .quadrant__numbers .chs__0 {
    top: 60px\9 ;
    left: 380px\9 ;
  }
}

.comparison--chart g:nth-last-of-type(2) rect[class^=s] {
  fill: #D8D8D8;
}

.inlineBlock {
  display: inline-block;
}

.-cursor-pointer:before {
  font-family: RapidRatings;
  font-size: 14px;
  color: #A3B0BB;
  content: "";
  float: right;
}
.-cursor-pointer.-sort-asc:before {
  font-size: 8px;
  transform: translateY(50%);
  color: #51585D;
  content: "";
}
.-cursor-pointer.-sort-desc:before {
  font-size: 8px;
  transform: translateY(50%);
  color: #51585D;
  content: "";
}

.ReactTable {
  font-size: 16px;
  border: 1px solid #C7D2DB;
}
.ReactTable .rt-table {
  background: #ffffff;
}
.ReactTable .rt-table .rt-td .link {
  vertical-align: middle;
}
.ReactTable .rt-table .rt-td span svg {
  display: inline;
}
.ReactTable .rt-table .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #C7D2DB;
}
.ReactTable .rt-table .rt-thead .rt-th:not(:first-of-type) {
  border-left: 1px solid #C7D2DB;
  border-right: none;
}
.ReactTable .rt-table .rt-thead .rt-resizable-header-content {
  padding: 0;
  text-align: left;
}
.ReactTable--lineHeight--md .rt-td::first-line {
  line-height: 48px;
}
.ReactTable .-pagination {
  box-shadow: none;
}
.ReactTable .rt-resizer {
  width: 24px;
  right: -14px;
}
.ReactTable .rt-td,
.ReactTable .rt-thead .rt-th {
  padding: 12px 20px;
}
.ReactTable .rt-resizable-header:focus {
  outline: none;
}
.ReactTable .rt-tbody .rt-td:not(:first-of-type) {
  border-left: 1px solid #C7D2DB;
}
.ReactTable .rt-tr.-even {
  background-color: #F4F6F9;
}
.ReactTable--checkboxTable .rt-td:first-of-type {
  padding: 0;
  align-self: center;
  border: none;
}
.ReactTable--checkboxTable .rt-td:first-of-type input[type=checkbox] {
  margin: 0 auto;
}
.ReactTable--checkboxTable .rt-thead .rt-th:first-of-type {
  padding: 0;
  align-self: center;
  border: none;
}
.ReactTable .rt-noData {
  text-align: center;
  width: 100%;
  height: calc(100% - 88px);
  background-color: #F4F6FA !important;
}
.ReactTable .rt-noData--searchResults {
  height: calc(100% - 45px);
  top: initial;
  bottom: 0;
  left: initial;
  transform: none;
}
.ReactTable .rt-noData--text {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}
.ReactTable .rt-noData--textLower {
  pointer-events: all;
  position: absolute;
  top: 180px;
  left: 0;
  right: 0;
}
.ReactTable .rt-noData--image {
  height: 100%;
  width: 100px;
  background-image: url("../imgs/SearchListIconXL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: 50px;
  margin: auto;
}
.ReactTable .rt-noData--keyError {
  height: 100%;
  width: 100px;
  background-image: url("../imgs/key_error.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: 50px;
  margin: auto;
}

.filter--container--search {
  float: right;
  position: relative;
}
.filter--container ::placeholder,
.filter--container :-ms-input-placeholder,
.filter--container ::-ms-input-placeholder {
  color: gray;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.filter--container > input {
  align-items: center;
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 40px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.filter--container > input::-webkit-input-placeholder {
  color: #A3B0BB;
}

.filter--container > input:-moz-placeholder {
  color: #A3B0BB;
}

.filter--container > input::-moz-placeholder {
  color: #A3B0BB;
}

.filter--container > input:-ms-input-placeholder {
  color: #A3B0BB;
}

.filter--container > input::-ms-clear {
  display: none;
}
.filter--container i {
  font-size: 20px;
  color: #A3B0BB;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.filter--container i:before {
  vertical-align: super;
}

.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #8d979e;
}

.riskLevel {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}
.riskLevel:before {
  content: "";
  color: #3b4043;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-flex;
}
.riskLevel__verylow:before {
  background-color: #6FBFEA;
}
.riskLevel__low:before {
  background-color: #2F872A;
}
.riskLevel__medium:before {
  background-color: #F7E856;
}
.riskLevel__high:before {
  background-color: #F5B353;
}
.riskLevel__veryhigh:before {
  background-color: #DC1B13;
}
.riskLevel__text {
  margin-left: 5px;
  vertical-align: middle;
}

.Toastify__toast-container {
  width: 100vw;
  padding: 0;
  top: 88px;
  left: 0;
}